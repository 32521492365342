import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title/*, Meta */} from '@angular/platform-browser';
import { AuthService } from '../../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartnerPreferenceService } from '../../services/partner-preference.service';
import { MyProfileService } from '../../services/my-profile.service';
import { NotifService } from '../../services/notif.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
// unused import
// import { isRestTypeNode } from 'typescript';
// import { LabelType, Options } from '@angular-slider/ngx-slider';
import { CommonService } from '../../services/common.service';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import {/* FormBuilder,*/FormGroup, FormControl, Validators, NgForm, } from '@angular/forms';


@Component({
  selector: 'app-communication-inbox',
  templateUrl: './communication-inbox.component.html',
  styleUrls: ['./communication-inbox.component.css']
})
export class CommunicationInboxComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  blockedProfileList: any = [];
  FollowUpProfileList: any = [];
  pageNo: number = 1;
  profileId: any;

  SkeltonLoader(i: number) {
    return new Array(i);
  }
  inboxLoader: boolean = false;
  user_id: any;
  matches: any = [];
  sort_by: '';
  Filter_by: '';
  selectId: any;
  selectedIds: any = [];
  FilterBy: any = '';
  sendFilterBy: any = '';
  cancelledFilterBy: any = '';
  follow_upFilterBy: any = '';
  blockFilterBy: any = '';
  Filters: string = 'AllRequests';
  sendFilters: string = 'All';
  cancelledFilters: string = 'AllCancelled';
  follow_upFilters: string = 'follow_up';
  blockFilters: string = 'block';
  profileList: any = [];
  sendprofileList: any = [];
  cancelledprofileList: any = [];

  matchedProfilesCount: number = 0;
  shortlistedProfilesCount: number = 0;
  favouriteProfilesCount: number = 0;
  viewedByProfilesCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 8;
  TotalRecord: number = 0;

  Responduserid: any;
  Responddisplayname: any;
  RespondProfilephoto: 'assets/images/banner-7.jpg';
  RespondApprove: any;

  isRespond: boolean = true;

  blockedList: any = [];
  historyData: any = [];
  isInbox: boolean = false;
  isSend: boolean = false;
  isFollowUp: boolean = false;
  isCancelled: boolean = false;
  isBlock: boolean = false;
  isUnblock: boolean = false;
  isInbox_date: any = '27/01/2000';
  isSend_date: any = '28/01/2000';
  isFollowUp_date: any = '29/01/2000';
  isCancelled_date: any = '30/01/2000';
  isBlock_date: any = '31/01/2000';
  isUnblock_date: any = '01/02/2000';
  historyProfileId: any;
  hidePageSize = true;

  inboxCount: number = 0;
  sentCount: number = 0;
  followUpCount: number = 0;
  acceptedCount: number = 0;
  cancelledCount: number = 0;
  blockedCount: number = 0;

  package_id: any = 0;
  encrypted_id: string | undefined;
  currentPage1: number;
  lastPage: number;
  displayName: any;


  otherReason: boolean = false;
  reportReason: string = ""
  reportReasonsList: any = [
    { id: 1, label: "Harassment or Abuse" },
    { id: 2, label: "Unwanted Attention" },
    { id: 3, label: "Spam" },
    { id: 4, label: "Scam or Fraud" },
    { id: 5, label: "Disrespectful Behavior" },
    { id: 6, label: "Privacy Concerns" },
    { id: 7, label: "Fake or Suspicious Profiles" },
    { id: 8, label: "Abusive or Threatening Behavior" },
    { id: 9, label: "Explicit Content" },
    { id: 10, label: "Violation of Platform Rules" },
    { id: 11, label: "Other" }

  ];
  @ViewChild('wordCountForm') wordCountNgForm: NgForm;
  @ViewChild("texts") texts: ElementRef;
  wordCountForm = new FormGroup({
    user_id: new FormControl(''),
    description: new FormControl('', [Validators.required]),
  })
  wordCount: any;
  words: any;
  tellUsMore: any;
  exceedLimit: boolean;

  constructor(
    private titleService: Title,

    private authService: AuthService,
    private cookieService: CookieService,
    private scroller: ViewportScroller,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private NotifService: NotifService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastrService: ToastrService,
    private CommonService: CommonService
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('Inbox | eternalstrings.com');

    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.package_id = localStorage.getItem('package_id');
    this.getCountOfRequest()
    this.getAllRequest();
  }


  getCountOfRequest() {
    let data = {
      user_id: this.user_id,
    };
    this.myProfileService.getCountOfRequest(data).subscribe((result) => {
      if (result.status) {
        this.inboxCount = result.inboxCount;
        this.sentCount = result.sentCount;
        this.followUpCount = result.followUpCount;
        this.cancelledCount = result.cancelledCount;
        this.acceptedCount = result.acceptedCount;
        this.blockedCount = result.blockedCount;

      } else {
        this.toastrService.error(result.message);
      }
      this.spinner.hide();
    });
  }

  public handlePage(e: any) {

    this.pageNo = e.pageIndex + 1;
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.itemPerPage;
    this.getAllRequest();
  }

  getAllRequest() {
    this.inboxLoader = true;
    let data = {
      user_id: this.user_id,
      Filter_by: this.FilterBy,
      filter: this.FilterBy,
      itemPerPage: 8,
      pageNo: this.pageNo,
    };
    this.myProfileService.getAllRequest(data).subscribe((result) => {
      if (result.status) {
        this.profileList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.shortlistedProfilesCount = result.shortlistedProfilesCount;
        this.favouriteProfilesCount = result.favouriteProfilesCount;
        this.viewedByProfilesCount = result.viewedByProfilesCount;
        this.TotalRecord = result.pages.total;
        this.currentPage1 = result.pages.current_page;
        this.lastPage = result.pages.last_page;
      } else {
        this.toastrService.error(result.message);
        this.inboxLoader = false;
      }
      this.inboxLoader = false;
    }, (error) => {                             //Error callback
      //console.error('error caught in component')
      this.spinner.hide();
      this.inboxLoader = false;
    });
  }

  getUserPackage(data) {
    this.package_id = data.userDetails.package_id;
  }

  doActionOnRequestsRespond(action: any, profile_id: any) {
    if (action == 'accept') {
      let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        action: action,
      };

      this.myProfileService.doActionOnRequests(pload).subscribe(
        (result) => {
          if (result.status) {
            this.inboxLoader = false;
            if (action == 'accept') {
              this.toastrService.success(
                result.message
              );
              this.router.navigate(['accepted']);
            }
          } else {
            this.toastrService.error(result.message);
          }
        },
        (error) => {
          this.inboxLoader = false;
          this.toastrService.error('Some thing went wrong!!');
        }
      );
    }
  }

  doActionOnRequests(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
          if (!this.paginator.hasNextPage() && this.TotalRecord % this.paginator.pageSize == 1) {
            this.paginator.previousPage();
          }
          this.inboxLoader = false;
          this.getAllRequest();
          this.getCountOfRequest();

        } else {
          this.inboxLoader = false;
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.inboxLoader = false;
        this.toastrService.error('Some thing went wrong!');
      }
    );
  }


  getRespondData(profile_id: any) {
    let FTArr = this.profileList;
    FTArr.forEach((element: any) => {
      if (element.user_id == profile_id) {
        (this.Responddisplayname = element.display_name);
          (this.RespondProfilephoto = element.profile_photo);
          (this.Responduserid = profile_id);
        (this.RespondApprove = element.profile_photo_approved);

      }
    });
  }


  getHistoryData(profile_id: any, connection_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      connection_id: connection_id,
    };
    this.myProfileService.getConnectionProfileHistory(pload).subscribe((result) => {
      if (result.status) {
        this.historyData = result.connectionHistory;
        this.inboxLoader = false;
      }
    }, (error) => {
      this.inboxLoader = false;
      this.toastrService.error('Some thing went wrong!');
    })

  }

  FiltersBy(event, Filters: any = 'AllRequests') {
    if (this.FilterBy != event.target.id) {
      this.FilterBy = event.target.id;
      this.Filters = Filters;
      this.inboxLoader = false;
      this.getAllRequest();
    }
  }

  Upgrade(userid, user_id) {
    this.router.navigate(['upgrade']);
  }

  ViewProfile(id, Name) {
    localStorage.setItem("pageFrom", "inbox");
    let pid: any = localStorage.getItem('package_id');
    if (pid > 0) {
      let encrypted_id: any = this.CommonService.encryptData(id);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      let url = `${environment.baseURL}` + 'detail/' + encrypted_id
      window.open(url, '_blank');
    } else {
      this.displayName = Name;
      $('#openModal1').click();
    }
  }

  blockReportId(user) {
    if (user.label == "Other") {
      this.otherReason = true;
      this.reportReason = ""
    } else {
      this.otherReason = false
      this.reportReason = user.label
    }
    console.log(user, "id and label", this.reportReason)
  }

  blockAndReportNow() {
    let data = {
      user_id: this.user_id,
      profile_id: this.profileId,
      action: "block_and_report",
      reason: this.reportReason
    };
    this.myProfileService.doActionOnRequests(data).subscribe(
      (result: any) => {
        if (result.status) {
          this.toastrService.success(result.message);
          this.getAllRequest();
          this.getCountOfRequest();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
      }
    );
  }

  userBlockReport(user) {
    this.profileId = user.user_id
    this.clear();
  }

  clear() {
    // console.log("before ", this.reportReason)
    this.reportReason = ""
    this.exceedLimit = false;
    this.otherReason = false
    let ele = document.getElementsByName("Serious");
    // before sonar
    // for (var i = 0; i < ele.length; i++) {
    //   var element = ele[i] as HTMLInputElement;
    //   element.checked = false;
    // }
      //after sonar
    for (const element of Array.from(ele) as HTMLInputElement[]) {
      element.checked = false;
    }
    // console.log("after ", this.reportReason)
  }

  wordCounter(event) {
    let para = this.wordCountForm?.value?.description;
    if (para.trim().length > 0) {
      this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
      this.words = this.wordCount ? this.wordCount.length : 0;
      if (this.words >= 21 && event.code != 'Backspace' && event.code != 'Delete') {
        this.wordError();
        // before sonarqube
        // const pattern = /[a-zA-Z0-9&_\.!,-\s()"']/g;
        const pattern = /[a-zA-Z0-9&_.!,\-\s()"']/g;
        if (!pattern.test(event.target.value)) {
          event.preventDefault();
          return false;
        } else {
         // before sonarqube
          // event.target.value = event.target.value.replace(/[a-zA-Z0-9&_\.!,-\s()']/g, "");
          // // this.wordCountForm.value.description = this.tellUsMore;
          // this.tellUsMore = this.tellUsMore.replace(/  +/g, ' ');
          event.target.value = event.target.value.replace(/[a-zA-Z0-9&_.,!\-\s()']/g, "");
          this.tellUsMore = this.tellUsMore.replace(/ {2,}/g, ' ');
          this.wordCountForm.patchValue({ 'description': this.tellUsMore.trim() + ' ' });
          this.words = this.tellUsMore.split(/\s+/).length
          event.preventDefault();
          return false;
        }
      } else {
        this.wordError();
        this.tellUsMore = this.wordCountForm?.value?.description;
      }
    } else {
      this.wordError();
      this.words = 0;
    }
  }

  wordError() {
    if (this.words > 20) {
      this.exceedLimit = true;
    } else {
      this.exceedLimit = false;
    }
  }

  pasteNumeric6(event) {
    setTimeout(() => {
      let para = this.wordCountForm?.value?.description;
      if (para.trim().length > 0) {
        this.wordCount = this.texts ? this.texts.nativeElement.value.split(/\s+/) : 0;
        this.words = this.wordCount ? this.wordCount.length : 0;
        if (this.words >= 21) {
          this.wordCount = this.wordCount.slice(0, 20);
          this.words = this.wordCount ? this.wordCount?.length : 0;
          this.wordCountForm.patchValue({ 'description': this.wordCount.join(" ") });
          this.wordError();
        }
      } else {
        this.wordError();
        this.words = 0;
      }
    }, 500);
  }

  pos = "";
  compare() {
    let pre = this.wordCount?.value?.description;
    setTimeout(() => {
      this.pos = this.wordCount?.value?.description;
      if (pre == this.pos) {
        // console.log("change", "itime")
      }
    }, 500);
    if (pre == this.pos) {
      // console.log("change", "out-time")
    }
  }
}


<div class="container border border-1 shadow-sm my-4 p-0 position-relative myall-section">
  <nav class="position-sticky z-index-60 top-70">
    <div class="nav nav-tabs d-flex align-items-center justify-content-between p-2 bg-warning" id="nav-tab"
      role="tablist">

      <a class="my-2 py-2 px-3 btn-outline-danger active border-0 flex-1 rounded-pill mx-1 text-center"
        routerLink="/myProfile">My
        Dashboard</a>

      <a class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/myInfo" [class.notClickable]="notClickable">My Profile</a>

      <a class="my-2 py-2 px-3 btn-outline-danger border-0 flex-1 rounded-pill mx-1 text-center dashboard-card123"
        routerLink="/myPhotoGallery" [class.notClickable]="notClickable">My Photo
        Gallery</a>

    </div>
  </nav>

  <div class="p-3 bg-white mt-68">
    <div class="tab-content" id="nav-tabContent">

      <!-- ----------start-main tabs----------------- -->
      <div class="tab-pane fade show" id="nav-dashboard" role="tabpanel" aria-labelledby="nav-dashboard-tab">

        <div class="row">

          <div class="col-12 col-md-4">
            <div class="border border-1 shadow-sm rounded">
              <div class="h-220 rounded position-relative">
                <img class="image-cover rounded" src="assets/images/real-welast_nameing-img-2.jpg" alt>
                <div class="position-absolute btn-danger btn btn-pos w-30-h-30 rounded-circle d-flex align-items-center
                  justify-content-center">
                  <i class="fas fa-plus text-light fs-5"></i>
                </div>
              </div>
              <div class="row px-2 py-4 m-0">
                <div class="col-8">
                  <p class="mb-0">{{displayName}}</p>
                  <p class="p-sm mb-0 fw-bold">SH80505446</p>
                </div>
                <div class="col-4 my-auto text-end">
                  <a href="javascript:void(0)" routerLink="/edit" class="text-danger"> Edit </a>
                </div>
              </div>
              <div class="row px-2 py-4 m-0 border border-1 border-star-0 border-end-0">
                <div class="col-8">
                  <p class="mb-0">Account Type</p>
                  <p class="p-sm mb-0 fw-bold"> Free Membership</p>
                </div>
                <div class="col-4 my-auto text-end">
                  <a href="javascript:void(0)" routerLink="/upgrade" class="text-danger">
                    Upgrade </a>
                </div>
              </div>
              <div class="row px-2 py-4 m-0">
                <div class="col-8">
                  <p class="mb-0">Verified Mobile Number</p>
                  <p class="p-sm mb-0">
                    <a href="javascript:void(0)" class="text-danger fw-bold">Verify your ID</a>
                  </p>
                </div>
                <div class="col-4 my-auto text-end">
                  <a href="javascript:void(0)" routerLink="/edit" class="text-danger"> Edit </a>
                </div>
              </div>

            </div>
          </div>

          <div class="col-12 col-md-8">

            <div class>
              <div class="row mx-0">
                <div class="col-6 mt-3 pb-4 px-0">
                  <h5 class="mb-0 fw-bold">Your Activity Summary</h5>
                </div>
                <div class="col-6 mt-3 pb-4 px-0 text-end text-muted text-break">
                  <div class="dropdown">
                    <p class="mb-0 btn fw-bold" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                      aria-expanded="false"> <i class="fas fa-bell"></i>
                      Communications
                      <span class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0">
                        73</span>
                    </p>

                    <ul class="dropdown-menu py-0 mx-height-60vh overflow-auto" aria-labelledby="dropdownMenuButton1">
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                      <li class="row mx-0">
                        <div class="col-12 d-flex border border-1 align-items-center justify-content-start px-2 py-3">
                          <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                            <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                          </div>
                          <div class="px-2">
                            <p class="mb-0 p-sm fw-bold mb-1"><span> Jyoti K alast_nameed a
                                Photo to her profile </span>
                            </p>
                            <p class="mb-0 p-xsm truncate-2 text-muted text-break">a few
                              hours ago
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row mx-0 rounded-3 overflow-hidden">
                <div class="shadow-sm col-4 bg-light py-2">
                  <h5 class="fw-bold mb-0"> 0 </h5>
                  <p class="mb-0">No Pending Invitations</p>
                </div>
                <div class="shadow-sm col-4 bg-light py-2 border border-1 border-top-0 border-bottom-0">
                  <h5 class="fw-bold mb-0"> 0 </h5>
                  <p class="mb-0"> Accepted Invitations</p>
                </div>
                <div class="shadow-sm col-4 bg-light py-2">
                  <h5 class="fw-bold mb-0"> 0 </h5>
                  <p class="mb-0"> No Recent Visitors</p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <div class="row mx-0 rounded-3 overflow-hidden">
                <div class="shadow-sm position-relative col-4 bg-light py-3">
                  <div class="my-auto">
                    <p class="mb-0 text-center p-sm">Only
                      <a href="javascript:void(0)" class="text-danger"> Premium </a> Members can
                      avail these benefits
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center position-absolute w-25-h-25 rounded-circle btn
                    btn-pos1 p-0 m-0 border-2 border border-start-0 border-top-0 border-bottom-0 ">
                    <i class="fas fa-lock text-danger"></i>
                  </div>
                </div>
                <div
                  class="shadow-sm text-muted text-break col-4 bg-light py-2 border border-1 border-top-0 border-bottom-0">
                  <h5 class="fw-bold mb-0"> 0 </h5>
                  <p class="mb-0"> Contacts viewed</p>
                </div>
                <div class="shadow-sm text-muted text-break col-4 bg-light py-2">
                  <h5 class="fw-bold mb-0"> 0 </h5>
                  <p class="mb-0"> Chats initiated</p>
                </div>
              </div>
            </div>

            <div class>
              <div class="row mx-0 mt-4">
                <div class="col-12 pb-4 px-0">
                  <h5 class="mb-0 fw-bold">Improve your Profile</h5>
                </div>
              </div>
              <div class="row mx-0 rounded-3 overflow-hidden border border-1 shadow-sm">
                <div class="col-12 col-sm-4 px-0 min-height-200">
                  <div class="bg-gradient w-100 h-100">

                  </div>
                </div>
                <div class="col-12 col-sm-8 py-4">
                  <h5 class="fw-bold mb-4">
                    Get 2 times more Matches, verify Profile with ID</h5>
                  <p class>Passport, Pan, Driver's Licence or Voter's ID</p>

                  <button type="button" class="btn btn-danger rounded-3 px-3">Upload Id</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row row-cols-1 row-cols-sm-2 my-4 g-2 g-lg-3">
          <div class="col">
            <div class="row">
              <div class="col-12">
                <p class="mb-0 fw-bold"> Premium Matches <span class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0">
                    949</span></p>
              </div>
            </div>
            <div class="row mt-4 px-2 rounded-3 overflow-hidden">
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/icon/premium.svg" alt>
                </div>
                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium +</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>
                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fab fa-staylinked fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/icon." alt>
                </div>
                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="p-xsm btn bg-danger text-light rounded-2 p-1">
                      <i class="fas fa-crown"></i> Premium +</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>
                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fab fa-staylinked fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>

                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="btn p-xsm bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium +</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>
                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>
                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="btn p-xsm bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium +</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>
                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>
                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium +</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>
                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 border border-1 text-center p-2">
                <a href="javascript:void(0)" class="btn btn-danger btn-sm" target="_blank">View All</a>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="row">
              <div class="col-12">
                <p class="mb-0 fw-bold"> Member You May Like<span
                    class="ms-2 btn-outline-danger btn rounded-3 px-2 py-0">
                    999+</span></p>
              </div>
            </div>
            <div class="row mt-4 px-2 rounded-3 overflow-hidden">
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>
                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="p-xsm btn bg-danger text-light rounded-2 p-1"><i class="fas fa-crown"></i> Premium +</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>
                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>

                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="btn p-xsm bg-danger text-light rounded-2 p-1">Just Joined</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>

                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>

                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>

                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>

                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span> <span
                      class="p-xsm btn bg-danger text-light rounded-2 p-1">Just Joined</span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>

                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex border border-1 align-items-center justify-content-between px-2 py-3">
                <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                  <img class="image-cover" src="assets/images/real-wedding-img-2.jpg" alt>
                </div>

                <div class="px-2">
                  <p class="mb-0 p-sm fw-bold mb-1"><span>Harshita P </span>
                  </p>
                  <p class="mb-0 p-xsm truncate-2 text-muted text-break">27 yrs, 5' 1", Hindi,
                    Bengaluru Software Professional (Others)</p>
                </div>

                <div class="flex-none btn p-1 border border-2 border-danger w-50-h-50 rounded-circle overflow-hidden">
                  <div class="btn btn-outline-danger p-0 w-100 h-100 border-0 rounded-circle overflow-hidden d-flex
                    align-items-center justify-content-center">
                    <i class="fas fa-check fs-4"></i>
                  </div>
                </div>
              </div>
              <div class="col-12 border border-1 text-center p-2">
                <a href="javascript:void(0)" routerLink="/matches" target="_blank" class="btn btn-danger btn-sm">View
                  All</a>
              </div>
            </div>
          </div>

        </div>

      </div>
      <!------------------------ end-main-tab --------------->

      <div class="tab-pane fade show active" id="nav-dashboard-2" role="tabpanel" aria-labelledby="nav-dashboard-2-tab">

        <div class="row g-3">
          <div class="order-sm-1 col-12 col-sm-6 col-lg-3 my-3">
            <!-- ......start content-loader code on profile cover .... -->
            <div class="border border-1 border-danger overflow-hidden rounded-5 shadow-2 h-100 "
              *ngIf="(myProfileLoader)">
              <div class="ratio-2 position-relative ">
                <img class="w-100 h-100 image-cover animated-background-name" alt>
                <img src="assets/images/default-profile-picture.svg" alt=""
                  class="position-absolute w-65-h-65 rounded-circle bottom-0 profile-circle animated-background-name">
              </div>
              <br>
              <h6 class="fw-bold text-center mt-4 pt-3 fs-5 animated-background-name"><small class="p-sm">
                </small><span style="display: none;">loader</span></h6>
            </div>
            <!-- ......end content-loader.... -->
            <div class="border border-1 border-danger overflow-hidden rounded-5 shadow-2 h-100"
              *ngIf="(!myProfileLoader)">
              <div class="ratio-2 position-relative">
                <app-progressive-image class="progressive-image">
                  <img appProgressiveImage [src]=" coverSRC " alt="" class="img-fluid w-100 h-100 image-cover"
                    type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                  <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
                    <div id="loaderProgressive" class="centerProgressive1"></div>
                  </div>
                  <div appProgressiveImageFallback class="fallback">
                    <img appProgressiveImageFallback class="img-fluid w-100 h-100 image-cover"
                      src="assets/images/banner-4.jpg" alt="">
                  </div>
                </app-progressive-image>
                <a href="javascript:void(0);" routerLink="/Profile-Preview" target="_blank">
                  <div class="position-absolute btn-danger btn btn-pos w-30-h-30 rounded-circle center">
                    <i class="fas fa-pen"></i>
                  </div>
                </a>
                <div class="position-absolute w-65-h-65 rounded-circle bottom-0 profile-circle" style="z-index: 2;">
                  <!-- type="button" -->
                  <!-- data-bs-toggle="modal" data-bs-target="#exampleModal" -->
                  <!-- <img src="assets/images/close-up-view.png" *ngIf="!details.profile_photo"
                    class="image-cover bg-white rounded-circle" alt="">
                  <img src="{{details.profile_photo}}" *ngIf="details.profile_photo"
                    class="image-cover bg-white rounded-circle" alt=""> -->
                  <app-progressive-image class="progressive-image">
                    <img appProgressiveImage [src]="profileSRC" alt=""
                      class="img-fluid image-cover bg-white rounded-circle cursor-pointer" type="button"
                      data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
                      <div id="loaderProgressive" class="centerProgressive1"></div>
                    </div>
                    <div appProgressiveImageFallback class="fallback">
                      <img appProgressiveImageFallback class="img-fluid image-cover bg-white rounded-circle h-65"
                        src="assets/images/default-profile-picture.svg" alt="">
                    </div>
                  </app-progressive-image>
                  <p class="text-center p-1"> <span
                      *ngIf="details?.profile_photo_approved == 1 && details?.profile_photo"
                      class="text-success border-success"> Approved</span>
                    <span
                      *ngIf="details?.profile_photo_approved == 0 && (details?.profile_photo !=null && details?.profile_photo !='')"
                      class="text-warning border-warning">
                      Awaiting Approval</span>
                    <span
                      *ngIf="details?.profile_photo_approved == 2 && (details?.profile_photo !=null && details?.profile_photo !='')"
                      class="text-danger border-danger"> Not Approved </span>
                    <span *ngIf="details?.profile_photo == null || details?.profile_photo == ''"
                      class="text-warning border-warning"> Default</span>
                  </p>
                </div>
              </div>
              <div class="col-12 pt-3 d-flex_rm align-items-center_rm text-center" [ngClass]="(this.details.profile_photo_approved == 0) ? 'mt-3' :
               (this.details.profile_photo_approved == 2) ? 'mt-3' : 'mt-0'" style="position: relative;">
                <h6 class="fw-bold fs-5 mt-5">{{details.display_name}}<small class>
                  </small></h6>
                <div class="membership_wpr" style="z-index: 1;">
                  <img src="assets/images/icon/elite.svg" class="width-30" alt placement="bottom-right"
                    ngbTooltip="Elite Member" *ngIf="details.package_id == 3" />
                  <img src="assets/images/icon/premium.svg" class="width-30" alt placement="bottom-right"
                    ngbTooltip="Premium Member" *ngIf="details.package_id == 2" />
                </div>

                <div class="membership_wpr1" style="z-index: 1;">
                  <span class="user_email_verified"
                    [ngbTooltip]="details?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                    placement="bottom-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                      class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':details?.is_user_verified != 1,
                                             'text-success':details?.is_user_verified == 1}" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                  0-1.044
                                  1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                  2.453c.386.273.744.482
                                  1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                  7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                  3.061-5.513
                                  2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                  0-2.887-.87C9.843.266
                                  8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                  0l-1.5-1.5a.5.5 0
                                  1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                    </svg>
                  </span>

                  <span class="user_email_verified1 mx-1" *ngIf="details?.is_user_verified== 0 " placement="bottom-left"
                    ngbTooltip="Verified email"><i class="fa fa-check fs-5" style="color:green"></i>
                  </span>
                  <span class="user_email_verified1 mx-1" *ngIf="details?.is_user_verified== 1 " placement="bottom-left"
                    ngbTooltip="Verified user"><i class="fa fa-check-double fs-6 " style="color:green"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="order-sm-3 order-lg-1 col-12 col-sm-12 col-lg-7 my-3">
            <!-- ....start content-loader on count card... -->
            <div class="border border-1 border-danger overflow-hidden rounded-5 shadow-2 h-100 animated-background"
              *ngIf="(cardCountLoader)">
              <div class="d-flex px-2 py-4 flex-wrap align-items-center justify-content-evenly ">
                <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0 animated-background-name">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6 ">
                    <i class="fas fa-users fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold"></p>
                  <small class="pt-1 pb-2 text-center lh-sm d-block"></small>
                </div>
                <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0 animated-background-name">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-bookmark fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold"></p>
                  <small class="pt-1 pb-2 text-center lh-sm d-block"></small>
                </div>
                <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0 animated-background-name">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-heart fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold"></p>
                  <small class="pt-1 pb-2 text-center lh-sm d-block"></small>
                </div>
                <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0 animated-background-name">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-paper-plane fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold"></p>
                  <small class="pt-1 pb-2 text-center lh-sm d-block"></small>
                </div>
                <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0 animated-background-name">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-question fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold"></p>
                  <small class="pt-1 pb-2 text-center lh-sm d-block"></small>
                </div>
                <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                  border-bottom-0 border-start-0 border-end-0 animated-background-name">
                  <div
                    class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                    <i class="fas fa-user-friends fs-5 up-2 cursor-pointer text-danger"></i>
                  </div>
                  <p class="mb-0 mt-4 text-center fw-bold"></p>
                  <small class="pt-1 pb-2 text-center lh-sm d-block"></small>
                </div>

              </div>
            </div>
            <!-- ....end content-loader on count card... -->
            <div class="border border-1 border-danger overflow-hidden rounded-5 shadow-2 h-100"
              *ngIf="(!cardCountLoader)">
              <div class="d-flex px-2 py-4 flex-wrap align-items-center justify-content-evenly ">

                <a href="javascript:void(0);" routerLink="/matches-view" target="_blank">
                  <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                    border-bottom-0 border-start-0 border-end-0">
                    <div
                      class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                      <i class="fas fa-users fs-5 up-2 cursor-pointer text-danger"></i>
                    </div>
                    <p class="mb-0 mt-4 text-center fw-bold">{{ViewCount}}</p>
                    <small class="pt-1 pb-2 text-center lh-sm d-block">Viewed By Others</small>
                  </div>
                </a>

                <a href="javascript:void(0);" routerLink="/matches-shortlist" target="_blank">
                  <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                    border-bottom-0 border-start-0 border-end-0">
                    <div
                      class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                      <i class="fas fa-bookmark fs-5 up-2 cursor-pointer text-danger"></i>
                    </div>
                    <p class="mb-0 mt-4 text-center fw-bold">{{ShortlistCount}}</p>
                    <small class="pt-1 pb-2 text-center lh-sm d-block">My Shortlist</small>
                  </div>
                </a>

                <a href="javascript:void(0);" routerLink="/matches-favourite" target="_blank">
                  <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                    border-bottom-0 border-start-0 border-end-0">
                    <div
                      class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                      <i class="fas fa-heart fs-5 up-2 cursor-pointer text-danger"></i>
                    </div>
                    <p class="mb-0 mt-4 text-center fw-bold">{{FavouriteCount}}</p>
                    <small class="pt-1 pb-2 text-center lh-sm d-block">My Favourite</small>
                  </div>
                </a>

                <a href="javascript:void(0);" routerLink="/inbox-sent" target="_blank">
                  <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                    border-bottom-0 border-start-0 border-end-0">
                    <div
                      class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                      <i class="fas fa-paper-plane fs-5 up-2 cursor-pointer text-danger"></i>
                    </div>
                    <p class="mb-0 mt-4 text-center fw-bold">{{sentCount}}</p>
                    <small class="pt-1 pb-2 text-center lh-sm d-block">Sent</small>
                  </div>
                </a>

                <a href="javascript:void(0);" routerLink="/inbox-followUp" target="_blank">
                  <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                    border-bottom-0 border-start-0 border-end-0">
                    <div
                      class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                      <i class="fas fa-question fs-5 up-2 cursor-pointer text-danger"></i>
                    </div>
                    <p class="mb-0 mt-4 text-center fw-bold">{{followUpCount}}</p>
                    <small class="pt-1 pb-2 text-center lh-sm d-block">Follow Up </small>
                  </div>
                </a>

                <a href="javascript:void(0);" routerLink="/chat" target="_blank">
                  <div class="mt-3 mb-2 px-2 mx-2 width-180 position-relative hover-3 rounded-5 shadow border border-4 border-warning
                    border-bottom-0 border-start-0 border-end-0">
                    <div
                      class="position-absolute top-0 w-40-h-40 rounded-circle text-center center bg-warning top-0 card-6">
                      <i class="fas fa-user-friends fs-5 up-2 cursor-pointer text-danger"></i>
                    </div>
                    <p class="mb-0 mt-4 text-center fw-bold">{{chatCount}}</p>
                    <small class="pt-1 pb-2 text-center lh-sm d-block"> Chats Started </small>
                  </div>
                </a>

              </div>
            </div>
          </div>
          <div class="order-sm-2 order-lg-3 col-12 col-sm-6 col-lg-2 my-3">
            <!-- ....start content-loader on complete now... -->
            <div
              class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 text-center animated-background"
              *ngIf="(inboxLoader)">
              <div class="py-2 cursor-pointer animated-background-name">
                <circle-progress [percent] [radius]="60" [outerStrokeWidth]="8" [innerStrokeWidth]="3"
                  [outerStrokeColor]="'#b51c36'" [innerStrokeColor]="'#ffcd04'" [animation]="true"
                  [animationDuration]="1000" [titleColor]="'#b51c36'" [responsive]="true" [units]="'%'"
                  [unitsColor]="'#b51c36'" [titleFontWeight]="'600'" [subtitle]="'Profile Completed'">
                </circle-progress>
              </div>
              <h6 class="fw-bold text-center mt-4 pt-3 fs-5 animated-background-name"><small class="p-sm">
                </small><span style="display: none;">loader</span></h6>

            </div>
            <!-- ....end content-loader on complete now... -->
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 text-center "
              *ngIf="(!inboxLoader)">
              <div class="py-2 cursor-pointer ">
                <circle-progress [percent]="profilePercent" [radius]="60" [outerStrokeWidth]="8" [innerStrokeWidth]="3"
                  [outerStrokeColor]="'#b51c36'" [innerStrokeColor]="'#ffcd04'" [animation]="true"
                  [animationDuration]="1000" [titleColor]="'#b51c36'" [responsive]="true" [units]="'%'"
                  [unitsColor]="'#b51c36'" [titleFontWeight]="'600'" [subtitle]="'Profile Completed'"></circle-progress>
              </div>
              <button *ngIf="profilePercent != 100" class="btn btn-danger d-block mb-3 mx-auto "
                (click)="completeNow()">
                Complete Now </button>
              <button style="display:none;" id="openModal" data-bs-target="#upgradeModal"
                data-bs-toggle="modal"></button>

            </div>
          </div>
          <button style="display:none;" id="openModal2" data-bs-target="#upgradeModal2" data-bs-toggle="modal"></button>
          <button style="display:none;" id="openModal1" data-bs-target="#upgradeModal1" data-bs-toggle="modal"></button>
          <button style="display:none;" id="openModal3" data-bs-target="#FreeTrialModal"
            data-bs-toggle="modal"></button>
          <button style="display:none;" id="openModal4" data-bs-target="#FreeTrialModalok"
            data-bs-toggle="modal"></button>
          <div class="order-sm-4 col-12 col-md-7 my-3">
            <div class="border border-1 border-danger h-100 overflow-hidden rounded-5 shadow-2 px-3 py-2">
              <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0 d-flex
                align-items-center justify-content-between">
                Suggested Matches
                <span class="text-end" *ngIf="TotalRecord != 0">
                  <button
                    class="cursor_unset btn-outline-danger btn-outline-danger-nbg btn btn-sm me-2">{{TotalRecord}}</button>
                  <a class="btn-danger btn btn-sm text-end" placement="top" href="javascript:void(0)"
                    (click)="suggested_matches('suggested')">
                    View All</a>
                </span>
              </h5>

              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div *ngIf="MLoader">
                </div>
                <!-- .........start suggested card on content-loader....... -->

                <div class="carousel-inner d-flex center">

                  <div
                    class="row card row shadow-sm border border-1 bg-light h-100 mx-0 overflow-hidden position-relative"
                    *ngIf="inboxLoader" style="width:36rem;">
                    <div class="mainCardBox col-12 card-body d-flex p-1 ">
                      <img src="assets/images/default-profile-picture.svg" alt="" class="img_Box animated-background" />
                      <div class="card-contentBox p-1">
                        <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0 card-nav">
                          <span class="nameBlank-box mt-1 animated-background-name "></span>
                        </div>
                        <div class="row mt-3">
                          <div class="col-6">
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                          </div>
                          <div class="col-6">
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                            <span class="blank-box mt-3 animated-background-name"></span>
                          </div>
                        </div>
                        <span class="footerBlankBox mt-4 animated-background-name"></span>
                      </div>
                    </div>
                  </div>
                  <!-- .........end suggested card on content-loader....... -->

                  <ng-container *ngFor="let profile of matches; let i= index">
                    <div class="carousel-item row w-100 mx-0 rounded-3 shadow-sm border border-1 bg-light mt-2"
                      [ngClass]="{'active':i==0}">

                      <div class="col-12 mt-2 w-100 px-0 d-flex overflow-x-auto">
                        <div class="ps-2 w-180 h-180 flex-none">
                          <img class="image-cover rounded-3"
                            *ngIf="!profile.profile_photo || profile.profile_photo_approved!=1 "
                            src="assets/images/default-profile-picture.svg" alt>
                          <div
                            *ngIf="profile?.profile_photo != null && !!profile?.profile_photo  && profile.profile_photo_approved==1">
                            <ng-container
                              *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
                              <img class="image-cover" src="assets/images/default-profile-picture.svg"
                                alt="profile img">
                            </ng-container>
                            <ng-container
                              *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5)">
                              <app-progressive-image class="progressive-image">
                                <img appProgressiveImage [src]="profile.profile_photo" alt=""
                                  class="img-fluid image-cover rounded-3">
                                <div appProgressiveImagePlaceholder class="placeholderOneOrThree-suggest">
                                  <div id="loaderProgressive" class="centerProgressive1"></div>
                                </div>
                                <div appProgressiveImageFallback class="fallback">
                                  <img appProgressiveImageFallback class="img-fluid image-cover rounded-3"
                                    src="assets/images/men-avtar-2.svg" alt="">
                                </div>
                              </app-progressive-image>
                            </ng-container>
                          </div>
                        </div>
                        <div class="row w-100 mx-0">
                          <div class="col-12">
                            <div class="row mx-0 pb-2 border border-2 border-top-0 border-start-0 border-end-0">
                              <div class="col-12 px-0 d-flex align-items-center">
                                <h5 class="text-danger mb-0 fw-bold"> {{profile.display_name}}</h5>

                                <div class=" px-0 d-flex align-items-center">
                                  <span class="user_email_verified"
                                    [ngbTooltip]="profile?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                                    placement="bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                      class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':profile?.is_user_verified != 1,
                                                 'text-success':profile?.is_user_verified == 1}" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                      0-1.044
                                      1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                      2.453c.386.273.744.482
                                      1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                      7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                      3.061-5.513
                                      2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                      0-2.887-.87C9.843.266
                                      8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                      0l-1.5-1.5a.5.5 0
                                      1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                                    </svg>
                                  </span>

                                  <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 0 "
                                    placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-5"
                                      style="color:green"></i>
                                  </span>
                                  <span class="user_email_verified1 mx-1" *ngIf="profile?.is_user_verified== 1 "
                                    placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-5 "
                                      style="color:green"></i>
                                  </span>
                                  <div class="d-flex align-items-center">
                                    <div class="p-xsm mb-0 text-success" *ngIf="profile.is_logged_in">
                                      <div class="flash d-inline-block me-1">
                                        <div class>
                                          <i class="fas fa-globe"></i>
                                        </div>
                                      </div>
                                      <div class="d-inline-block">
                                        Online
                                      </div>
                                    </div>
                                    <div class="p-xsm mb-0 text-default" *ngIf="!profile.is_logged_in">
                                      <div class="d-inline-block me-1">
                                        <div class>
                                          <i class="fas fa-globe"></i>
                                        </div>
                                      </div>
                                      <div class="d-inline-block">
                                        Offline
                                      </div>
                                    </div>

                                    <div class="ms-2">
                                      <img src="assets/images/icon/{{
                                        profile.package_icon
                                        }}" class="width-30" alt placement="right" ngbTooltip="Elite Member"
                                        *ngIf="profile.package_id == 3" />
                                      <img src="assets/images/icon/{{
                                        profile.package_icon
                                        }}" class="width-30" alt placement="right" ngbTooltip="Premium Member"
                                        *ngIf="profile.package_id == 2" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row py-2">
                              <div class="col-6">
                                <p class="p-sm mb-2 text-muted text-break">
                                  <strong>Age:</strong> {{profile.age }} yrs <strong>Height:</strong>
                                  {{ profile?.height|heightConverter:"Ft"}}
                                </p>
                              </div>
                              <div class="col-6">
                                <p class="p-sm mb-2 text-muted text-break" *ngIf="profile.working_as">{{
                                  profile?.working_as | limit : 20}} </p>
                              </div>

                              <div class="col-6">
                                <p class="p-sm mb-2 text-muted text-break"> {{ profile?.marital_status | limit
                                  :18}}
                                </p>
                              </div>
                              <div class="col-6">
                                <p class="p-sm mb-0 text-muted text-break"> {{ profile?.current_city | limit :
                                  20}}</p>
                              </div>

                              <div class="col-6">
                                <p class="p-sm mb-0 text-muted text-break"> {{ profile?.religion | limit :18}}<span
                                    *ngIf="profile.caste">,
                                    {{ profile?.caste | limit :18}}</span>
                                </p>
                              </div>

                              <div class="col-6">
                                <p class="p-sm mb-0 text-muted text-break">{{ profile?.stateName | limit :
                                  20}} </p>
                              </div>
                              <div class="col-6">
                                <p class="p-sm mb-2 text-muted text-none"> </p>
                              </div>
                              <div class="col-6">
                                <p class="p-sm mb-0 text-muted text-break"> {{ profile?.living_in_country_name
                                  | limit
                                  :
                                  20}} </p>
                              </div>
                              <div class="col-12 pt-2">
                                <p class="text-muted p-sm mb-0">{{profile.description | limit :75}} <a
                                    href="javascript:void(0)"
                                    (click)="ViewProfile(profile.user_id,profile.display_name)"
                                    class="default_link text-underline">View
                                    Profile</a>
                                </p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>

                <div *ngIf="TotalRecord==0 && !inboxLoader" class="align-items-center justify-content-center mt-5">
                  <h6 class="fw-bold mt-5 text-center pt-5"> You have no suggested matches at this time, please update
                    your
                    partner
                    preferences.
                  </h6>
                </div>
                <div class="center mt-4" *ngIf="TotalRecord>1 && !inboxLoader">
                  <button class="p-0 border-0 mx-2 position-static" type="button" (click)="handlePageP()"
                    data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <i class="fas fa-caret-square-left text-danger fs-1"></i>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="p-0 border-0 mx-2 position-static" type="button" (click)="handlePageN()"
                    data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <i class="fas fa-caret-square-right text-danger fs-1"></i>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="order-sm-5 col-12 col-md-5 my-3 height-350">
            <div class="border border-1 border-danger h-100 position-relative rounded-5 shadow-2 px-3 py-2">
              <h5 class="pb-2 position-sticky top-0 fw-bold border border-2 lh-base border-top-0 border-danger
                  border-start-0 border-end-0 d-flex align-items-center justify-content-between">
                Communication
                <span class="text-end" *ngIf="totalCommunication != 0">
                  <button *ngIf="!CommunicationLoader"
                    class="cursor_unset btn-outline-danger btn-outline-danger-nbg btn btn-sm me-2">{{totalCommunication}}</button>
                  <button *ngIf="CommunicationLoader"
                    class="cursor_unset btn-outline-danger btn btn-sm me-2 animated-background-name"></button>
                  <a class="btn-danger btn btn-sm text-end" style="display:none" routerLink="/inbox" target="_blank">
                    View All</a>
                </span>
              </h5>

              <div class="mt-1 scrollbar overflow-auto mb-4 dashboard-box">
                <div *ngIf="!CommunicationLoader">
                  <ng-container *ngFor="let data of communication,let i= index">
                    <a href="javascript:void(0);" (click)="ViewRequest(data.message_type,data.id)">
                      <div class="col-12 alert d-flex align-items-center overflow-hidden rounded-3 mb-1" [ngClass]="{
                          'alert-success':data.message_type === 'Request accepted',
                          'alert-warning':data.message_type === 'Connection request',
                          'alert-danger':data.message_type === 'Request cancelled',
                          'alert-warning':data.message_type === 'Connection request reminder'
                          }">
                        <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0">
                          <img src="assets/images/default-profile-picture.svg"
                            *ngIf="!data.profile_photo || data.profile_photo_approved!=1  " class="image-cover" alt>
                          <div
                            *ngIf="data?.profile_photo != null && !!data?.profile_photo && data.profile_photo_approved==1 ">
                            <ng-container
                              *ngIf="data?.photo_visiblity == 'No one' || (data?.photo_visiblity == 'Visible to connected members' && data?.stage != 5)">
                              <img class="image-cover" src="assets/images/default-profile-picture.svg" alt="img">
                            </ng-container>
                            <ng-container
                              *ngIf="data?.photo_visiblity == 'Visible to all members' || (data?.photo_visiblity == 'Visible to connected members' && data?.stage == 5)">
                              <img src="{{data.profile_photo}}" class="image-cover" alt>
                            </ng-container>
                          </div>
                        </div>
                        <div class="flex-grow-1 ms-2 overflow-hidden">
                          <div class="d-flex align-items-center justify-content-between mb-1">
                            <h6 class="mb-0 fw-bold">{{data.message_type}}</h6>
                            <small class="text-muted text-break p-sm">{{data.msgTime}}</small>
                          </div>
                          <div class="d-flex align-items-center justify-content-between">
                            <p class="truncate-2 p-xsm fw-bold m-0">{{data.alert_message}}</p>
                            <span class="text-muted">{{data.msgDate}}</span>
                          </div>
                        </div>
                      </div>

                    </a>
                  </ng-container>
                </div>

                <div *ngIf="CommunicationLoader">

                  <div *ngFor="let number of [0,1,2]"
                    class="col-12 alert d-flex align-items-center overflow-hidden rounded-3 border border-1 animated-background ">
                    <div class="w-50-h-50 rounded-3 overflow-hidden flex-shrink-0" target="_blank">
                      <img src="assets/images/default-profile-picture.svg" class="image-cover animated-background-name"
                        alt>
                    </div>
                    <div class="flex-grow-1 ms-2 overflow-hidden">
                      <div class="d-flex align-items-center justify-content-between mb-1">
                        <h6 class="mb-0 fw-bold blank-box-communication animated-background-name" style="width:70%">
                          <span style="display: none;">loader</span>
                        </h6>
                        <small class="text-muted text-break p-sm blank-box-communication animated-background-name"
                          style="width:20%"></small>
                      </div>
                      <div class="truncate-2 p-xsm fw-bold blank-box-communication animated-background-name mt-2"
                        style="width:50%">
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="totalCommunication==0 && !CommunicationLoader" class="center">
                  <h6 class="fw-bold mt-5 center pt-5">Start sending requests to other profiles.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row g-3 ">
        <div class="col-12 col-md-6 my-3">

          <div class>
            <div class="w-100 border border-1 border-danger h-100  scrollbar overflow-auto rounded-5 shadow-2 px-3 py-2"
              [ngClass]="{'h-513': !EliteLoader && Elitematches.length>0}">
              <!-- [ngClass]="{'h-100':EliteTotalRecord>0}" -->
              <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0
                    d-flex align-items-center justify-content-between">
                Elite Matches
                <span class="text-end" *ngIf="EliteTotalRecord !=0">
                  <button *ngIf="EliteLoader"
                    class="cursor_unset btn-outline-danger btn-outline-danger-nbg btn btn-sm me-2 animated-background-name">{{EliteTotalRecord}}</button>
                  <button *ngIf="!EliteLoader"
                    class="cursor_unset btn-outline-danger btn-outline-danger-nbg btn btn-sm me-2">{{EliteTotalRecord}}</button>
                  <!-- <a *ngIf="EliteLoader" class="btn-outline-danger btn btn-sm text-end animated-background-name">
                    </a> -->
                  <a class="btn-danger btn btn-sm text-end" placement="top" (click)="suggested_matches('elite')">
                    View All</a>
                </span>
              </h5>
              <!-- scrollbar  mb-5 dashboard-box -->
              <div>
                <!-- <list-content-loader> -->

                <!-- <list-content-loader start> -->
                <div *ngIf="EliteLoader">
                  <div *ngFor="let number of [0,1,2,3]" class="dashboard-box scrollbar overflow-auto mt-2 px-2 rounded-3 d-flex border border-1 align-items-center
                        justify-content-between p-2 animated-background ">
                    <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                      <img src="assets/images/default-profile-picture.svg" class="image-cover animated-background-name"
                        alt>
                    </div>
                    <div class="px-2 flex-auto">
                      <p class="mb-0 p-sm fw-bold mb-1 text-dark blank-box-communication animated-background-name mt-2"
                        style="width:80%"><span> </span>
                        <span class="d-inline-block p-xsm mb-0 text-success mx-2">
                        </span>
                      </p>
                      <p class="mb-0 p-xsm truncate-2 text-muted text-break truncate-2 p-xsm fw-bold blank-box-communication
                            animated-background-name mt-2" style="width:50%">
                        <strong></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!EliteLoader && Elitematches.length>0"
                class="dashboard-box scrollbar overflow-auto mt-2 px-2 rounded-3 ">
                <a *ngFor='let elite of Elitematches ;let i= index'
                  (click)="ViewProfile(elite.user_id,elite.display_name)"
                  class="col-12 d-flex border border-1 align-items-center justify-content-between p-2">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden position-relative">
                    <img class="image-cover" *ngIf="!elite?.profile_photo || elite?.profile_photo_approved!=1 "
                      src="assets/images/men-avtar-2.svg" alt>
                    <div *ngIf="elite?.profile_photo && elite?.profile_photo_approved==1">
                      <ng-container
                        *ngIf="elite?.photo_visiblity == 'No one' || (elite?.photo_visiblity == 'Visible to connected members' && elite?.stage != 5)">
                        <img class="image-cover" src="assets/images/default-profile-picture.svg" alt="img">
                      </ng-container>
                      <ng-container
                        *ngIf="elite?.photo_visiblity == 'Visible to all members' || (elite?.photo_visiblity == 'Visible to connected members' && elite?.stage == 5)">
                        <app-progressive-image class="progressive-image">
                          <img appProgressiveImage [src]="elite.profile_photo" alt="" class="img-fluid image-cover">

                          <div appProgressiveImagePlaceholder class="placeholderOneOrThree position-absolute">
                            <div id="loaderProgressive" class="centerProgressive"></div>
                          </div>
                          <div appProgressiveImageFallback class="fallback">
                            <img appProgressiveImageFallback class="img-fluid image-cover"
                              src="assets/images/men-avtar-2.svg" alt="">
                          </div>
                        </app-progressive-image>
                      </ng-container>
                    </div>
                  </div>
                  <div class="px-2 flex-auto">
                    <p class="mb-0 p-sm fw-bold mb-1 text-dark"><span>{{elite.display_name}} </span>
                      <span class="d-inline-block p-xsm mb-0  mx-1">

                        <div class="d-flex align-items-center">
                          <span class="user_email_verified"
                            [ngbTooltip]="elite?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                            placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                              class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':elite?.is_user_verified != 1,
                                                 'text-success':elite?.is_user_verified == 1}" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                      0-1.044
                                      1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                      2.453c.386.273.744.482
                                      1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                      7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                      3.061-5.513
                                      2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                      0-2.887-.87C9.843.266
                                      8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                      0l-1.5-1.5a.5.5 0
                                      1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                            </svg>
                          </span>

                          <span class="user_email_verified1 mx-0" *ngIf="elite?.is_user_verified== 0 "
                            placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-6"
                              style="color:green"></i>
                          </span>
                          <span class="user_email_verified1 mx-0" *ngIf="elite?.is_user_verified== 1 "
                            placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-6 "
                              style="color:green"></i>
                          </span>
                          <div class="p-xsm mb-0 text-success mx-1 " *ngIf="elite.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class>
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">
                              Online
                            </div>
                          </div>
                          <div class="p-xsm mb-0 text-default mx-1 " *ngIf="!elite.is_logged_in">
                            <div class="d-inline-block me-1">
                              <div class>
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">
                              Offline
                            </div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                                  elite.package_icon
                                  }}" class="width-30" alt placement="top" ngbTooltip="Elite Member"
                              *ngIf="elite.package_id == 3" />
                          </div>
                        </div>
                      </span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">
                      <strong>Age:</strong>{{elite.age}} yrs,
                      <strong>Height:</strong> {{ elite?.height|heightConverter:"Ft"}},
                      <span>{{ elite?.marital_status | limit:18}} {{ elite?.religion | limit :18}}, </span>
                      <span *ngIf="elite.caste">{{ elite?.caste | limit :18}}, </span>
                      <span>{{ elite?.working_as | limit : 20}}, </span>
                      <span>{{ elite?.stateName | limit : 20}}, </span>
                      <span>{{ elite?.living_in_country_name | limit : 20}}</span>
                    </p>
                  </div>
                </a>
              </div>
              <div *ngIf="Elitematches.length == 0 && !EliteLoader "
                class="row mt-3 px-2 rounded-3 scrollbar overflow-auto dashboard-box mb-5">
                <div class="align-items-center justify-content-center mt-2">
                  <h6 class="align-items-center fw-bold center pt-5">You have no Elite Matches at this time.</h6>
                </div>
              </div>
            </div>

            <div
              class="w-100 mt-4 border border-1 border-danger h-100  scrollbar overflow-auto rounded-5 shadow-2 px-3 py-2"
              [ngClass]="{'h-513': PremiumMatches.length > 0 && !EliteLoader}" *ngIf="HidePremium">
              <h5 class="pb-2 fw-bold border border-2 lh-base border-top-0 border-danger border-start-0 border-end-0
                    d-flex align-items-center justify-content-between">
                Premium Matches

                <span class="text-end" *ngIf="PremiumTotalRecord != 0">
                  <button *ngIf="!EliteLoader"
                    class="cursor_unset btn-outline-danger  btn-outline-danger-nbg btn btn-sm me-2">{{PremiumTotalRecord}}</button>
                  <!-- <a class="btn-danger btn btn-sm text-end" routerLink="/matches" target="_blank"
                    style="display: none;">
                    View All</a> -->
                  <a class="btn-danger btn btn-sm text-end" *ngIf="!EliteLoader" placement="top"
                    (click)="suggested_matches('premium')">
                    View All</a>
                </span>

              </h5>

              <div>
                <!-- <list-content-loader> -->

                <!-- <list-content-loader start> -->
                <div *ngIf="EliteLoader">

                  <div *ngFor="let number of [0,1,2,3]" class="dashboard-box scrollbar overflow-auto mt-2 px-2 rounded-3 d-flex border border-1 align-items-center
                        justify-content-between p-2 animated-background">
                    <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                      <img src="assets/images/default-profile-picture.svg" class="image-cover animated-background-name"
                        alt>
                    </div>
                    <div class="px-2 flex-auto">
                      <p class="mb-0 p-sm fw-bold mb-1 text-dark blank-box-communication animated-background-name mt-2"
                        style="width:80%"><span> </span>
                        <span class="d-inline-block p-xsm mb-0 text-success mx-2">
                        </span>
                      </p>
                      <p class="mb-0 p-xsm truncate-2 text-muted text-break truncate-2 p-xsm fw-bold blank-box-communication
                            animated-background-name mt-2" style="width:50%">
                        <strong></strong>
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <div *ngIf="PremiumMatches.length > 0 && !EliteLoader"
                class="row mt-2 px-2 rounded-3 overflow-hidden cursor-pointer">
                <a *ngFor='let premium of PremiumMatches ;let i= index'
                  (click)="ViewProfile(premium.user_id,premium.display_name)" target="_blank"
                  class="col-12 d-flex border border-1 align-items-center justify-content-between p-2">
                  <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                    <img class="image-cover" *ngIf="!premium.profile_photo || premium.profile_photo_approved!=1 "
                      src="assets/images/default-profile-picture.svg" alt>
                    <div *ngIf="premium?.profile_photo && premium?.profile_photo_approved==1">
                      <ng-container
                        *ngIf="premium?.photo_visiblity == 'No one' || (premium?.photo_visiblity == 'Visible to connected members' && premium?.stage != 5)">
                        <img class="image-cover" src="assets/images/default-profile-picture.svg" alt="img">
                      </ng-container>
                      <ng-container
                        *ngIf="premium?.photo_visiblity == 'Visible to all members' || (premium?.photo_visiblity == 'Visible to connected members' && premium?.stage == 5)">
                        <app-progressive-image class="progressive-image">
                          <img appProgressiveImage [src]="premium.profile_photo" alt="" class="img-fluid image-cover">

                          <div appProgressiveImagePlaceholder class="placeholderOneOrThree position-absolute">
                            <div id="loaderProgressive" class="centerProgressive"></div>
                          </div>
                          <div appProgressiveImageFallback class="fallback">
                            <img appProgressiveImageFallback class="img-fluid image-cover"
                              src="assets/images/men-avtar-2.svg" alt="">
                          </div>
                        </app-progressive-image>
                      </ng-container>
                    </div>

                  </div>
                  <div class="px-2 flex-auto">
                    <p class="mb-0 p-sm fw-bold mb-1 text-dark"><span>{{premium.display_name}} </span>
                      <span class="d-inline-block p-xsm mb-0 mx-1">
                        <div class="d-flex align-items-center">
                          <span class="user_email_verified"
                            [ngbTooltip]="premium?.is_user_verified ==1 ? 'Verified user': 'Verified email'"
                            placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                              class="bi bi-shield-fill-check mb-1 " [ngClass]="{'text-muted':premium?.is_user_verified != 1,
                                                 'text-success':premium?.is_user_verified == 1}" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0
                                      0-1.044
                                      1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517
                                      2.453c.386.273.744.482
                                      1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159
                                      7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195
                                      3.061-5.513
                                      2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0
                                      0-2.887-.87C9.843.266
                                      8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708
                                      0l-1.5-1.5a.5.5 0
                                      1 1 .708-.708L7.5 7.793l2.646-2.647z" />
                            </svg>
                          </span>

                          <span class="user_email_verified1 mx-0" *ngIf="premium?.is_user_verified== 0 "
                            placement="bottom" ngbTooltip="Verified email"><i class="fa fa-check fs-6"
                              style="color:green"></i>
                          </span>
                          <span class="user_email_verified1 mx-0" *ngIf="premium?.is_user_verified== 1 "
                            placement="bottom" ngbTooltip="Verified user"><i class="fa fa-check-double fs-6 "
                              style="color:green"></i>
                          </span>
                          <div class="p-xsm mb-0 text-success mx-1" *ngIf="premium.is_logged_in">
                            <div class="flash d-inline-block me-1">
                              <div class>
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">
                              Online
                            </div>
                          </div>
                          <div class="p-xsm mb-0 text-default mx-1" *ngIf="!premium.is_logged_in">
                            <div class="d-inline-block me-1">
                              <div class>
                                <i class="fas fa-globe"></i>
                              </div>
                            </div>
                            <div class="d-inline-block">
                              Offline
                            </div>
                          </div>

                          <div class="ms-2">
                            <img src="assets/images/icon/{{
                                  premium.package_icon
                                  }}" class="width-30" alt placement="top" ngbTooltip="Elite Member"
                              *ngIf="premium.package_id == 3" />
                            <img src="assets/images/icon/{{
                                  premium.package_icon
                                  }}" class="width-30" alt placement="top" ngbTooltip="Premium Member"
                              *ngIf="premium.package_id == 2" />
                          </div>
                        </div>
                      </span>
                    </p>
                    <p class="mb-0 p-xsm truncate-2 text-muted text-break">
                      <strong>Age:</strong>{{premium.age }} yrs,
                      <strong>Height:</strong> {{ premium?.height|heightConverter:"Ft"}},
                      <span>{{ premium?.marital_status | limit:18}} {{ premium?.religion | limit :18}}, </span>
                      <span *ngIf="premium.caste">{{ premium?.caste | limit :18}}, </span>
                      <span>{{ premium?.working_as | limit : 20}}, </span>
                      <span>{{ premium?.stateName | limit : 20}}, </span>
                      <span>{{ premium?.living_in_country_name | limit : 20}}</span>
                    </p>
                  </div>

                </a>
              </div>

              <div *ngIf="PremiumMatches.length == 0 && !EliteLoader "
                class="row mt-3 px-2 rounded-3 scrollbar overflow-auto dashboard-box mb-5">
                <div class="align-items-center justify-content-center mt-2">
                  <h6 class="align-items-center fw-bold center pt-5">You have no Premium Matches at this time.</h6>
                </div>
              </div>

            </div>
          </div>

        </div>

        <!-- mxh-1000 -->
        <div class="col-12 col-md-6 my-3 " [ngClass]="{'mxh-1026': alertsCount>0 && !AlertsLoader}">
          <div id="alert_wpr2" class="border border-1 border-danger rounded-5 shadow-2 px-3 py-2 position-relative"
            [ngClass]="{'h-100': alertsCount>0 && !AlertsLoader}">
            <h5 class="top-0 position-sticky pb-2 fw-bold border border-2 lh-base border-top-0 border-danger
                  border-start-0 border-end-0 d-flex align-items-center justify-content-between z-index-1">
              My Alerts

              <span *ngIf="alertsCount!==0 && !AlertsLoader">

                <button
                  class="cursor_unset btn-outline-danger btn-outline-danger-nbg btn btn-sm me-2 ">{{totalAlert}}</button>

                <button *ngIf="this.selectedIds!=''" class="btn-danger p-0 d-inline-block btn btn-sm center ms-2 border-0 rounded-3 w-30-h-30
                      rounded-circle" (click)="deleteSelectedAlert()" placement="bottom" ngbTooltip="Delete">
                  <i class="fas fa-trash"></i>
                </button>
                <button class="btn-danger p-0 d-inline-block btn btn-sm center ms-2 border-0 rounded-3 w-30-h-30
                      rounded-circle" (click)="refreshAlerts()" placement="bottom" ngbTooltip="Refresh">
                  <i class="fas fa-redo-alt"></i>
                </button>
                <button class="btn-danger p-0 d-inline-block btn btn-sm center ms-2 border-0 rounded-3 w-30-h-30
                      rounded-circle" (click)="manageAlerts()" placement="bottom" ngbTooltip="Manage Alerts">
                  <i class="fas fa-cog"></i>
                </button>
              </span>
            </h5>
            <h2 class="text-danger mt-4 text-center" style="display: none;">Coming soon...</h2>

            <div id="alert_wpr" class="row mt-3 px-2 rounded-3 scrollbar overflow-auto dashboard-box mb-5" #alertBox>

              <ng-container *ngIf="alertsCount!=0">

                <div class="accordion accordion-flush position-" id="accordionFlushExample1">
                  <div class="accordion-item mb-2">
                  </div>

                  <!-- <list-content-loader> -->

                  <div *ngIf="AlertsLoader" class="me-2 animated-background">
                    <div *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]" class="dashboard-box scrollbar overflow-auto mt-2 px-2 rounded-3 d-flex border border-1 align-items-center
                          justify-content-between p-2 animated-background">
                      <div class="flex-none w-65-h-65 rounded-circle overflow-hidden">
                        <img src="assets/images/default-profile-picture.svg"
                          class="image-cover animated-background-name" alt>
                      </div>
                      <div class="px-2 flex-auto">
                        <p class="mb-0 p-sm fw-bold mb-1 text-dark blank-box-communication animated-background-name mt-2"
                          style="width:80%"><span> </span>
                          <span class="d-inline-block p-xsm mb-0 text-success mx-2">
                          </span>
                        </p>
                        <p class="mb-0 p-xsm truncate-2 text-muted text-break truncate-2 p-xsm fw-bold blank-box-communication
                              animated-background-name mt-2" style="width:50%">
                          <strong></strong>
                        </p>
                      </div>
                    </div>

                  </div>
                  <div *ngIf="!AlertsLoader">
                    <div class="accordion-item mb-14" *ngFor="let profile of notificationsConcate; let i= index">
                      <div class="accordion-header" [id]="'flush-headingTwo'+i">
                        <button class="mb-2 border-0 col-12 d-flex align-items-center justify-content-start bg-light
                              rounded shadow-sm p-2">
                          <div class="me-2">
                            <!--  -->
                            <!-- <input type="checkbox" class="checkbox" [checked]="profile?.checkedbox"
                              (change)="checkboxId($event)" id="{{profile.id}}"> -->
                            <!-- <input type="checkbox" class="checkbox" [checked]="profile?.checkedbox"
                              [ngbTooltip]="(profile?.type === 'request_astro_detail' || profile?.type === 'request_contact_detail') ? 'can\'t check for delete' : 'check for delete'"
                              (change)="checkboxId($event)" [id]="profile.id"
                              [disabled]="profile?.type === 'request_astro_detail' || profile?.type === 'request_contact_detail'"> -->
                            <input type="checkbox" class="checkbox" [checked]="checkedStateMap[profile.id]"
                              [ngbTooltip]="(profile?.type === 'request_astro_detail' || profile?.type === 'request_contact_detail') ? 'can\'t check for delete' : 'check for delete'"
                              (change)="updateCheckedState(profile, $event.target.checked) ;checkboxId($event)"
                              [id]="profile.id"
                              [disabled]="profile?.type === 'request_astro_detail' || profile?.type === 'request_contact_detail'">

                          </div>
                          <!--  -->
                          <div *ngIf="profile?.sender_type!='admin'" class="me-2">
                            <a class (click)="ViewProfile(profile.sender_id)">
                              <div class="w-50-h-50 rounded-circle overflow-hidden" placement="top">
                                <img src="assets/images/men-avtar-2.svg" alt="" class="image-cover"
                                  *ngIf="!profile.profile_photo || profile.profile_photo_approved != 1">
                                <div
                                  *ngIf="profile?.profile_photo != null && !!profile?.profile_photo && profile.profile_photo_approved==1">
                                  <ng-container
                                    *ngIf="profile?.photo_visiblity == 'No one' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage != 5)">
                                    <img class="image-cover" src="assets/images/default-profile-picture.svg" alt=""
                                      alt="assets/images/men-avtar-2.svg">
                                  </ng-container>
                                  <ng-container
                                    *ngIf="profile?.photo_visiblity == 'Visible to all members' || (profile?.photo_visiblity == 'Visible to connected members' && profile?.stage == 5)">
                                    <img src="{{profile.profile_photo}}" alt="" class="image-cover">
                                  </ng-container>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div *ngIf="profile?.sender_type=='admin'" class="me-2">
                            <!-- <a class> -->
                            <div class="w-50-h-50 rounded-circle overflow-hidden" placement="top">
                              <img src="assets/images/men-avtar-2.svg" alt="" class="image-cover">
                            </div>
                          </div>

                          <div class="flex-auto collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#flush-collapseTwo'+i" aria-expanded="false"
                            [attr.aria-controls]="'flush-collapseTwo'+i">
                            <div class="mb-1 d-flex align-items-center justify-content-between">

                              <h6 class="mb-0 fw-600">
                                <span *ngIf="profile?.sender_type=='user'">{{profile.display_name}}</span>
                                <span *ngIf="profile?.sender_type=='admin'">Admin</span>
                                <span *ngIf="profile?.sender_type=='automatic'">Admin</span>
                              </h6>

                              <strong class="text-danger p-xsm"> {{profile.created_at | date:'MMMM d'}} </strong>
                            </div>
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'profile_photo'">
                              Please upload your profile photo for reach.
                            </p>
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'plan_expire'">
                              {{profile.message}}. <a href="javascript:void(0)" (click)="renewPlan()">Click
                                here</a> to renew.
                            </p>
                            <p class=" mb-0 text-start p-xsm"
                              *ngIf=" profile.type == 'free_trial_expired' || profile.type == 'free_trial_expiring'">
                              {{profile.message}}. <a href="javascript:void(0)" routerLink="/upgrade"
                                class="text-danger">
                                Please Upgrade </a>
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'cover_photo'">
                              Please upload your cover photo for reach.
                            </p>
                            <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'new_feature'">
                              Eternal string added new feature on the site please check.
                            </p> -->
                            <!-- <span *ngIf="profile.type == 'new_feature'" [innerHtml]="profile.message | safeHtmlPipe">
                            </span> -->


                            
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'new_feature'">
                              {{profile?.messageText}}
                              <span *ngIf="profile.pageUrl">
                                <a 
                                class="text-underline cursor-pointer"
                                (click)="ViewAlertPageFeature1(profile.pageUrl)">
                                click here
                              </a> to view .
                              </span>
                            </p>


                            <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'event_notification'">
                              {{profile?.messageTextEvent}}
                              <span >
                               Start Date : {{profile?.messagestart_date}}
                              </span>
                              <span >
                                Time From : {{profile?.timeFrom}}
                               </span>
                              <span >
                                End Date : {{profile?.messageend_date}}
                               </span>

                               <span >
                                Time To : {{profile?.timeto}}
                               </span>
                            </p> -->

                            

                            <div *ngIf="profile.type == 'event_notification'">
                              <div class="mb-0 text-start p-xsm" *ngIf="!profile?.sameDate">
                                  <p class="mb-0 text-start p-xsm">
                                      {{profile?.messageTextEvent}}
                                  </p>
                                  <div>
                                      Start Date : {{profile?.messagestart_date}}, Time From : {{profile?.timeFrom}}
                                  </div>
                                  <!-- <div>
                                      Time From: {{profile?.timeFrom}}
                                  </div> -->
                                  <div>
                                      End Date : {{profile?.messageend_date}}, Time To : {{profile?.timeto}}
                                  </div>
                                  <!-- <div>
                                      Time To: {{profile?.timeto}}
                                  </div> -->
                              </div>
                          
                              <div class="mb-0 text-start p-xsm" *ngIf="profile?.sameDate">
                                  <p class="mb-0 text-start p-xsm">
                                      {{profile?.messageTextEvent}}
                                  </p>
                                  <div>
                                      Date : {{profile?.messagestart_date}}
                                  </div>
                                  <div>
                                      Time From : {{profile?.timeFrom}}, To : {{profile?.timeto}}
                                  </div>
                              </div>
                          </div>
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'success_story'">
                            {{profile.message}}.
                            <!-- <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a> -->
                            
                          </p>
                          


                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'add_to_shortlist'">
                              {{profile.display_name}} has added you to their My shortlist.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a> to view Profile.
                            </p>
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'add_to_favourite'">
                              {{profile.display_name}} has added you to their My favourite.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a> to view Profile.
                            </p>
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'viewed_by_other'">
                              {{profile.display_name}} has viewed your profile.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>
                            <p class=" mb-0 text-start p-xsm"
                              *ngIf="profile.type == 'request_astro_detail' || profile.type == 'request_contact_detail'">
                              {{profile.display_name}} has requested access to view your
                              <sapn *ngIf="profile.type == 'request_astro_detail'">astro</sapn>
                              <span *ngIf=" profile.type == 'request_contact_detail'">contact</span> details.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>
                            <p class=" mb-0 text-start p-xsm"
                              *ngIf="profile.type == 'allowed_astro_detail' || profile.type == 'allowed_contact_detail'">
                              {{profile.display_name}} has Allowed you access to view
                              <sapn *ngIf="profile.type == 'allowed_astro_detail'">astro</sapn>
                              <span *ngIf=" profile.type == 'allowed_contact_detail'">contact</span> details.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>
                            <p class=" mb-0 text-start p-xsm"
                              *ngIf="profile.type == 'denied_astro_detail' || profile.type == 'denied_contact_detail'">
                              {{profile.display_name}} has Denied you access to view
                              <sapn *ngIf="profile.type == 'denied_astro_detail'">astro</sapn>
                              <span *ngIf=" profile.type == 'denied_contact_detail'">contact</span> details.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <!-- alert for Profile Photo,Cover Photo, Gallery -->
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'profile_picture_verified'">
                              {{profile.display_name}} has changed their Profile picture.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'cover_picture_verified'">
                              {{profile.display_name}} has changed their Cover picture.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'gallery_picture_verified'">
                              {{profile.display_name}} has added more pictures to their Photo Gallery.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <!-- myinfo_setion: about_you/basics_lifestyle
  /religious_views/astro_details/family_details/
  education_professional_details/hobbies_interests_more
  /your_location/contact_details -->

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'about_you'">
                              {{profile.display_name}} has updated their Profile in the About You section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a> to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'basics_lifestyle'">
                              {{profile.display_name}} has updated their Profile in the Basic & Lifestyle section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'religious_views'">
                              {{profile.display_name}} has updated their Profile in the Religious Views section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'astro_details'">
                              {{profile.display_name}} has updated their Profile in the Astro Details section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a> to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'family_details'">
                              {{profile.display_name}} has updated their Profile in the Family Details section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'education_professional_details'">
                              {{profile.display_name}} has updated their Profile in the Education & Professional Details
                              section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'hobbies_interests_more'">
                              {{profile.display_name}} has updated their Profile in the Hobbies, Interests & more
                              section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'your_location'">
                              {{profile.display_name}} has updated their Profile in the Your Location section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'contact_details'">
                              {{profile.display_name}} has updated their Profile in the Contact Details section.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'user_verified'">
                              <!-- {{profile.display_name}} has updated their Profile in the Contact Details section. -->
                              <strong>{{this.user.firstName}} {{this.user.lastName}}</strong> your Phone
                              <strong>{{this.user.mobileNo}}</strong> has been verified by Admin.
                            </p>

                            <!-- *********************** -->
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'approved_profile_photo'">
                              Your Profile Photo has been approved.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'approved_cover_photo'">
                              Your Cover Photo has been approved.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'approved_photo_gallery_photo'">
                              Your Photo Gallery Photo has been approved.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'not_approved_profile_photo'">
                              Your Profile Photo has not been approved. Please review <span
                                class="text-underline cursor-pointer" data-bs-toggle="modal"
                                data-bs-target="#guidelinesModal"> Photo Guidelines. </span>
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'not_approved_cover_photo'">
                              Your Cover Photo has not been approved. Please review <span
                                class="text-underline cursor-pointer" data-bs-toggle="modal"
                                data-bs-target="#guidelinesModal"> Photo Guidelines. </span>
                            </p>

                            <p class=" mb-0 text-start p-xsm"
                              *ngIf="profile.type == 'not_approved_photo_gallery_photo'">
                              Your Photo Gallery Photo has not been approved. Please review <span
                                class="text-underline cursor-pointer" data-bs-toggle="modal"
                                data-bs-target="#guidelinesModal"> Photo Guidelines. </span>
                            </p>
                            <!-- hide profile alert start -->
                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'hidden_profile_self'">
                              Your Profile is hidden by you. <span class="text-underline cursor-pointer"
                                href="javascript:void(0)" (click)="unhide()"> Unhide profile. </span>
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'unhide_profile_self'">
                              Your Profile maked unhide by you.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'hide_profile'">
                              {{profile.display_name}} has hidden their profile.
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'unhide_profile'">
                              {{profile.display_name}} has un-hidden their profile.
                              <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                class="text-underline cursor-pointer"
                                (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                click here
                              </a>to view Profile.
                            </p>
                            <!-- hide profile alert end -->

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'extend_membership'">
                              {{profile.message}} .
                            </p>

                            <!-- admin safety / Disclaimer / Payment policy / Cookie Preference alerts stert-->

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_safety_tips'">
                              {{profile.message}} .
                              Please review
                              <a href="javascript:void(0)" routerLink="/safety-tips"
                                class="  text-underline cursor-pointer">
                                Safety Tips.
                              </a>
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_disclaimer'">
                              {{profile.message}} .
                              Please review
                              <a href="javascript:void(0)" routerLink="/Disclaimer"
                                class="  text-underline cursor-pointer">
                                Disclaimer.
                              </a>
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_payment_policy'">
                              {{profile.message}} .
                              Please review
                              <a href="javascript:void(0)" routerLink="/payment"
                                class="  text-underline cursor-pointer"> Payment
                                Policy.
                              </a>
                            </p>

                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_cookie_preference'">
                              {{profile.message}} .
                              Please review <a data-bs-toggle="modal" class="  text-underline cursor-pointer"
                                data-bs-target="#CookiesModal">
                                Cookie
                                Preferences.</a>
                            </p>

                            <p class=" mb-0 text-start p-xsm"
                              *ngIf="profile.type == 'plan_expired'">
                              {{profile.message}}. <a href="javascript:void(0)" (click)="renewPlan()">Click
                                here</a> to renew.
                            </p>



                            <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'Suspicious Activity'">
                              {{profile.message}} .
                            </p>


                            <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'event_notification'">
                              {{profile.message}} .
                            </p> -->

                            <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'new_feature'">
                             Eternal String added New Feature on the site.
                            </p> -->

                            <!-- admin safety / Disclaimer / Payment policy / Cookie Preference alerts end-->

                          </div>
                        </button>
                      </div>

                      <div [id]="'flush-collapseTwo'+i" class="accordion-collapse collapse shadow-2 position-"
                        [attr.aria-labelledby]="'flush-headingTwo'+i" data-bs-parent="#accordionFlushExample1">
                        <div class="reminder-box position-relative rounded accordion-body bg-white border-1 border
                              border-danger z-index-1">
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="mb-2">
                              <h6 class="mb-0 fw-600">
                                <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                                  class="text-dark" (click)="ViewProfile(profile.sender_id,profile.display_name)">
                                  {{profile.display_name}}
                                </a>
                                <a *ngIf="profile?.sender_type=='user' && (profile.type == 'hide_profile' )"
                                  class="text-dark">
                                  {{profile.display_name}}
                                </a>
                                <p *ngIf="profile?.sender_type=='admin'" class="text-dark">
                                  Admin
                                </p>
                                <p *ngIf="profile?.sender_type=='automatic'" class="text-dark">
                                  Admin
                                </p>


                              </h6>
                              <p class="text-danger mb-0 p-xsm"> <strong>Sent:
                                </strong> {{profile.created_at | date:'MMMM d, y \'at\' h.mm a'}}</p>
                            </div>
                            <div class="mb-2 d-flex align-items-center justify-content-end">
                              <button
                                *ngIf="profile.type !== 'request_astro_detail' && profile.type !== 'request_contact_detail'"
                                class="btn btn-danger border-0 center p-0 me-2 w-30-h-30 rounded-circle" placement="top"
                                ngbTooltip="Delete" (click)="deleteAlert(profile.id)">
                                <i class="fas fa-trash-alt"></i>
                              </button>
                              <button class="btn p-0 center btn-danger text-end w-30-h-30 rounded-circle border-0
                                    collapsed" placement="top" ngbTooltip="Close" type="button"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseTwo'+i"
                                aria-expanded="false" [attr.aria-controls]="'flush-collapseTwo'+i">
                                <i class="fas fa-times"></i>
                              </button>
                            </div>
                          </div>
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'profile_photo'">
                            Please upload your profile photo for better reach.
                          </p>
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'cover_photo'">
                            Please upload your cover photo for better reach.
                          </p>
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'plan_expire'">
                            {{profile.message}}. <a href="javascript:void(0)" (click)="renewPlan()">Click
                              here</a> to renew.
                          </p>

                          <!-- <span *ngIf="profile.type == 'new_feature'" [innerHtml]="profile.message | safeHtmlPipe">
                          </span> -->

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'new_feature'">
                            {{profile?.messageText}}
                            <span *ngIf="profile.pageUrl">
                              <a 
                              class="text-underline cursor-pointer"
                              (click)="ViewAlertPageFeature1(profile.pageUrl)">
                              click here
                            </a> to view .
                            </span>
                           
                          </p>


                          <div *ngIf="profile.type == 'event_notification'">
                            <div class="mb-0 text-start p-xsm" *ngIf="!profile?.sameDate">
                                <p class="mb-0 text-start p-xsm">
                                    {{profile?.messageTextEvent}}
                                </p>
                                <div>
                                    Start Date : {{profile?.messagestart_date}}, Time From : {{profile?.timeFrom}}
                                </div>
                               
                                <div>
                                    End Date : {{profile?.messageend_date}}, Time To : {{profile?.timeto}}
                                </div>
                               
                            </div>
                        
                            <div class="mb-0 text-start p-xsm" *ngIf="profile?.sameDate">
                                <p class="mb-0 text-start p-xsm">
                                    {{profile?.messageTextEvent}}
                                </p>
                                <div>
                                    Date : {{profile?.messagestart_date}}
                                </div>
                                <div>
                                    Time From : {{profile?.timeFrom}}, To : {{profile?.timeto}}
                                </div>
                            </div>
                        </div>

                        <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'success_story'">
                          {{profile.message}}.
                          <!-- <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                            class="text-underline cursor-pointer"
                            (click)="ViewProfile(profile.sender_id,profile.display_name)">
                            click here
                          </a> -->
                          
                        </p>
                          <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'plan_expired'">
                            {{profile.message}}. <a href="javascript:void(0)" (click)="renewPlan()">Click
                              here</a> to renew.
                          </p> -->
                          <p class=" mb-0 text-start p-xsm"
                            *ngIf=" profile.type == 'free_trial_expired'  || profile.type == 'free_trial_expiring'">
                            {{profile.message}}. <a href="javascript:void(0)" routerLink="/upgrade" class="text-danger">
                              Please Upgrade </a>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'add_to_shortlist'">
                            {{profile.display_name}} has added you to their My shortlist.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'add_to_favourite'">
                            {{profile.display_name}} added you to their My favourite.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'viewed_by_other'">
                            {{profile.display_name}} has viewed your profile.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>
                          <p class=" mb-0 text-start p-xsm"
                            *ngIf="profile.type == 'request_astro_detail' || profile.type == 'request_contact_detail'">
                            {{profile.display_name}} has requested access to view your
                            <sapn *ngIf="profile.type == 'request_astro_detail'">astro</sapn>
                            <span *ngIf=" profile.type == 'request_contact_detail'">contact</span> details.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a> to view Profile.
                          </p>
                          <p class=" mb-0 text-start p-xsm"
                            *ngIf="profile.type == 'allowed_astro_detail' || profile.type == 'allowed_contact_detail'">
                            {{profile.display_name}} has Allowed you access to view
                            <sapn *ngIf="profile.type == 'allowed_astro_detail'">astro</sapn>
                            <span *ngIf=" profile.type == 'allowed_contact_detail'">contact</span> details.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>
                          <p class=" mb-0 text-start p-xsm"
                            *ngIf="profile.type == 'denied_astro_detail' || profile.type == 'denied_contact_detail'">
                            {{profile.display_name}} has Denied you access to view
                            <sapn *ngIf="profile.type == 'denied_astro_detail'">astro</sapn>
                            <span *ngIf=" profile.type == 'denied_contact_detail'">contact</span> details.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>


                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'profile_picture_verified'">
                            {{profile.display_name}} has changed their Profile picture.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.

                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'cover_picture_verified'">
                            {{profile.display_name}} has changed their Cover picture.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'gallery_picture_verified'">
                            {{profile.display_name}} has added more pictures to their Photo Gallery.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'about_you'">
                            {{profile.display_name}} has updated their Profile in the About You section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'basics_lifestyle'">
                            {{profile.display_name}} has updated their Profile in the Basic & Lifestyle section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'religious_views'">
                            {{profile.display_name}} has updated their Profile in the Religious Views section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'astro_details'">
                            {{profile.display_name}} has updated their Profile in the Astro Details section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'family_details'">
                            {{profile.display_name}} has updated their Profile in the Family Details section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'education_professional_details'">
                            {{profile.display_name}} has updated their Profile in the Education & Professional Details
                            section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'hobbies_interests_more'">
                            {{profile.display_name}} has updated their Profile in the Hobbies, Interests & more
                            section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'your_location'">
                            {{profile.display_name}} has updated their Profile in the Your Location section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'contact_details'">
                            {{profile.display_name}} has updated their Profile in the Contact Details section.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>



                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'user_verified'">
                            <!-- {{profile.display_name}} has updated their Profile in the Contact Details section. -->
                            <strong>{{this.user.firstName}} {{this.user.lastName}}</strong> your Phone
                            <strong>{{this.user.mobileNo}}</strong> has been verified by Admin.
                          </p>

                          <!-- *********************** -->
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'approved_profile_photo'">
                            Your Profile Photo has been approved.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'approved_cover_photo'">
                            Your Cover Photo has been approved.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'approved_photo_gallery_photo'">
                            Your Photo Gallery Photo has been approved.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'not_approved_profile_photo'">
                            Your Profile Photo has not been approved. Please review <span
                              class="text-underline cursor-pointer" data-bs-toggle="modal"
                              data-bs-target="#guidelinesModal"> Photo Guidelines. </span>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'not_approved_cover_photo'">
                            Your Cover Photo has not been approved. Please review <span
                              class="text-underline cursor-pointer" data-bs-toggle="modal"
                              data-bs-target="#guidelinesModal"> Photo Guidelines. </span>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'not_approved_photo_gallery_photo'">
                            Your Photo Gallery Photo has not been approved. Please review <span
                              class="text-underline cursor-pointer" data-bs-toggle="modal"
                              data-bs-target="#guidelinesModal"> Photo Guidelines. </span>
                          </p>
                          <!-- hide profile alert start -->
                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'hidden_profile_self'">
                            Your Profile is hidden by you. <span class="text-underline cursor-pointer"
                              href="javascript:void(0)" (click)="unhide()"> Unhide profile. </span>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'unhide_profile_self'">
                            Your Profile maked unhide by you.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'hide_profile'">
                            {{profile.display_name}} has hidden their profile.
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'unhide_profile'">
                            {{profile.display_name}} has un-hidden their profile.
                            <a *ngIf="profile?.sender_type=='user' && (profile.type != 'hide_profile' ) "
                              class="text-underline cursor-pointer"
                              (click)="ViewProfile(profile.sender_id,profile.display_name)">
                              click here
                            </a>to view Profile.
                          </p>
                          <!-- hide profile alert end -->
                          <!-- ******** -->

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'extend_membership'">
                            {{profile.message}} .
                          </p>


                          <!-- admin safety / Disclaimer / Payment policy / Cookie Preference alerts stert-->

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_safety_tips'">
                            {{profile.message}} .
                            Please review
                            <a href="javascript:void(0)" routerLink="/safety-tips"
                              class="  text-underline cursor-pointer">
                              Safety Tips.
                            </a>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_disclaimer'">
                            {{profile.message}} .
                            Please review
                            <a href="javascript:void(0)" routerLink="/Disclaimer"
                              class="  text-underline cursor-pointer">
                              Disclaimer.
                            </a>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_payment_policy'">
                            {{profile.message}} .
                            Please review
                            <a href="javascript:void(0)" routerLink="/payment" class="  text-underline cursor-pointer">
                              Payment
                              Policy.
                            </a>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'update_cookie_preference'">
                            {{profile.message}} .
                            Please review <a data-bs-toggle="modal" class="  text-underline cursor-pointer"
                              data-bs-target="#CookiesModal">
                              Cookie
                              Preferences.</a>
                          </p>

                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'plan_expired'">
                            {{profile.message}}. <a href="javascript:void(0)" (click)="renewPlan()">Click
                              here</a> to renew.
                          </p>


                          <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'Suspicious Activity'">
                            {{profile.message}} .
                          </p>


                          <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'event_notification'">
                            {{profile.message}} .
                          </p> -->
                          <!-- <p class=" mb-0 text-start p-xsm" *ngIf="profile.type == 'new_feature'">
                            {{profile.message}} .
                          </p> -->

                          <!-- admin safety / Disclaimer / Payment policy / Cookie Preference alerts end-->


                          <ng-container
                            *ngIf="profile.type == 'request_astro_detail' || profile.type == 'request_contact_detail'">
                            <div class="m-2 text-end">
                              <!-- <button class="btn btn-danger btn-sm me-2" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#flush-collapseTwo'+i" aria-expanded="false"
                                [attr.aria-controls]="'flush-collapseTwo'+i"
                                (click)="ViewProfile(profile.sender_id)">View
                                Profile</button> -->

                              <button class="btn btn-danger btn-sm me-2" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#flush-collapseTwo'+i" aria-expanded="false"
                                [attr.aria-controls]="'flush-collapseTwo'+i"
                                (click)="allowToAccess(profile,1)">Allow</button>

                              <button class="btn btn-danger btn-sm border border-1 border-danger me-2"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseTwo'+i"
                                aria-expanded="false" [attr.aria-controls]="'flush-collapseTwo'+i"
                                (click)="allowToAccess(profile,2)">Deny</button>

                            </div>
                          </ng-container>
                        </div>
                      </div>

                    </div>
                    <!-- text-xl-center -->
                    <div class="" #scrollMe (scroll)="onScrollMe($event)">
                      <a href="javascript:void(0);" *ngIf="showMoreAlert" (click)="showMoreUserAlertFun(nextPage)"
                        class="read-more m-t-30" placement="bottom">
                        <span class="mdi mdi-chevron-double-up btn-danger text-white show-more-button"> <i
                            class="fas fa-angle-double-up"></i> Show More</span>

                      </a>

                      <span *ngIf="alertsCount!==0 && !AlertsLoader  && totalAlert>5"
                        style="position: absolute;right: 20px;bottom: 9px;">

                        <button class="cursor_unset btn-danger btn btn-sm me-2 ">{{totalAlert}}</button>
                        <button *ngIf="this.selectedIds!=''" class="btn-danger p-0 d-inline-block btn btn-sm center ms-2 border-0 rounded-3 w-30-h-30
                              rounded-circle" (click)="deleteSelectedAlert()" placement="bottom" ngbTooltip="Delete">
                          <i class="fas fa-trash"></i>
                        </button>
                        <button class="btn-danger p-0 d-inline-block btn btn-sm center ms-2 border-0 rounded-3 w-30-h-30
                              rounded-circle" (click)="refreshAlerts()" placement="bottom" ngbTooltip="Refresh">
                          <i class="fas fa-redo-alt"></i>
                        </button>
                        <button class="btn-danger p-0 d-inline-block btn btn-sm center ms-2 border-0 rounded-3 w-30-h-30
                              rounded-circle" (click)="manageAlerts()" placement="bottom" ngbTooltip="Manage Alerts">
                          <i class="fas fa-cog"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- html for annoucement -->
                <div *ngIf="!AlertsLoader && false">
                  <div class="col-12 d-flex align-items-center justify-content-start bg-light rounded shadow-sm py-2 "
                    *ngFor='let in of SkeltonLoader(15) ;let i= index'>
                    <div class="me-2">
                      <input type="checkbox" class="checkbox">
                    </div>
                    <div class="me-2">
                      <button class="w-40-h-40 btn-blue btn rounded-circle center border-0" placement="top"
                        ngbTooltip="News & Announcement">
                        <i class="fas fa-bullhorn"></i>
                      </button>
                    </div>
                    <div class="flex-auto">
                      <div class="mb-1 d-flex align-items-center justify-content-between">
                        <h6 class="mb-0 fw-600">News & Announcement</h6> <strong class="text-danger"><small>November
                            25</small> </strong>
                      </div>
                      <p class="truncate-2 mb-0 p-xsm">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
                        mollitia, quisquam distinctio eos magnam ducimus voluptatibus optio
                        quidem
                        fuga illo?
                      </p>
                    </div>
                  </div>

                </div>
              </ng-container>
              <div *ngIf="alertsCount==0 && !AlertsLoader" class="align-items-center justify-content-center mt-2">
                <h6 class="align-items-center fw-bold center pt-5"> You have no Alerts at this time. </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- tab-one -->
    <div style="display: none;" class="tab-pane fade px-3 mx-width-800 mx-auto" id="nav-home" role="tabpanel"
      aria-labelledby="nav-home-tab">

      <div class="row">
        <div class="col-12 bg-light">
          <h5 class="mb-0 py-3 fw-bold"> John Doe <span class="p-sm"> (JWM123456789) </span> </h5>
        </div>
      </div>

      <div class="row mx-0 my-2">
        <div class="col-12 col-sm-3 border border-1 px-0">
          <img class="image-cover" src="assets/images/a6139a0139e8734ab3c77307e8c9a29d.jpg" alt>
        </div>
        <div class="col-12 col-sm-9 p-3 mt-2">

          <div class="row m-0">
            <div class="col-6">
              <div class="row">
                <div class="col-6 px-0">
                  <p class="p-sm text-muted text-break fw-bold">Age / Height</p>
                </div>
                <div class="col-6 px-0">
                  <p class="p-sm"><span class="fw-bold fs-6"> : </span> 22 / 4' 5"</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 px-0">
                  <p class="p-sm text-muted text-break fw-bold">Marital Status</p>
                </div>
                <div class="col-6 px-0 px-0">
                  <p class="p-sm"><span class="fw-bold fs-6"> : </span> Single</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 px-0">
                  <p class="p-sm text-muted text-break fw-bold">Posted By</p>
                </div>
                <div class="col-6 px-0">
                  <p class="p-sm"><span class="fw-bold fs-6"> : </span> Other</p>
                </div>
              </div>

            </div>

            <div class="col-6">

              <div class="row">
                <div class="col-6 px-0">
                  <p class="p-sm text-muted text-break fw-bold">Religion / Caste</p>
                </div>
                <div class="col-6 px-0">
                  <p class="p-sm"><span class="fw-bold fs-6"> : </span>Hindu, Agarwal</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 px-0">
                  <p class="p-sm text-muted text-break fw-bold">Location</p>
                </div>
                <div class="col-6 px-0">
                  <p class="p-sm"><span class="fw-bold fs-6"> : </span> Indore</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 px-0">
                  <p class="p-sm text-muted text-break fw-bold">First Language</p>
                </div>
                <div class="col-6 px-0">
                  <p class="p-sm"><span class="fw-bold fs-6"> : </span> Hindi</p>
                </div>
              </div>

            </div>

          </div>

          <div class="row m-0">
            <div class="col-12 border border-1 border-danger p-2">
              <h5 class="text-danger fw-bold"> Manage your Profile </h5>

              <div class="row">
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" routerLink="/edit" class="text-danger p-sm">Edit
                    Personal Profile</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" class="text-danger p-sm">View Profile
                    Status</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" class="text-danger p-sm">Set Contact
                    Filters</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" routerLink="/edit" class="text-danger p-sm">Edit
                    Partner Profile</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" class="text-danger p-sm">Alast_name Photos</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" class="text-danger p-sm">Hide / Delete
                    Profile</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" routerLink="/edit" class="text-danger p-sm">Edit
                    Contact Details</a>
                </div>
                <div class="col-6 col-sm-4">
                  <a href="javascript:void(0)" class="text-danger p-sm">Hobbies &
                    Interests</a>
                </div>
              </div>
            </div>
            <div class="col-12 text-end">
              <a href="javascript:void(0)" class="p-sm text-danger"> Preview your profile</a>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 border border-2 border-danger border-bottom-0 border-start-0 border-end-0">
        <div class="row m-0">
          <div class="col-12 px-0">
            <button type="button" class="btn btn-danger rounded-0">About You</button>
            <button type="button" class="ms-2 btn btn-danger rounded-0">Partner Preferences</button>
          </div>

          <div class="col-12 px-0 mt-4">
            <div class="row m-0">
              <div class="col-10 px-0">
                <h6 class="fw-bold mb-0 py-1"> Personality Description etc.</h6>
              </div>
              <div class="col-2 text-end">
                <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
              </div>
            </div>
            <div class="row m-0 border border-1 border-bottom-0 border-start-0 border-end-0">
              <div class="col-12">
                <p class="p-sm lh-sm mt-2 text-secondary">Hello, here is a quick introduction
                  about
                  my relative. <br> He has completed his B.E / B.Tech. He believes in having a
                  blend of modern & traditional values and balancing professional & family
                  life.
                  Please get in touch
                  if you would like to know more about him.</p>
              </div>
            </div>
          </div>

          <!-- start Basics & Lifestyle -->
          <div class="col-12 px-0 mt-4">
            <div class="row m-0">
              <div class="col-10 px-0">
                <h6 class="fw-bold mb-0 py-1"> Basics & Lifestyle</h6>
              </div>
              <div class="col-2 text-end">
                <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
              </div>
            </div>
            <div class="row m-0 border border-1 border-bottom-0 border-start-0 border-end-0">
              <div class="col-6 mt-2">
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break fw-bold mb-0">Age</p>
                  </div>
                  <div class="col-6">
                    <span class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>22</span>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Date of Birth</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0 "><span class="fw-bold fs-6"> :
                      </span>
                      01-Jan-2000
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold">Marital Status</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      Single
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Height</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span> 4'
                      5" (134cm)</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Grew Up In</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      India
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-6 mt-2">
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Food Preference</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      Veg
                    </p>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Personal Values</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      Will tell you later</p>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break fw-bold mb-0"> Sun Sign</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>Capricorn</p>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break fw-bold mb-0"> Do you smoke? </p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>Yes
                    </p>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break fw-bold mb-0"> Do you drink? </p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>Yes
                    </p>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break fw-bold mb-0"> Do you drink? </p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>Yes
                    </p>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Blood Group</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      Enter Now</p>
                  </div>
                </div>

                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold"> Health Information
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      Not Specified</p>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-6">
                    <p class="p-sm text-muted text-break mb-0 fw-bold">Disability</p>
                  </div>
                  <div class="col-6">
                    <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                      </span>
                      None
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!-- end Basics & Lifestyle -->

          <div class="row mx-0 mt-4 px-0">
            <!--start religious background -->
            <div class="col-12 col-md-6 px-0">

              <div class="row m-0">
                <div class="col-10 px-0">
                  <h6 class="fw-bold mb-0 py-1"> Religious Views</h6>
                </div>
                <div class="col-2 text-end">
                  <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
                </div>
              </div>
              <div class="row m-0 border border-1 border-bottom-0 border-start-0 border-end-0">
                <div class="col-12 mt-2">
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold">Religion</p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Hindu</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold"> Community</p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Agarwal</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold">Caste</p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Not Specified</p>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold"> Gothra / Gothram
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Not Specified</p>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold">First Language
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Hindi</p>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold">Language Spoken
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm mb-0"><span class="fw-bold fs-6"> : </span> English
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end religious background -->

            <!--start astro -->
            <div class="col-12 col-md-6 px-0">
              <div class="row m-0">
                <div class="col-10 px-0">
                  <h6 class="ps-2 fw-bold mb-0 py-1"> Astro Details</h6>
                </div>
                <div class="col-2 text-end">
                  <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
                </div>
              </div>
              <div class="row m-0 border border-1 border-bottom-0 border-start-0 border-end-0">
                <div class="col-12 mt-2">
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold">Manglik</p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Don't Know</p>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold"> Date of Birth
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> 01-Jan-2000</p>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold"> Time of Birth
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Enter Now</p>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <p class="p-sm text-muted text-break mb-0 fw-bold"> City of Birth
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                        </span> Enter Now</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--start astro -->
          </div>

          <!--start family-detils -->
          <div class="row px-0 mx-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Family Details</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Father's Status</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Mother's Status</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Family Location</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                    </span>Indore,
                    Madhya Pradesh, India
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Native Place </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>Not
                    Specified
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">No. of Brothers</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> No. of Sisters</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Family Type</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Not
                    Specified
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Family Values</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Not
                    Specified
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Family Affluence</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>

            </div>
          </div>
          <!--end family details  -->

          <!--start family-detils -->
          <div class="row px-0 mx-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Education & Professional Details</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Education</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>B.E
                    /
                    B.Tech
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">College/University Name
                  </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                    </span>Acharya
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Annual Income</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                    </span>50,000
                    to 75,000 CAD</p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Working With</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                    </span>Private
                    Company
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted mb-0 fw-bold"> Profession Area</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :
                    </span>Banking
                    Professional
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Company Name </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>A
                    PJ
                    Project </p>
                </div>
              </div>
            </div>
          </div>
          <!--end family details  -->

          <!--start Location of Groom -->
          <div class="row mx-0 px-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Your Location </h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Current Residence</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Indore, India</p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">State Of Residence</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Madhya
                    Pradesh
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Residency Status</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Citizen
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Zip/Pin/Postal code</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Not
                    Specified
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end Location of Groom  -->

          <!--start Hobbies, Interests & more -->
          <div class="row mt-4 mx-0 px-0">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Hobbies, Interests & more</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Hobbies</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Interests</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Favourite Music</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Favourite Cuisine </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
            </div>

            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Preferred Movies</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Sports / Fitness
                    Activities
                  </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Favourite Cuisine</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Preferred Dress Style</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Enter
                    Now
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end Hobbies, Interests & more  -->

          <!--start Other-detils -->
          <div class="row mx-0 px-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Other Details</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Profile Created By</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Self
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Food Preference </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Veg
                  </p>
                </div>
              </div>

            </div>
          </div>
          <!--end Other details  -->

          <div class="col-12 border border-2 border-secondary mt-3 bg-secondary"></div>
          <div class="col-12 py-1 mb-3 text-end">
            <a href="javascript:void(0)" class="p-sm text-danger">Back to top</a>
          </div>

          <!-- --------------------Start partner Preference----------------- -->
          <div class="col-12 px-0 bg-light">
            <h5 class="mb-0 px-2 py-3 fw-bold">Partner Preferences</h5>
          </div>
          <!--start partner Preference Basic Details -->
          <div class="row mx-0 px-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Basic Info</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Age</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span> 18
                    to
                    22
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Height </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span> 4'
                    5"
                    to 4' 11"</p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Religion / Community</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Hindu
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> First Language</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Hindi
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Marital Status</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Single
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end partner Preferences Basic Details  -->

          <!--start partner Preferences Location Details -->
          <div class="row mx-0 px-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Location Details</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Country Living In</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    India
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> State/Province </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    M.P.
                  </p>
                </div>
              </div>
              <div class="row mb-10 ">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> City</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    Indore
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">

            </div>
          </div>
          <!--end partner Preferences Location Details  -->

          <!--start partner Preferences Education & Professional Details -->
          <div class="row px-0 mx-0 mt-4">
            <div class="col-10 px-0">
              <h6 class="fw-bold mb-0 py-1">Education & Professional Details</h6>
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Education</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Working With </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Profession Area</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted mb-0 fw-bold"> Profession Area</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Annual Income</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    CAD
                    less than 100,000 to 500,000.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end partner Preferences Education & Professional Details  -->

          <!-- --------------------end-partner-Preferences----------------- -->

          <div class="col-12 my-4 px-0 bg-light">
            <h5 class="mb-0 px-2 py-3 fw-bold">My Contact Details</h5>
          </div>
          <div class="row mx-0 px-0">
            <div class="col-10 px-0">
            </div>
            <div class="col-2 text-end">
              <a href="javascript:void(0)" routerLink="/edit" class="p-sm text-danger">Edit</a>
            </div>
          </div>
          <div class="row border border-1 border-bottom-0 border-start-0 border-end-0">
            <div class="col-12 col-md-6 mt-2">
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Mobile</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> : </span>
                    +91-8878480214
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Contact Person</p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :- </span>
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold">Relationship With The
                    Member
                  </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"><span class="fw-bold fs-6"> :- </span>
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Convenient Time To Call
                  </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"> <span class="fw-bold fs-6"> : </span>
                  </p>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-6">
                  <p class="p-sm text-muted text-break mb-0 fw-bold"> Display Option </p>
                </div>
                <div class="col-6">
                  <p class="p-sm text-secondary mb-0"> <span class="fw-bold fs-6"> :
                    </span>You
                    have chosen to display your Contact details to all premium members.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-login-footer></app-login-footer>

<!-- modals -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered ">
    <!-- modal-lg -->
    <div class="modal-content rounded-5 border border-1 border-warning">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">{{details.display_name}} <small
            class="p-sm"></small></h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"> <i
            class="fas fa-times fs-4 text-danger"></i> </button>
      </div>

      <div class="modal-body">
        <div class="ratio-1">
          <img src="assets/images/men-avtar-2.svg" *ngIf="!details.profile_photo" class="image-cover rounded-5 border border-1
              border-warning" alt>
          <img src="{{details.profile_photo}}" *ngIf="details.profile_photo" class="image-cover rounded-5 border border-1
              border-warning" alt>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}}. -->
          Upgrade now to view the profile of {{upgradeDisplayName}}.
          <!-- Click Upgrade Now to View Profile! KamalPreet S  -->
        </p>

        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
          routerLink="/upgrade">Upgrade Now </button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="upgradeModal1" #upgradeModalForClose tabindex="-1" aria-labelledby="upgradeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}} -->
          <strong> Upgrade Now to Premium or Elite.</strong>
        </p>
        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close" routerLink="/upgrade"
          (click)="memberSet()">Upgrade Now </button>
        <button
          *ngIf="(freetrial_start_date == '0000-00-00 00:00:00' && freetrial_end_date == '0000-00-00 00:00:00') || (freetrial_start_date == null && freetrial_end_date == null) "
          class="btn btn-outline-danger rounded-pill ms-2" data-bs-dismiss="modal" aria-label="Close"
          (click)="goTofreeTrial()">Check
          Free Trial </button>
        <!-- added null -->
      </div>

    </div>
  </div>
</div>



<div class="modal fade" id="FreeTrialModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body  p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
        <div class="text-center"> <img src="assets/images/logo.png" alt="" width="200" class="mb-6"></div>

        <div class="col-12 pb-4">
          <p class=" fw-bold px-3 mb-0 text-center "> <i class="fas fa-check text-success"></i> Free Trial Benefits </p>
          <ul class>
            <li class="">Free members send connect requests to other members during the Free Trial period.</li>
            <li class="">Free members can compare profiles during the Free Trial period.</li>
          </ul>
        </div>

        <div class="text-center">
          <button class="btn btn-outline-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
            (click)="backToUpgrade()">Back
          </button>

          <button class="btn btn-outline-danger rounded-pill ms-2" data-bs-dismiss="modal" aria-label="Close"
            (click)="upgradeFreeTrial()">Use
            Free Trial </button>

          <button class="btn btn-outline-danger rounded-pill ms-2" data-bs-dismiss="modal" aria-label="Close">Cancel
          </button>
        </div>

      </div>

    </div>
  </div>
</div>



<div class="modal fade" id="FreeTrialModalok" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body  p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <div class="text-center"> <img src="assets/images/logo.png" alt="" width="200" class="mb-6"></div>

        <div class="col-12 pb-4">
          <p class=" fw-bold px-3 mb-0 text-center "> <i class="fas fa-check text-success"></i> {{freeTrailMessage}}
          </p>
        </div>

        <div class="text-center">
          <button class="btn btn-outline-danger rounded-pill ms-2" data-bs-dismiss="modal" aria-label="Close">OK
          </button>
        </div>

      </div>

    </div>
  </div>
</div>

<!--  -->
<!--upgrade modal -->
<div class="modal fade" id="upgradeModal2" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/logo.png" alt="" width="200" class="mb-6">

        <p class="p-sm mb-4">
          <!-- Upgrade Now to start communicating with {{displayName}}. -->
          Your <strong>profile</strong> is currently hidden, <strong>Un-hide </strong> your profile to find a match.
          <!-- Click Upgrade Now to View Profile! KamalPreet S  -->
        </p>

        <button class="btn btn-danger rounded-pill" data-bs-dismiss="modal" aria-label="Close"
          (click)="unhide()">Un-Hide Profile </button>
      </div>

    </div>
  </div>
</div>

<!--  -->

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content rounded-5 border border-1 border-warning">
      <div class="modal-header pb-0 border-0">
        <h5 class="modal-title mb-0 fw-bold" id="exampleModalLabel">{{details.display_name}} <small
            class="p-sm"></small></h5>
        <button type="button" class="btn" data-bs-dismiss="modal" aria-label="Close"> <i
            class="fas fa-times fs-4 text-danger"></i> </button>
      </div>
      <div class="modal-body">
        <div class="ratio-2">
          <img src="{{details.cover_photo}}" class="image-cover rounded-5 border border-1 border-warning" alt>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal -->
<div class="modal fade" id="guidelinesModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content position-relative">
      <button type="button" class="btn-close position-absolute top-0 right-0 m-3 z-index-99" data-bs-dismiss="modal"
        aria-label="Close"></button>

      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-12">
            <h5 class="p-0 fw-bold text-muted"> Photos Guidelines </h5>
            <div class="row my-3">
              <div class="col-12 d-flex align-items-center">
                <div class>
                  <small><i class="fas fa-check text-success"></i> Close Up </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/close-up-view.png" alt>
                  </div>
                </div>

                <div class="mx-3">
                  <small><i class="fas fa-check text-success"></i> Half View </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/half-view.png" alt>
                  </div>
                </div>
                <div class>
                  <small><i class="fas fa-check text-success"></i> Full View </small>
                  <div class="w-85-h-85">
                    <img class="image-cover border border-1 border-danger rounded-3 bg-danger"
                      src="assets/images/full-view.png" alt>
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div class="col-12 pb-4">
            <p class="lh-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-check text-success"></i> Do's </p>
            <ul class>
              <li class="lh-lg p-sm">Your profile photo should be front-facing and your entire photo
                should be visible. </li>
              <li class="lh-lg p-sm">Ensure that your photo is recent and not with a group.</li>
              <li class="lh-lg p-sm">You can upload upto 20 photos to your gallery.</li>
              <li class="lh-lg p-sm">Each photo must be less than 5 MB in size and must be in one of
                the
                following
                formats: jpg, gif, png, bmp .</li>
            </ul>

            <p class="h-lg p-sm fw-bold px-3 mb-0"> <i class="fas fa-times text-danger"></i> Don'ts</p>
            <ul class>
              <li class="lh-lg p-sm"> Watermarked, digitally enhanced, morphed photos or photos with
                your
                personal information will be rejected. </li>
              <li class="lh-lg p-sm"> Irrelevant photographs will lead to deactivation of your profile
                and
                membership. </li>
              <li class="lh-lg p-sm"> The Picture should not be Blurry. </li>
              <li class="lh-lg p-sm"> Do not upload vulgar or nude images. </li>

            </ul>
          </div>
          <div class="col-12 rounded mx-auto py-2 px-3 border border-1 shadow-sm bg-light">
            <p class="p-sm mb-0 text-center"> <span class="fs-5 fw-bold text-danger">*</span> Photos
              will be
              screened, and added to your profile within 5 business days.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" id="CookiesModal" tabindex="-1" aria-labelledby="CookiesModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="fw-600" id="CookiesModalLabel">Cookie Preferences </h5>
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-12 col-md-4">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <button class="btn btn-outline-danger p-sm mb-2 active" id="v-pills-home-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                aria-selected="true">Cookie Details</button>
              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-profile-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
                aria-selected="false">Strictly Necessary Cookies </button>
              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-messages-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages"
                aria-selected="false">
                Functional Cookies </button>
              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-settings-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings"
                aria-selected="false">Analytics and Performance Cookies</button>

              <button class="btn btn-outline-danger p-sm mb-2" id="v-pills-cookiesTabFifth-tab" data-bs-toggle="pill"
                data-bs-target="#v-pills-cookiesTabFifth" type="button" role="tab"
                aria-controls="v-pills-cookiesTabFifth" aria-selected="false"> Targeting Cookies</button>

            </div>
          </div>
          <div class="col-12 col-md-8">

            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                aria-labelledby="v-pills-home-tab">
                <h6 class="fw-600">Cookie Details</h6>
                <p>
                  <strong>Click on the different category headings to find out more and change your cookie
                    preferences.</strong>
                  When you visit our Sites, we store or retrieve information on your browser using cookies and similar
                  technologies, which may be set
                  by us or other companies who provide services to Eternal Strings in connection with our Sites. Cookies
                  and the information they
                  collect might relate to you, your preferences or your device, and are used to make the Sites work as
                  you expect them to, store your
                  preferences, help us understand how people use our Sites, provide a more personalized web experience,
                  and create a profile of your
                  interests in order to show you relevant advertisements or content on other websites with the
                  participation of our advertising
                  partners as described below. For more information about how we use cookies, please see the cookie
                  section of our <a class="text-danger" data-bs-dismiss="modal" href="javascript:void(0)"
                    routerLink="/Privacy">Privacy Policy</a> .
                </p>
                <p>
                  You can choose not to allow certain types of cookies via the settings, but that choice may impact your
                  experience and some features
                  on the Sites. Your choice is specific to the device, website, and browser you are using, and is
                  deleted when you clear your cookies.
                </p>
              </div>

              <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600">Strictly Necessary Cookies </h6>
                  </div>
                  <div class="col-4 text-end">
                    <p class="text-danger fw-500">Always active </p>
                  </div>
                </div>

                <p>
                  Strictly necessary cookies are needed for the website to function and cannot be switched off in our
                  systems. They enable core functions like carrying out network transmissions to help the Sites operate
                  or are set in response to actions made by you, such as setting your privacy preferences, logging in or
                  filling in forms. Strictly necessary cookies cannot be switched off in our systems, but you can
                  disable them by changing your browser settings. Disabling these cookies will affect how the Sites
                  function and may prevent you from using certain features on the Sites.
                </p>

              </div>

              <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600"> Functional Cookies </h6>
                  </div>
                  <div class="col-4 ">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                      <label class="form-check-label" for="flexSwitchCheckDefault"> Inactive </label>
                    </div>
                  </div>
                </div>

                <p>
                  You can opt in to allow functional cookies using this toggle. Functional cookies enable our Sites to
                  provide enhanced functionality and personalization on our Sites - for example, remembering your choice
                  of language or region. Blocking these cookies may impact your experience and some features on the
                  Sites. Your choice is specific to the device, website, and browser you are using, and is deleted when
                  you clear your browser’s cookies.

                </p>

              </div>

              <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600"> Analytics and Performance Cookies </h6>
                  </div>
                  <div class="col-4  ">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2">
                      <label class="form-check-label" for="flexSwitchCheckDefault2"> Inactive </label>
                    </div>
                  </div>
                </div>
                <p>
                  You can opt in to allow performance cookies using this toggle. Analytics and performance cookies allow
                  us to count visits and traffic sources so we can measure and improve the performance of our Sites.
                  They help us to know which pages are the most and least popular and see how visitors move around the
                  Sites. We don't use information collected by analytics and performance cookies for any advertising or
                  profiling purposes. Your choice is specific to the device, website, and browser you are using, and is
                  deleted when you clear your browser’s cookies.

                </p>
              </div>

              <div class="tab-pane fade" id="v-pills-cookiesTabFifth" role="tabpanel"
                aria-labelledby="v-pills-cookiesTabFifth-tab">
                <div class="row">
                  <div class="col-8">
                    <h6 class="fw-600"> Targeting Cookies </h6>
                  </div>
                  <div class="col-4 ">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2">
                      <label class="form-check-label" for="flexSwitchCheckDefault2"> Inactive </label>
                    </div>
                  </div>
                </div>
                <p>
                  You can opt in to allow cookie-based tracking for personalized, targeted advertising through our
                  advertising partners as described
                  below, using this toggle. These cookies may be set through our Sites by our advertising partners. They
                  may be used by those
                  companies to build a profile of your interests and show you relevant advertisements or content on
                  other websites. Blocking these
                  cookies may impact your experience and some features on the Sites. If you do not allow these cookies,
                  you will still see
                  advertisements, but you may receive less relevant advertising or content and some features of our
                  Sites may be impacted. Your choice
                  is specific to the device, website, and browser you are using, and is deleted when you clear your
                  browser’s cookies.

                </p>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-danger">Confirm My Choices </button>
        <button type="button" class="btn btn-danger">Allow All</button>
      </div>
    </div>
  </div>
</div>
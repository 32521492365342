import { Component, OnInit,ViewChild } from '@angular/core';
import { Title/*, Meta*/} from '@angular/platform-browser';
//unused import
// import { LabelType, Options } from '@angular-slider/ngx-slider';
// import { isRestTypeNode } from 'typescript';
import { AuthService } from '../../shared/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartnerPreferenceService } from '../../services/partner-preference.service';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service';
import { NotifService } from '../../services/notif.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';


@Component({
  selector: 'app-inbox-blocked',
  templateUrl: './inbox-blocked.component.html',
  styleUrls: ['./inbox-blocked.component.css']
})
export class InboxBlockedComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  blockedProfileList: any = [];
  FollowUpProfileList: any = [];
  historyData: any = [];
  currentPage1: any;
  lastPage: any;
  pageNo: number = 1;
  displayName: any;
  SkeltonLoader(i: number) {
    return new Array(i);
  }
  inboxLoader:boolean =false;
  user_id: any;
  matches: any = [];
  sort_by: '';
  Filter_by: '';
  selectId: any;
  selectedIds: any = [];
  FilterBy: any = '';
  sendFilterBy: any = '';
  cancelledFilterBy: any = '';
  follow_upFilterBy: any = '';
  blockFilterBy: any = '';
  Filters: string = 'AllRequests';
  sendFilters: string = 'All';
  cancelledFilters: string = 'AllCancelled';
  follow_upFilters: string = 'follow_up';
  blockFilters: string = 'block';
  profileList: any = [];
  sendprofileList: any = [];
  cancelledprofileList: any = [];

  matchedProfilesCount: number = 0;
  shortlistedProfilesCount: number = 0;
  favouriteProfilesCount: number = 0;
  viewedByProfilesCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 8;
  TotalRecord: number = 0;

  Responduserid: any;
  Responddisplayname: any;
  RespondProfilephoto: 'assets/images/banner-7.jpg';

  isRespond: boolean = true;

  blockedList: any = [];

  isInbox: boolean = false;
  isSend: boolean = false;
  isFollowUp: boolean = false;
  isCancelled: boolean = false;
  isBlock: boolean = false;
  isUnblock: boolean = false;
  isInbox_date: any = '27/01/2000';
  isSend_date: any = '28/01/2000';
  isFollowUp_date: any = '29/01/2000';
  isCancelled_date: any = '30/01/2000';
  isBlock_date: any = '31/01/2000';
  isUnblock_date: any = '01/02/2000';
  historyProfileId: any;
  hidePageSize = true;

  inboxCount: number = 0 ;
  sentCount: number = 0 ;
  followUpCount: number = 0 ;
  blockedCount: number = 0 ;
  cancelledCount: number = 0 ;
  encrypted_id: string | undefined;

  acceptedCount: number = 0 ;

  constructor(
    private titleService: Title,

    private authService: AuthService,
    private cookieService: CookieService,
    private scroller: ViewportScroller,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private NotifService: NotifService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastrService: ToastrService,
    private CommonService:CommonService,
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('Blocked | eternalstrings.com');
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.getCountOfRequest();
    this.blocked_List(); 
  }

  getCountOfRequest() {
     let data = {
      user_id: this.user_id,
    };
    this.myProfileService.getCountOfRequest(data).subscribe((result) => {
      if (result.status) {
        this.inboxCount=result.inboxCount;
        this.sentCount=result.sentCount;
        this.followUpCount=result.followUpCount;
        this.cancelledCount=result.cancelledCount;
        this.blockedCount=result.blockedCount;
        this.acceptedCount=result.acceptedCount;
      } else {
        this.toastrService.error(result.message);
      }
      this.spinner.hide();
    }, (error) => {                              //Error callback
          this.spinner.hide();
        });
  }

  public handlePage(e: any) {
    this.pageNo = e.pageIndex+1;
    this.currentPage = e.pageIndex+1;
    this.pageSize = e.pageSize;
    this.getBlockedRequest();
  }
  getBlockedRequest() {
    this.inboxLoader=true;
    let data = {
      user_id: this.user_id,
      filter: this.FilterBy,
      itemPerPage:8,
      pageNo :this.pageNo,
    };
      this.myProfileService.getBlockedRequest(data).subscribe((result) => {
      if (result.status) {
        this.blockedList = result.pages.data;
        this.currentPage = result.pages.current_page;
        this.TotalRecord = result.pages.total;
        this.currentPage1 = result.pages.current_page;
        this.lastPage = result.pages.last_page;
      } else {
        this.toastrService.error(result.message);
      }
      this.inboxLoader=false;
    },(error) => {                              //Error callback
        this.spinner.hide();
        this.toastrService.error('error caught in component!');
        this.inboxLoader=false;
      });
}

  doActionOnRequestsBlocked(action: any, profile_id: any) {
    let pload = {
      user_id: this.user_id,
      profile_id: profile_id,
      action: action,
    };

    this.myProfileService.doActionOnRequests(pload).subscribe(
      (result) => {
        if (result.status) {
          this.toastrService.success(result.message);
        if(!this.paginator.hasNextPage() && this.TotalRecord % this.paginator.pageSize == 1) {
            this.paginator.previousPage();
          }
          this.inboxLoader=false;
          this.getBlockedRequest();
          this.getCountOfRequest();

        } else {
          this.toastrService.error(result.message);
          this.inboxLoader=false;
        }
      },
      (error) => {
          this.inboxLoader=false;
          this.toastrService.error('Some thing went wrong!');
      }
    );
  }

  getHistoryData(profile_id: any ,connection_id:any) {
     let pload = {
        user_id: this.user_id,
        profile_id: profile_id,
        connection_id: connection_id,
      };
      this.myProfileService.getConnectionProfileHistory(pload).subscribe((result) => {
       if (result.status) {
         this.historyData = result.connectionHistory
          this.inboxLoader=false;
       }
     },(error) => {
      this.inboxLoader=false;
      this.toastrService.error('Some thing went wrong!!');
      })
    
  }

blockFiltersBy(event, blockFilters: any = 'block') {
    if (this.FilterBy != event.target.id) {
      this.FilterBy = event.target.id;
      this.blockFilters = blockFilters;

      if(this.pageNo != 1){
        this.paginator.firstPage();
        this.pageNo = 1;
      }
      this.inboxLoader=false;
      this.getBlockedRequest();
    }
  }

  blocked_List() {
    this.inboxLoader=false;
    this.getBlockedRequest();
  }
  

  Upgrade(userid, user_id){
    // unused code
    //this.router.navigate(['upgread']);
    // console.log(userid,'from upgrade useris');
    // console.log(user_id,'from upgrade user_id');
  }

  ViewProfile(id,Name){
    localStorage.setItem("pageFrom", "blocked");
   let pid:any = localStorage.getItem('package_id') ;
    if(pid>0){
      let encrypted_id:any = this.CommonService.encryptData(id)
;
        encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
        let url =`${environment.baseURL}`+'detail/'+encrypted_id
        window.open(url, '_blank');
    }else{
      this.displayName = Name ;
          $('#openModal1').click();
    }
  }

}

<div class="w-100 bg-maroon" *ngIf="!verified">
  <app-header></app-header>
</div>
<div class="page-header1">
  <div class="container">
    <div class="row">
      <div class="col-12 ">
        <div class="page-caption1">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content py-3 vh">
  <div class="container mt-3">

    <div class="row">
      <div class="col-md-9 col-12 pageScroll scrollbar">
        <div class="row card position-sticky top-70 z-index-10" *ngIf="blogDetailsArr?.length== 0 && !bloglistLoader ">
          <div class="col-12 py-3">
            <a href="javascript:void(0);" (click)="searchBack()">
              <div class="btn btn-danger w-35-h-35 position-absolute center"><i class="fas fa-arrow-left"></i>
              </div>
            </a>
            <h1 class="mb-0 text-center text-danger fw-bold">No Blogs Available</h1>
          </div>
        </div>
       
        <div class="row px-2 row-cols-1 row-cols-sm-2 row-cols-lg-3"
          *ngIf="blogDetailsArr?.length> 0 && !bloglistLoader ">
          <div class="col p-1" *ngFor="let blogdetails of blogDetailsArr">
            <div class="card mycard  h-100 border border-1 rouned-0 shadow-sm">
              <div class="card-deck-img h-30 position-relative overflow-hidden rounded">
                <span *ngIf="blogdetails.blog_media && blogdetails.blog_media[0] &&
                                    blogdetails.blog_media[0].media_mime_type.includes('image/')"
                  (click)="viewBlog(blogdetails.blog_title,blogdetails.blog_title_url)">
                  <ng-container
                    *ngIf="blogdetails.blog_media[0].thumb_media_path && blogdetails.blog_media[0].thumb_media_path != ''">
                    <img src="{{blogdetails.blog_media[0].thumb_media_path}}" class="img-fix w-100 h-30 card-img-top"
                      alt="Blog" title="Blog Image"/>
                  </ng-container>
                  <ng-container
                    *ngIf="!blogdetails.blog_media[0]?.thumb_media_path || blogdetails.blog_media[0].thumb_media_path != ''">
                    <img src="{{blogdetails.blog_media[0].media_path}}" class="img-fix w-100 h-30 card-img-top"
                      alt="Blog" title="Blog Image"/>
                  </ng-container>
                </span>
                <span (click)="viewBlog(blogdetails.blog_title,blogdetails.blog_title_url)">
                  <img src="assets/images/blog/blog_default_card.png"
                    *ngIf="blogdetails.blog_media[0].media_path=='' || blogdetails.blog_media[0].media_path== null "
                    class="rounded-sm image-contain cursor-pointer" alt="Blog" title="Blog Image"/>
                </span>
              </div>
              <div class="card-body p-0">
                <div class="row mx-0 p-2 bg-gray border-bottom">
                  <div class="col-7 px-0 text-truncate">
                    <a [title]="blogdetails?.category_name" href="javascript:void(0)"
                      (click)="viewBlog(blogdetails.blog_title,blogdetails.blog_title_url)" class="text-danger fw-bold">
                      {{
                      blogdetails.category_name }} </a>
                  </div>
                  <div class="col-5 px-0 text-end">
                    <span *ngIf="blogdetails.published_at!='0000-00-00 00:00:00'">
                      <small class="text-muted" title="Published on">
                        <!-- Posted on {{blogdetails.published_at }} -->
                        {{blogdetails?.msgSent | date:'dd/MM/yyyy' }}
                      </small>
                      <!-- | gMTDate -->
                    </span>

                    <span *ngIf="blogdetails.published_at=='0000-00-00 00:00:00'">
                      <small class="text-muted" title="Published on">
                        {{blogdetails.published_at }}
                      </small>
                    </span>
                  </div>
                </div>
                <div class="p-2">
                  <h5 class="card-title fs-17 truncate-2">
                    <a href="javascript:void(0)" (click)="viewBlog(blogdetails.blog_title,blogdetails.blog_title_url)"
                      class="text-danger fw-bold">{{blogdetails.blog_title}}</a>
                  </h5>
                  <p *ngIf="blogdetails?.author" class="text-end mb-1"> Author :
                    <span class="text-muted">{{blogdetails?.author | limit :25}}</span></p>

                  <p class="card-text truncate-4">{{blogdetails.blog_content | limit : 150}} </p>
                </div>
              </div>

              <div class="card-footer px-2 d-flex align-items-center justify-content-end">
                <div class="text-end">
                  <a href="javascript:void(0)" class="btn btn-sm btn-danger" placement="bottom" Favorite="View
                                        Detail"
                    (click)="viewBlog(blogdetails.blog_title,blogdetails.blog_title_url)">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row px-2 row-cols-1 row-cols-sm-2 row-cols-lg-3" *ngIf="bloglistLoader">
          <div *ngFor="let number of [0,1,2,3,4,5]" class="col p-1">
            <div class="card h-100 border border-1 rouned-0 shadow-sm">
              <div class="card-deck-img position-relative overflow-hidden">
                <img class="rounded-sm image-cover hover-scale animated-background-name" alt=""
                  style="height: 180px;" />
              </div>
              <div class="card-body p-0">
                <div class="row mx-0 p-2 bg-gray border-bottom">
                  <div class="col-8 px-0 text-truncate">
                    <a href="javascript:void(0)" class="text-danger fw-bold blank-box animated-background-name"
                      style="width :70%"><span></span>
                    </a>
                  </div>
                  <div class="col-4 px-0 text-end">
                    <small class="text-mute blank-box animated-background-name" style=" width :60%">
                    </small>
                  </div>
                  <div class="col-8 px-0 text-end">
                    <small class="text-mute blank-box animated-background-name mt-3" style="width :70%">
                    </small>
                  </div>
                  <div class="col-4 px-0 text-end">
                    <small class="text-mute blank-box animated-background-name mt-3 " style=" width :60%">
                    </small>
                  </div>
                </div>
                <div class="p-2">
                  <h5 class="card-title truncate-2">
                    <a href="javascript:void(0)" class="text-danger fw-bold blank-box animated-background-name"
                      style=" width :86%"><span style="display: none;">animation</span></a>
                  </h5>
                  <h5 class="card-title truncate-2">
                    <a href="javascript:void(0)" class="text-danger fw-bold blank-box animated-background-name"
                      style=" width :59%"><span style="display: none;">animation</span></a>
                  </h5>

                  <h5 class="card-title truncate-2">
                    <a href="javascript:void(0)" class="text-danger fw-bold blank-box animated-background-name"
                      style=" width :49%"><span style="display: none;">animation</span></a>
                  </h5>

                  <p class="card-text truncate-4 blank-box animated-background-name mt-3" style="width :29%"> </p>
                </div>
              </div>
              <div class="card-footer px-2 d-flex align-items-center justify-content-end">
                <div class="text-end">
                  <a href="javascript:void(0)" class="btn btn-sm  blank-box animated-background-name" placement="bottom"
                    Favorite="View Detail" style="height: 30px;width: 50px;"><span
                      style="display: none;">animation</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mx-0 rounded-3 justify-content-end" *ngIf="TotalRecordCount> 9 && !bloglistLoader ">
          <div class="col-md-3 px-0 col-10 justify-content-center">
            <mat-paginator #paginator [pageSizeOptions]="[8, 16, 50, 100]" [length]="TotalRecord" showFirstLastButtons
              aria-label="Select page of periodic elements" (page)="handlePage($event)" [pageSize]="pageSize"
              [hidePageSize]="hidePageSize">
            </mat-paginator>
          </div>
          <div class="col-md-2 col-2 text-center bg-white d-flex align-items-center justify-content-center">
            <p class="mat-paginator-range mb-0">Page {{currentPage}} of {{lastPage}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 position-relative pt-1 pageScroll scrollbar">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item widget">
            <button class="accordion-button no-cursor-pointer" type="button">
              Search ({{searchCount}})
            </button>
            <div style="display: block !important;" id="collapseOne" class="accordion-collapse collapse"
              aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="input-group mb-3 ">
                  <input style="height:34px;" type="text"
                    class="w-100 rounded-3 border-solid-1 ps-3 pe-5 position-relative ng-touched"
                    placeholder="Search here" aria-label="Search Here" [(ngModel)]="search"
                    (keyup)="handleKeyboardEvent($event)" aria-describedby="button-addon2">
                  <div class="input-group-append position-absolute top-50 start-100 translate-middle">
                    <span *ngIf="search == ''"> <i class="fas fa-search searchButton" (click)="Search()"></i></span>
                    <button *ngIf="search != ''" (click)="clearSearch()"
                      class="searchButton btn btn-small p-0 fw-semibold" type="button" id="button-addon2">X</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item widget widget-category">
            <button class="accordion-button collapsed" id="openModal12" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Categories
            </button>
            <button style="display:none;" data-bs-toggle="collapse" data-bs-target="#collapseTwo" ></button>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class>
                  <li class="slide-10" [ngClass]="category_id == 0 ? 'active' : ''"><a href="javascript:void(0);"
                      (click)="filterByCategory(0,'all')"><i class="fa fa-arrow-right" aria-hidden="true"></i>
                      All categories <strong class='ml-1'>({{totalBlogs}})</strong></a>
                  </li>
                  <ng-container *ngFor="let category of categoryList">
                    <li class="slide-10" [ngClass]="category_id == category.id ? 'active' : ''"><a
                        href="javascript:void(0);" (click)="filterByCategory(category.id,category.category_name)"><i
                          class="fa fa-arrow-right" aria-hidden="true"></i>
                        {{category.category_name}} <strong *ngIf="category.category_count> 0"
                          class='ml-1'>({{category.category_count}})</strong></a></li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item widget widget-archives">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Popular Blogs
            </button>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class="mb-0">
                  <li class="slide-10" *ngFor="let mostReadBlogs of popularBlogs"><a href="javascript:void(0);"
                      (click)="popularBlogsRedirect(mostReadBlogs)"><i class="fa fa-arrow-right" aria-hidden="true"></i>
                      {{mostReadBlogs.blog_title}}</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item widget-tags">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#headingFour" aria-expanded="false" aria-controls="headingFour">
              Tags
            </button>
            <div id="headingFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <a *ngFor="let tag of tagsList; index as i" class="rounded cursor-pointer" [id]="tag"
                  (click)="Tags($event)" [ngClass]="(tagname==tag)?'active1': ''">{{tag}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotifService } from '../services/notif.service';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { MyProfileService } from '../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../services/register.service';
import { CommonService } from '../services/common.service';
import * as $ from 'jquery';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})

export class BlogComponent implements OnInit {

  readonly notifyOptions = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // clickIconToClose: true,
    preventDuplicates: true
  };
  txt: string = '';
  options: any;
  verified: boolean = false;
  user_id: number;
  private subscription;
  blogDetailsArr: any = [];
  blogCount: number;
  showMoreUserList: any;
  nextPage: any;
  categoryList: any = [];
  blog_category_name: any;
  category_id: any = 0;
  activeTab: any;
  TotalRecord: number = 0;
  TotalRecordCount: number = 0;
  currentPage: number = 1;
  pageSize: number = 9;
  showPagination: boolean;
  hidePageSize = true;
  search: string = '';
  tag: string = '';

  lastPage: number;
  itemPerPage: number = 9;

  bloglistLoader: boolean = false;
  categoriesLoader: boolean = false;
  totalBlogs: number = 0;
  searchCount: any = 0;

  // tagList = [
  //   "Commitment",
  //   "Compatibility",
  //   "Connect",
  //   "Couple",
  //   "Family",
  //   "Festival",
  //   "Find love",
  //   "Find your match",
  //   "First date",
  //   "Happily Married",
  //   "Help",
  //   "Indian",
  //   "Inspiring story",
  //   "Interact",
  //   "Life partner",
  //   "Love",
  //   "Made for each other",
  //   "Manage matchmaking",
  //   "Marriage",
  //   "Match",
  //   "Match-making",
  //   "Matchmaking",
  //   "Matrimonial",
  //   "Online dating",
  //   "Perfect match",
  //   "Perfect partner",
  //   "Potential match",
  //   "Relationship",
  //   "Relationship advice",
  //   "Research",
  //   "Right match",
  //   "Single",
  //   "Singles event",
  //   "Social",
  //   "Soulmate success story",
  //   "Survey",
  //   "Tips",
  //   "Traditional",
  //   "Trust",
  //   "Wedding",
  //   "Women",
  //   "Wonderful"
  // ];


  tagname: string = '';
  popularBlogs: any = [];
  tagsList: string[];


  //  pageSize: number = 8;
  constructor(

    private notifService: NotifService,
    private cookieService: CookieService,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    private myProfileService: MyProfileService,
    private toastrService: ToastrService,
    private registerService: RegisterService,
    private commonService: CommonService,
    private route: ActivatedRoute,

  ) {
    this.showNotification();
    this.options = this.notifService.getOptions();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    $('#openModal12').click();

    this.titleService.setTitle('Blog | eternalstrings.com');
    this.meta.addTag({ name: 'robots', content: 'all' });
    this.user_id = parseInt(this.cookieService.get('user_id'));
    if (this.user_id) {
      this.verified = true;
    }

    const routSubscription = this.router.events.subscribe((val) => {
      this.subscription.unsubscribe();
      routSubscription.unsubscribe();
    });


    this.route.queryParams.subscribe(params => {
      if (params['sort_by']) {
        this.category_id = decodeURIComponent(params['sort_by']);
        this.activeTab = this.category_id;
      }

      if (params['sort_byTag']) {
        this.tag = decodeURIComponent(params['sort_byTag']);
        this.tagname = this.tag;
      }
    });
    this.getBlogsList();
    this.getBlogCategories();
    this.getMostReadBlogs();
    this.getBlogTags();

  }

  getUser() {
    let data = {
      user_id: this.user_id,
    };
    this.registerService.getUserDetails(data).subscribe((user_details: any) => {
      let details = user_details.userDetails;
      let register_id = details.register_done;
      localStorage.setItem('register_id', register_id);
    });
  }

  getBlogCategories() {
    this.categoriesLoader = true;
    this.myProfileService.blogCategories().subscribe(
      res => {
        this.categoryList = res["category_list"];
        this.categoriesLoader = false;
      }, (err) => {
        this.categoriesLoader = false;
      }
    );
  }

  getBlogsList() {
    this.bloglistLoader = true;
     let data = {
      itemPerPage: this.itemPerPage,
      page: this.currentPage,
      search: this.search,
      tag: this.tag,

      platform_id: 1, //this.platform_id,
      category_id: this.category_id,
    };
    this.blogCount = 0;
    this.myProfileService.getBlogsList(data).subscribe((res: any) => {
      if (res?.['status_code'] && res['status_code'] == 200) {

         // before sonar
        // for (let i = 0; i < res['blogs_list'].length; i++) {
        //   if (res && res['blogs_list'] && res['blogs_list'][i] && res['blogs_list'][i]['blog_content']) {
        //     var blog_content = this.removeTags(res['blogs_list'][i]['blog_content']);
        //     res['blogs_list'][i]['blog_content'] = blog_content.substring(0, 250);
        //   }
        // }

        for (const element of res['blogs_list']) {
          if (res?.['blogs_list'] && element?.['blog_content']) {
            let blog_content = this.removeTags(element['blog_content']);
            element['blog_content'] = blog_content.substring(0, 250);
          }
        }

        if (res?.['showMore']) {
          this.showMoreUserList = res['showMore'];
        }
        this.blogDetailsArr = res['blogs_list'];

        // for convert in local time
        this.blogDetailsArr.forEach(element => {
          if (element.published_at != "" && element.published_at != null) {
            let convertedDate = this.convertDateFormat(element.published_at);
            const outputDate = this.addMinutesToDateString(convertedDate);
            element['msgSent'] = outputDate;
          }
        });
    
        this.TotalRecord = res['totalRecord'];
        this.TotalRecordCount = res['totalRecord'];
        this.lastPage = Math.ceil(this.TotalRecord / this.itemPerPage);
        // this.category_id == 0 &&
        // if( this.search == '' && this.tag == '' ) {
        //   this.totalBlogs = this.TotalRecord;

        // }

        this.totalBlogs = res['total'];

        if (this.search != '') {
          this.searchCount = this.TotalRecord;
        } else {
          this.searchCount = 0;
        }

      } else {
        this.toastrService.error(res['message'], 'Error!');
      }
      this.bloglistLoader = false;
    }, (err) => {
      if (err?.['message']) {
        this.bloglistLoader = false;
      }
    });
  }

  somefunction() {
    this.subscription.unsubscribe()
  }

  Search() {
    this.tagname = '';
    this.tag = '';
    this.category_id = 0;

    this.getBlogsList();
    this.bloglistLoader = false;
    window.scroll(0, 0);
  }

  onKeyDown(event: KeyboardEvent) {
    let keypressed = event?.key;
    if (keypressed === 'Enter'){
      // Enter key was pressed
      this.Search();
      // Do something...
    }
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getBlogsList()
    this.bloglistLoader = false;
    window.scroll(0, 0);
  }

  Tags(event) {
  
    this.tagname = event.target.id;
    this.tag = event.target.id;

    this.category_id = 0;
    this.search = '';
    this.getBlogsList();
   
    window.scroll(0, 0);
  }

  showNotification() {
    this.subscription = interval(60000).subscribe(x => {
      if (this.cookieService.get('oldUser')) {
        // document why this block is empty
      } else {
        this.notifService.action(
          '<a href=' + environment.registerDone + ' class="text-light fs-5 pt-2" style="text-decoration:none;">Sign Up for free</a><button class="btn btn-sm btn-close position-absolute top-0 right-0 m-2"></button>',
          `Start finding your life partner`,
          'btn',
          () => this.txt = `${this.showNotification()}`
        );
        this.somefunction();
      }
    });
  }

  notifAction1() {
    this.notifService.action(
      'Demo notif with button',
      `Click button below: <button class="btn btn-primary">Do it!</button>`,
      'btn',
      () => this.txt = `${this.subscription.subscribe()}`
    );
  }

  notifDestroyed(notif: any) {
    this.notifService.notifDestroyed(notif['id']);
  }

  // before sonar
  // removeTags(str) {
  //   if ((str === null) || (str === ''))
  //     return false;
  //   else
  //     str = str.toString();
  //   return str.replace(/(<([^>]+)>)/ig, '');
  // }

   // after sonar
   removeTags(str: string): string {
    if (str === null || str === '') {
      return '';
    }
  
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
  
    return doc.body.textContent || '';
  }

  viewBlog(title,blog_title_url) {
    if(blog_title_url!=''){
      let url = `${environment.baseURL}` + 'blog/' + blog_title_url;
      window.open(url);
    }else{
      let outputString = this.convertString(title);
      let url = `${environment.baseURL}` + 'blog/' + outputString;
      window.open(url);
    }

  }



  convertString(str) {
    let result = str.replace(/[^a-zA-Z0-9 ]/g, '');
    result = result.replace(/ /g, '-');
    return result;
}







  filterByCategory(categoryId, categoryName) {
    this.category_id = categoryId;
    this.activeTab = categoryId;

    this.search = "";
    this.tag = '';
    this.tagname = '';
    this.getBlogsList();
    window.scroll(0, 0);
  }

  convertDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000000Z`;
  }

  convertUTCToLocal(utcTimestamp) {
    const date = new Date(utcTimestamp);
    const localDateString = date.toLocaleString();
    return localDateString;
  }

  addMinutesToDateString(dateString: string): string {
    const date = new Date(dateString);
    let newMin = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - newMin);
    return date.toISOString();
  }

  searchBack() {
    this.search = '';
    this.category_id = 0;
    this.tag = '';
    this.tagname = '';
    this.getBlogsList();
    window.scroll(0, 0);
  }

  clearSearch() {
    this.currentPage = 1;
    this.search = "";
    this.tag = '';
    this.tagname = '';
    this.blogDetailsArr = [];
    this.getBlogsList();
  }

  // before sonar
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   var keypressed = event.keyCode;
  //   if (keypressed == 13) {
  //     this.currentPage = 1;
  //     this.tag = '';
  //     this.category_id = 0;
  //     this.tagname = '';
  //     this.getBlogsList();
  //   } else if (keypressed == 8 && this.search == '') {
  //     this.currentPage = 1;
  //     this.getBlogsList();
  //   }
  // }

    // after sonar
    handleKeyboardEvent(event: KeyboardEvent) {
      let keypressed = event?.key;
      if (keypressed === 'Enter') {
        this.tag = '';
        this.category_id = 0;
        this.tagname = '';
        this.currentPage = 1;
        this.getBlogsList();
      } else if (keypressed === 'Backspace' && this.search === '') {
        this.currentPage = 1
        this.getBlogsList();
      }
    }

  getMostReadBlogs() {
    let data =
    {
      platform_id: 1
    }
    this.myProfileService.getMostReadBlogs(data).subscribe(
      res => {
        this.popularBlogs = res["mostReadBlogs"];
      }, (err) => {
      }
    );
  }

  popularBlogsRedirect(blog) {
    this.viewBlog(blog.id,blog.blog_title_url);
  }

  getBlogTags() {
    this.myProfileService.getBlogTags().subscribe(
      res => {
        const tags: string[] = this.extractTags(res["tags"]);
        this.tagsList = tags;
      }, (err) => {
      }
    );
  }


  extractTags(data: { tag: string }[]): string[] {
    return data.map(item => item.tag.replace(/'/g, '')).filter(tag => !!tag);
  }

}

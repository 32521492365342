import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { ServicesComponent } from './services/services.component';
import { RegisterComponent } from './register/register.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { RegisterNxtComponent } from './register-nxt/register-nxt.component';
import { RegisterDoneComponent } from './register-done/register-done.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { StepComponent } from './step/step.component';
import { SteptwoComponent } from './steptwo/steptwo.component';
import { StepDoneComponent } from './step-done/step-done.component';
import { LoginhomeComponent } from './loginhome/loginhome.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { LoginHeaderComponent } from './shared/login-header/login-header.component';
import { LoginFooterComponent } from './shared/login-footer/login-footer.component';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { DisclaimerComponent } from './Policies/disclaimer/disclaimer.component';
import { TermsComponent } from './Policies/terms/terms.component';
import { PrivacyComponent } from './Policies/privacy/privacy.component';
import { SearchComponent } from './search/search.component';
import { PaymentComponent } from './Policies/payment/payment.component';
import { EditComponent } from './edit/edit.component';
import { MatchesComponent } from './matches/matches.component';
import { DetailComponent } from './detail/detail.component';
import { SettingComponent } from './setting/setting.component';
import { InboxComponent } from './inbox/inbox.component';
import { InboxSentComponent } from './communications/inbox-sent/inbox-sent.component';
import { InboxFollowUpComponent } from './communications/inbox-follow-up/inbox-follow-up.component';
import { InboxCancelledComponent } from './communications/inbox-cancelled/inbox-cancelled.component';

import { InboxBlockedComponent } from './communications/inbox-blocked/inbox-blocked.component';
import { CommunicationInboxComponent } from './communications/communication-inbox/communication-inbox.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from './chat/chat.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
// import { AdminModule } from './admin/admin.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UpgreadComponent } from './upgread/upgread.component';
import { ProfilePage1Component } from './profile-page1/profile-page1.component';
import { PartnerPreferencesComponent } from './partner-preferences/partner-preferences.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SaftyTipsComponent } from './safty-tips/safty-tips.component';
import { CompareComponent } from './compare/compare.component';
import { CookiesComponent } from './cookies/cookies.component';
import { AuthInterceptor } from './shared/auth.interceptor';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CookieService } from 'ngx-cookie-service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReplaceSpaceWithUnderscorePipe } from './pipes/ReplaceSpaceWithUnderscorePipe';
import { ShortNamePipe } from './pipes/shortName.pipe';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { WeightConverterPipe } from './pipes/WeightConvertorPipe';
import { HeightConverterPipe } from './pipes/HeightConvertorPipe';
import { LoginHomeComponent } from './login-home/login-home.component';
import { ReplaceSpaceWithComaPipe } from './pipes/replaceSpaceWithComaPipe';
import { ReplaceUnderscoreWithComaPipe } from './pipes/replaceUnderscoreWithComa';
import { ReplaceComaWithSpacePipe } from './pipes/ReplaceComaWithSpacePipe';
import { NotificationComponent } from './notification/notification.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MyinfoComponent } from './myinfo/myinfo.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { MatchesViewComponent } from './matches-view/matches-view.component';
import { MatchesShortlistComponent } from './matches-shortlist/matches-shortlist.component';
import { MatchesFavouriteComponent } from './matches-favourite/matches-favourite.component';
import { HeightTopPipe } from './pipes/HeightTopPipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { MatPaginatorModule } from '@angular/material/paginator';

import { AgeConvertPipePipe } from './pipes/age-convert-pipe.pipe';
import { LimitPipe } from './pipes/limit.pipe';
import { HttperrorInterceptorService } from './services/httperror-interceptor.service';
// import { HttperrorInterceptorService } from './services/httperror-interceptor.service';
import { AES } from 'crypto-ts';
import { AcceptedComponent } from './communications/accepted/accepted.component';
// import { BlogsComponent } from './blogs/blogs.component';
// import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { ProgressiveImageModule } from './Directives/progressive-image/progressive-image.module';
//import { AgeConvertPipePipe } from './age-convert-pipe.pipe';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PackagesComponent } from './packages/packages.component';
// import { ImageCropperComponent } from './Directives/Image-cropper/component/image-cropper.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
// import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { ReplaceEmojisPipe } from './pipes/emoji.pipe';
import { SafeHtmlPipePipe } from './pipes/safeHtmlPipe.pipe';
import { LimitHtmlPipe } from './pipes/limitHtml.pipe';
import { SafeHtmlPipe } from 'ngx-spinner/lib/safe-html.pipe';
// import { NgxGeoIpModule } from 'ngx-geoip';
import { UtcConverterService } from './services/utc-converter.service';
import { UtcToLocalTimePipe } from './pipes/dateTime.pipe';
import { DatePipe } from '@angular/common';
import { TimezonePipe } from './pipes/timezone.pipe';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SocketService } from './services/socket.service';
import { VideoService } from './services/video.service';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { SearchForLookingComponent } from './search-for-looking/search-for-looking.component';
import { BiodataComponent } from './biodata/biodata.component';
import { RenewalComponent } from './renewal/renewal.component';
// import { BeforeunloadModule, BEFORE_UNLOAD_MESSAGE, BEFORE_UNLOAD_FN } from './beforeunload';
import { BeforeunloadModule } from './beforeunload/beforeunload.moule';
import { SuggestedMatchesComponent } from './suggested-matches/suggested-matches.component';
import { GMTDatePipe } from './pipes/gmtdate.pipe';
import { SuccessStoryComponent } from './shared/success-story/success-story.component';
import { HoneymoonDestinationsComponent } from './shared/honeymoon-destinations/honeymoon-destinations.component';
import { ProfileCardComponent } from './shared/profile-card/profile-card.component';
import { AddSpaceAfterCommaPipe } from './pipes/add-space-after-comma.pipe';
import { BlogCategoryComponent } from './blog-category/blog-category.component';



const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled"
  //scrollPositionRestoration: "enabled"
};
// const config: SocketIoConfig = { url: '', options: {} };
const routes: Routes = [
  {
    path: "",
    component: AppComponent
  },
  // { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BlogComponent,
    BlogDetailComponent,
    BlogCategoryComponent,
    ContactComponent,
    AboutComponent,
    FaqComponent,
    ServicesComponent,
    RegisterComponent,
    LoginComponent,
    RegisterNxtComponent,
    RegisterDoneComponent,
    HeaderComponent,
    FooterComponent,
    StepComponent,
    SteptwoComponent,
    StepDoneComponent,
    LoginhomeComponent,
    ProfilePageComponent,
    MyinfoComponent,
    MyprofileComponent,
    LoginHeaderComponent,
    LoginFooterComponent,
    DisclaimerComponent,
    TermsComponent,
    PrivacyComponent,
    SearchComponent,
    PaymentComponent,
    EditComponent,
    MatchesComponent,
    DetailComponent,
    SettingComponent,
    InboxComponent,
    InboxSentComponent,
    InboxFollowUpComponent,
    InboxCancelledComponent,
    InboxBlockedComponent,
    ChatComponent,
    UpgreadComponent,
    ProfilePage1Component,
    PartnerPreferencesComponent,
    SaftyTipsComponent,
    CompareComponent,
    CookiesComponent,
    ResetPasswordComponent,
    ReplaceSpaceWithUnderscorePipe,
    ShortNamePipe,
    ReplaceSpaceWithComaPipe,
    ReplaceUnderscoreWithComaPipe,
    ReplaceComaWithSpacePipe,
    ChangePasswordComponent,
    WeightConverterPipe,
    HeightConverterPipe,
    HeightTopPipe,
    LoginHomeComponent,
    NotificationComponent,
    ProfilePreviewComponent,
    MyDashboardComponent,
    MyinfoComponent,
    PhotoGalleryComponent,
    MatchesViewComponent,
    MatchesShortlistComponent,
    MatchesFavouriteComponent,
    AgeConvertPipePipe,
    LimitPipe,
    ReplaceEmojisPipe,
    CommunicationInboxComponent,
    AcceptedComponent,
    // BlogsComponent,
    // BlogDetailsComponent,
    PackagesComponent,
    SafeHtmlPipePipe,
    LimitHtmlPipe,
    UtcToLocalTimePipe,
    TimezonePipe,
    PhoneNumberPipe,
    SearchForLookingComponent,
    BiodataComponent,
    RenewalComponent,
    SuggestedMatchesComponent,
    GMTDatePipe,
    SuccessStoryComponent,
    HoneymoonDestinationsComponent,
    ProfileCardComponent,
    AddSpaceAfterCommaPipe,
    // ComponentComponent,  
  ],
  imports: [
    BeforeunloadModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    NgbPaginationModule,
    NgbAlertModule,
    AngularMultiSelectModule,
    // AdminModule,
    // AdminModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatSliderModule,
    NgxSliderModule,
    ImageCropperModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    ProgressiveImageModule,
    ContentLoaderModule,
    MatDatepickerModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // SocketIoModule.forRoot(config),
    RouterModule.forRoot(routes, routerOptions),
    NgCircleProgressModule.forRoot({
      // radius: 100,
      // outerStrokeWidth: 16,
      // innerStrokeWidth: 8,
      // outerStrokeColor: "#b51c36",
      // innerStrokeColor: "#ffcd04",
      // animationDuration: 1000,
    }),
    ToastrModule.forRoot({
      timeOut: 5000, // 5 seconds
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-center-center',
      messageClass: 'font-size-large' // Add your custom CSS class here
    }),
    CarouselModule,
    NgSelectModule,
    PickerModule,
    SimpleNotificationsModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      // interceptor to show loading spinner
      provide: HTTP_INTERCEPTORS,
      useClass: HttperrorInterceptorService,
      multi: true,
    },
    CookieService,
    UtcConverterService,
    DatePipe,
    SocketService,
    VideoService
  ]
})
export class AppModule {
  constructor() {
    // console.log('app module loaded')
  }
}

@NgModule({
  imports: [NgbPaginationModule,
    NgbAlertModule,


  ],
})
export class YourAppModule { }


import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title,Meta} from '@angular/platform-browser';
import { interval } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
// unused import
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { environment } from 'src/environments/environment';
import { NotifService } from '../services/notif.service';
import { CommonService } from '../services/common.service';
import { TokenService } from '../shared/token.service';
import * as $ from 'jquery';
import { AuthService } from '../shared/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { browserRefresh } from 'src/app/app.component';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('customHtml') customHtml: TemplateRef<any>;
  @ViewChild('myVideo') myVideo: ElementRef;
  // carouselItems: string[] = ['Slide 1', 'Slide 2', 'Slide 3', 'Slide 4']; // Add your slide content here
  carouselItems: any = [
    {
      title: 'Hethin & Kamal',
      location: 'Vancouver, Canada',
      image: 'assets/images/success-wedding/success-wedding-1.JPG',
      story: "A huge thank you to our matchmaker Gail Bains for introducing me to my soul mate. She set us up on a blind date and we instantly connected. We had so much in common and just felt so comfortable being around each other. We dated for awhile and both came to the realization that we couldn't live without one another, so we ended up getting married. Without Gail's matchmaking skills and intuition we wouldn't be where we are today, in a strong and loving relationship. Our foundation for the rest of our lives has been laid and it’s all thanks to Gail. Thank you again for changing our lives and helping us find happiness, true love and joy."
    },
    {
      title: 'Mariah & Karnvir',
      location: 'Surrey, Canada',
      image: 'assets/images/success-wedding/success-wedding-2.JPG',
      story: "Gail Bains is very kind and caring, and also direct. She calls things out, which I appreciate, and we talk through real-life situations, applying the principals I am learning. I trust that I can share anything with Gail, including insecurities I might have. She is supportive, nonjudgmental, and encouraging. Gail Bains approach is very different than what I expected. She helped me understand and realize the importance of what it was that I was looking for in a life partner. I highly recommend working with Gail as she was able to help find my soul mate. I truly can’t thank you enough. Mariah <br><br> Gail, thank you so much for making me believe again that the love of my life is somewhere out there. I honestly at some points felt hopeless until I met and worked with you. She made me believe in connection, love, partnership and companionship again. By the time I found Gail I was hopelessly thinking that there is no one out there that could be a good match for me. She has changed that- she gave me confidence, put a smile on my face and made me believe in love again! She is an incredible woman. I would highly recommend her for anyone who values their time and energy. Karnvir"
    },
    {
      title: 'Rupdesh & Kirat',
      location: 'Morgan Town, USA',
      image: 'assets/images/success-wedding/success-wedding-3.JPG',
      story: 'Coming soon!'
    },
    {
      title: 'Harminder & Harkirat',
      location: 'Surrey, Canada',
      image: 'assets/images/success-wedding/success-wedding-4.jpeg',
      story: "Our journey to find true love and companionship has been a dream come true, and we owe a heartfelt thank you to Eternal String. This incredible platform, brought to our attention through the dedicated and insightful service of Gail Bains, has been a cornerstone in our love story. From the moment we engaged with Eternal String, we realized the uniqueness of their approach to matchmaking – it's personalized, thoughtful, and genuinely focused on creating meaningful connections. The journey with them was not just about finding a partner, but about understanding ourselves and what we truly sought in a life companion. Today, as we reflect on our blissful marital life, we recognize how instrumental Eternal String has been. It's more than a matrimonial site; it's a gateway to finding a soulmate. For anyone who is on the quest for love, we wholeheartedly recommend Eternal String. It's not just a platform; it's a catalyst for finding lifelong happiness and love."
    }
  ]; // Add your slide content here

  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    items: 3, // Display two slides
    dots: true,
    slideTransition: 'linear',
    responsive: {
      0: {
        items: 1 // Display one slide on small screens
      },
      768: {
        items: 2 // Display two slides on medium screens
      },
      1124: {
        items: 3 // Display two slides on medium screens
      }
    }
  };


  toggleNavbar = true;
  readonly notifyOptions = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // clickIconToClose: true,
    preventDuplicates: true
  };

  txt: string = '';
  options: any;
  private subscription;
  isLoggedIn: any;
  isVideoLoaded: boolean = false;
  // country: any;
  countries: any;
  phoneCode: any = 38;
  lookingforSearchForm: FormGroup;
  // message: string | undefined;
  age_from: any = [];
  age_to: any = [];
  showNotif: boolean;
  public browserRefresh: boolean;
  couple: number = 0;
  title: any = '';
  story: any = '';
  coupleImg: any = '';
  limitStory: number = 200;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private cookieService: CookieService,
    private router: Router,
    private notifService: NotifService,
    public token: TokenService,
    private commonService: CommonService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    // private partnerPreferenceService: PartnerPreferenceService,
  ) {
    this.titleService.setTitle('Find Your Perfect Match | Best Matrimonial Site in India');
    this.meta.addTag({ name: 'description', content: "Find your happily ever after with our trusted matrimonial site. We connect compatible singles looking for love. Personalized matches. Free to join! " });
    this.meta.addTag({ name: 'robots', content: 'all' });
    this.meta.addTag({ name: 'keywords', content: 'Matrimonial Site, Find Love, life partner'});

    // this.isLoggedIn = this.token.isLoggedIn();
    // console.log('on home page isLoggedIn', this.isLoggedIn)
    // this.commonService.updateLoginStatus(this.isLoggedIn);
    // if (this.isLoggedIn) {
    //   this.router.navigate(['Home']);
    // }
    // this.showNotification();
    // this.options = {
    //   clickToClose: false,
    //   showProgressBar: true,
    //   theClass: 'er-notification',
    //   timeOut: 0,
    //   preventDuplicates: true
    // };
    setTimeout(() => {
      this.isLoggedIn = this.token.isLoggedIn();
      // console.log('on home page isLoggedIn', this.isLoggedIn)
      this.commonService.updateLoginStatus(this.isLoggedIn);
      if (this.isLoggedIn) {
        console.log("track session issue");
        this.router.navigate(['Home']);
      }
    }, 1000);

    this.showNotification();
    this.options = {
      clickToClose: false,
      showProgressBar: true,
      theClass: 'er-notification',
      timeOut: 0,
      preventDuplicates: true
    };
  }

  ngOnInit(): void {


    for (let i = 18; i <= 70; i++) {
      // unused code
      // const element = array[index];
      this.age_from.push({ id: i })
    }
    this.showNotif = true

    const routSubscription = this.router.events.subscribe((val) => {
      this.subscription.unsubscribe();
      routSubscription.unsubscribe();
    });

    this.lookingforSearchForm = this.formBuilder.group({
      looking_for: ['', [Validators.required]],
      age_from: ['', [Validators.required]],
      age_to: new FormControl('', [Validators.required]),
      religion: new FormControl('', [Validators.required]),
      country: ['', [Validators.required]],
    })

    this.getCountryList();

    this.browserRefresh = browserRefresh;
    // console.log('refreshed?:', browserRefresh);
    if (browserRefresh) {
      setTimeout(() => {
        // unused code
        // $('#openModal2').click();
      }, 5000);
    }
  }

  openStory(i: number) {
    this.couple = i;
    this.title = this.carouselItems[i].title;
    this.story = this.carouselItems[i].story;
    this.coupleImg = this.carouselItems[i].image;
    this.limitStory = 600;
  }
  readMore() {
    this.limitStory = 2000;
  }
  readLess() {
    this.limitStory = 600;
  }

  public handlePageN() {
    // unused code
    // this.spinner.show();
  }

  public handlePageP() {
    // document why this method 'handlePageP' is empty
  }

  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit')
    this.isVideoLoaded = true;
    // unused code
    // this.myVideo.nativeElement.play();
    this.playVideo();
  }

  playVideo() {
    const media = this.myVideo.nativeElement;
    media.muted = true; // without this line it's not working although I have "muted" in HTML
    media.loop = true; // without this line it's not working although I have "muted" in HTML
    media.play();
  }

  toggleVideo() {
    console.log('toggleVideo')

    this.myVideo.nativeElement.play();
  }

  goToSignUp() {
    document.getElementById("closeWelcomeModal")!.click();
    this.router.navigate(['registerDone']);
  }

  somefunction() {
    this.subscription.unsubscribe()
  }

  navigateTo(id) {
    window.scroll(0, 0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    $('#goTop').click();

    this.router.navigate([id]);
  }

  showNotification() {
    this.subscription = interval(6000).subscribe(x => {
      if (this.cookieService.get('oldUser')) {
        if (this.showNotif == true) {
          this.showNotif = false
          this.notifService.action(
            '<span class="text-danger fs-5  bottom center">Sign Up for free </span><button class="btn btn-sm btn-close position-absolute top-0 right-0 m-2"></button>',
            '<span class="text-danger pt-5">Start finding your life partner</span>',
            'btn',
            () => this.txt = `${this.showNotification()}`
          );
          this.somefunction();
        }
      } else {
        this.notifService.action(
          '<span class="text-danger fs-5  bottom center ">Sign Up for free </span><button class="btn btn-sm btn-close position-absolute top-0 right-0 m-2"></button>',
          '<span class="text-danger pt-5">Start finding your life partner</span>',
          'btn',
          () => this.txt = `${this.showNotification()}`
        );
        this.somefunction();
      }
    });
  }

  notifAction1() {
    this.notifService.action(
      'Demo notif with button',
      `Click button below: <button class="btn btn-primary">Do it!</button>`,
      'btn',
      () => this.txt = `${this.subscription.subscribe()}`
    );
  }

  notifDestroyed(notif: any) {
    this.notifService.notifDestroyed(notif['id']);
  }

  getCountryList() {
    this.authService.getCountryList().subscribe(
      (result) => {
        this.countries = result.data;
        this.commonService.updateCountryList(this.countries);
        // console.log(result.data)
      },
      (error) => {

      },
      () => {
      }
    );
  }

  submit() {
    const queryParams = {
      looking_for: this.lookingforSearchForm.value.looking_for,
      age_from: this.lookingforSearchForm.value.age_from,
      age_to: this.lookingforSearchForm.value.age_to,
      religion: this.lookingforSearchForm.value.religion,
      country: this.lookingforSearchForm.value.country,
    };

    this.authService.searchRecords(queryParams).subscribe(
      (res: any) => {
        // before sonar
        // if (res.status && res.pages && res.pages.data && res.pages.data.length > 0) {
        if (res?.pages.data && res.pages?.data?.length > 0) {
          this.router.navigate(['/search-for-looking'], { queryParams });
        } else {
          // this.message = "No Data Found"
          this.toastrService.error('No Data Found!');
        }
        // setTimeout(()=>(this.message=undefined),3000)
      },
      (error: any) => {
        console.error("Error occurred:", error);
      }
    )
  }

  checkAge() {

    if (this.lookingforSearchForm.value.age_to <= this.lookingforSearchForm.value.age_from) {
      this.f.age_from.setValue(18);
    }
  }

  get f() {
    return this.lookingforSearchForm.controls;
  }

  onChangeAgeFrom(age: any) {
    this.age_to = [];
    for (let i = age.target.value; i <= 70; i++) {
      this.f.age_to.setValue('');
      this.age_to.push({ id: i });
    }
  }

}

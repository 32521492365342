import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, ViewChild,/* ViewEncapsulation, HostListener, */ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder/*, FormGroup, FormControl, Validators, NgForm,*/ } from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { CookieService } from 'ngx-cookie-service';
import { PartnerPreferenceService } from '../services/partner-preference.service';
import { MyProfileService } from '../services/my-profile.service';
import * as moment from 'moment';
// unused import
// import { of } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import * as $ from 'jquery';
import { HttpClient/*, HttpHeaders */ } from '@angular/common/http';
import { NotifService } from '../services/notif.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/common.service';
import { Title/*, Meta */ } from '@angular/platform-browser';
import { TokenService } from './../shared/token.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.css']
})
export class MyprofileComponent implements OnInit {
  //  @ViewChild('scrollMe') myScrollContainer: any;
  @ViewChild('upgradeModalForClose') upgradeModalForClose: ElementRef;
  @ViewChild('alertBox', { static: true }) alertBox: ElementRef;


  // check screen width for alertbox scroll
  mobileMedia_Mobil_s: any = window.matchMedia("(max-width:320px)");
  mobileMedia_Mobil_M: any = window.matchMedia("(max-width:375px)");
  mobileMedia_Mobil_L: any = window.matchMedia("(max-width:425px)");
  mobileMedia_Tablet: any = window.matchMedia("(max-width:768px)");
  mobileMedia_Small_Laptop: any = window.matchMedia("(max-width:1024px)");


  [x: string]: any;


  checkedStateMap: { [id: string]: boolean } = {};

  createdBy: string;
  calculatedAge: number;
  headingCountryName: any;
  phoneCode: number;
  headingStateName: any;
  count: any;
  matches: any = [];
  TotalRecord: number = 0;
  selectId: any;
  pageNo: number = 1;
  communication: any = [];
  totalCommunication: number = 0;
  Elitematches: any = [];
  EliteTotalRecord: number = 0;
  PremiumMatches: any = [];
  ElitePremiumMatches: any = [];
  PremiumTotalRecord: any;
  HidePremium: boolean = true;
  suggestedMatches: any;
  partnerPreferenceMatches: any;
  chatCount: number;
  notifications: any;
  user: any;
  notificationsConcate: any = [];

  selectedIds: any = [];
  selectedIdsingle: any = [];

  alertsCount: any;
  upgradeDisplayName: any;
  ipAddress: string;
  memberPackageId: any;
  SkeltonLoader(i: number) {
    return new Array(i);
  }

  divbox: any;
  aboutYou = true;
  details: any = [];
  age: any;
  year: any;
  partnerDetail: any = '';
  user_id: string;
  weight: any = [];
  height: any = [];
  age_select: any = [];
  years: any = [];
  astroyears: any = [];
  hour: any = [];
  minute: any = [];
  second: any = [];
  dateSel: { id: number; name: number; }[];
  astrodateSel: { id: number; name: number; }[];

  yearSel: { id: number; name: number; }[];

  maxYear = moment().year() - 18;
  minYear = moment().year() - 90;
  displayName: any;

  countries: any;
  states: any;
  displayFirstName: any;
  displayLastName: any;
  displayUniqueKey: any;
  inboxLoader: boolean = false;
  cardCountLoader: boolean = false;
  myProfileLoader: boolean = false;

  // Basics and Lifestyle Form

  havePicture: boolean = false;
  haveCover: boolean = false;
  profileSRC: any = 'assets/images/men-avtar-2.svg';
  gender: string;
  coverSRC: any = 'assets/images/banner-4.jpg';
  aboutYouButton: boolean = false;
  fileUpload: any;
  fileUploadCount: any;
  pasteEvnetStatus: boolean;
  is_uploaded = false;
  totalMediaLength: any;
  totalMediaSize: any;
  options = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // clickIconToClose: true,
    preventDuplicates: true
  };
  ShortlistCount: number;
  ViewCount: number;
  FavouriteCount: number;
  followUpCount: number;
  sentCount: number;
  profilePercent: number;
  MLoader: boolean = true;
  CommunicationLoader: boolean = true;
  AlertsLoader: boolean = true;
  EliteLoader: boolean = true;
  member: any;


  tableSize: number = 10;
  // itemPerPage: number = 100;
  currentPage: number = 1;
  sortBy: any = '0';
  showMoreAlert: boolean = false;
  nextPage: any = 1;
  totalAlert: any;

  notClickable: boolean = true;
  living_in_country: any;
  freeTrailMessage: any;
  freetrial_start_date: any;
  freetrial_end_date: any;
  sameDate: boolean = false;


  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private registerService: RegisterService,
    private cookieService: CookieService,
    private partnerPreferenceService: PartnerPreferenceService,
    private myProfileService: MyProfileService,
    private authService: AuthService,
    private http: HttpClient,
    private notifService: NotifService,
    private titleService: Title,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private token: TokenService,
    private datePipe: DatePipe,
  ) {
    this.commonService.updateLoginStatus(true);
    this.year = new Date().getFullYear();
    this.years = [];
    this.astroyears = [];
    for (let i = this.maxYear; this.minYear <= i; i--) {
      this.years.push({ id: i, name: i });
      this.astroyears.push({ id: i, name: i });
    }
    localStorage.setItem('currentUrl', this.router.url);

    // console.log(this.mobileMedia_Mobil_s,'mobileMedia_Mobil_s');
    // console.log(this.mobileMedia_Mobil_M,'mobileMedia_Mobil_M');
    // console.log(this.mobileMedia_Mobil_L,'mobileMedia_Mobil_L');
    // console.log(this.mobileMedia_Tablet,'mobileMedia_Tablet');
    // console.log(this.mobileMedia_Small_Laptop,'mobileMedia_Small_Laptop');

    // console.log(this.mobileMedia_Mobil_s.media,'mobileMedia_Mobil_s');
    // if(this.mobileMedia_Mobil_s.matches && this.mobileMedia_Mobil_s.media=='(max-width: 320px)'){
    //   alert("hello ajay");
    // }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.titleService.setTitle('My Dashboard | eternalstrings.com');
    this.user_id = this.cookieService.get('user_id');
    let data = {
      user_id: this.user_id
    }
    this.myProfileLoader = true
    this.registerService.getUserDetails(data).subscribe((user_details: any) => {
      this.myProfileLoader = false;
      this.details = user_details.userDetails;
      if (this.details != '') {
        let register_id = this.details.register_done;
        localStorage.setItem('register_id', register_id);
        this.commonService.setRegisterDoneStep(register_id);

        this.freetrial_start_date = this.details.freetrial_start_date;
        this.freetrial_end_date = this.details.freetrial_end_date;

        if (register_id == 0) {
          this.router.navigate(['register']);
        } 
        if (register_id == '') {
          this.router.navigate(['register']);
        } else if (register_id == 1) {
          this.router.navigate(['registerNxt']);
        } else if (register_id == 2) {
          this.router.navigate(['step']);
        } else if (register_id == 3) {
          this.router.navigate(['stepTwo']);
        } else if (register_id == 4) {
          this.router.navigate(['stepDone']);
        } else if (register_id == 5) {
          this.router.navigate(['ProfilePage1']);
        } else if (register_id == 6) {
          this.router.navigate(['PartnerPreferences']);
        } else {
          this.router.navigate(['myProfile']);
        }

        if (this.details?.is_hidden == 1) {
          $('#openModal2').click();
        }

        if (this.details.profile_photo) {
          this.havePicture = true;
          this.profileSRC = this.details.profile_photo;
        } else {
          this.havePicture = false;
        }

        if (this.details.cover_photo) {
          this.haveCover = true;
          this.coverSRC = this.details.cover_photo;
        } else {
          this.haveCover = false;
        }

        if (this.details.gender == "Male") {
          this.gender = "Male";
        } else {
          this.gender = "Female";
        }
        if (this.details.age != '') {
          const date = moment(this.details.dob, 'YYYY-MM-community');
          const years = moment().diff(date, 'years');
          this.age = this.details.age;
          console.log(years)
        }

        if (this.details.living_in_country != '') {
          this.living_in_country = this.details.living_in_country;
        }

        this.getSuggestedMatches();
        this.getPartnerPreference();

        this.getNotifications();
        this.checkpachage();
      }
    }, (error) => {
      this.myProfileLoader = false
    });
    this.getViewedByShortlistedFavouritedCount();
    this.getCountOfRequest();
    this.getCommunicationMessageOnAction();
    this.getChatCount();
    this.getIpAdd()

    // this.getEliteMatches();
    // this.Akshay();




  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.notClickable = false;
  }


  getIpAdd() {
    this.commonService.index().subscribe((result) => {
      let dd = JSON.stringify(result);

      localStorage.setItem('Time-Zone', result.timezone);
      localStorage.setItem('System', dd);
      console.log(dd, "sagar")
    })
  }

  getAge(dateString: string) {
    const date = moment(dateString, 'YYYY-MM-community');
    const years = moment().diff(date, 'years');
    this.calculatedAge = years;
    return years;
  }

  getFavouriteProfiles() {
    this.inboxLoader = true;
    let data = {
      user_id: this.user_id,
      sort_by: "",
      pageNo: 1,
      itemPerPage: 8,
    }
    this.myProfileService.getFavouriteProfiles(data).subscribe(
      (result) => {
        this.FavouriteCount = result.favouriteProfilesCount;
        this.ShortlistCount = result.shortlistedProfilesCount;
        this.ViewCount = result.viewedByProfilesCount;
      }, (error) => {                              //Error callback
        //  // console.error('error caught in component')
        // this.spinner.hide();
        // this.toastrService.error('error caught in component!');
        //throw error;   //You can also throw the error to a global error handler
      })
  }

  getViewedByShortlistedFavouritedCount() {
    this.cardCountLoader = true;
    let data = {
      user_id: this.user_id
    }
    this.myProfileService.getViewedByShortlistedFavouritedCount(data).subscribe(
      (result) => {
        this.FavouriteCount = result.favouriteProfilesCount;
        this.ShortlistCount = result.shortlistedProfilesCount;
        this.ViewCount = result.viewedByProfilesCount;
        this.cardCountLoader = false
      }, (error) => {
        this.cardCountLoader = false
      })
  }

  getCountOfRequest() {
    let data = {
      user_id: this.user_id,
    };

    this.myProfileService.getCountOfRequest(data).subscribe((result) => {
      if (result.status) {
        this.sentCount = result.sentCount;
        this.followUpCount = result.followUpCount;
      }
    }, (error) => {                              //Error callback
      //  // console.error('error caught in component')
      // this.spinner.hide();
      // this.toastrService.error('error caught in component!');
      //throw error;   //You can also throw the error to a global error handler
    });
  }

  getSuggestedMatches() {
    this.inboxLoader = true;
    this.EliteLoader = true;
    this.myProfileService.getSuggestedMatches({ user_id: this.user_id }).subscribe(
      (result) => {
        if (result.status) {
          this.suggestedMatches = result.matches;
          this.testData = result.matches
          this.EliteLoader = false;
          this.calculateSugestedMatches();
          this.calculateEliteAndPremium();
        }
      }, (error) => {
        this.EliteLoader = false;
      })

  }

  testfun() {
    this.testData.forEach(element => {
      if (element.user_id == 164) {

        if (this.partnerPreferenceMatches.living_with_family != undefined && this.partnerPreferenceMatches.living_with_family != null && element.living_with_family != null && element.living_with_family != undefined) {

          if (this.partnerPreferenceMatches.living_with_family == element.living_with_family) {
            // console.log("element matched" ,) 
            // ** family type **

            if (this.partnerPreferenceMatches.family_type != null && element.family_type != null && element.family_type != undefined) {
              // Living with family 5
              if (this.partnerPreferenceMatches.family_type == element.family_type) {
                console.log("element family_type  matched",)
              } else {
                console.log("element family_type not matched", "Archna")
              }
            } else {
              // console.log(element.user_id ,"Sagar family_type",element.family_type,"error" ,"Archna family_type",this.partnerPreferenceMatches.family_type) 
            }
            // ** family type ** 
          } else {
            // console.log("element not matched" ,"Archna ")  
          }
        } else {
          // console.log(element.user_id ,"Sagar",element.living_with_family,"error" ,"Archna",this.partnerPreferenceMatches.family_type) 
        }

        // ** Have children **

        if (this.partnerPreferenceMatches.family_type != null && element.family_type != null && element.family_type != undefined) {
          // Living with family 5
          if (this.partnerPreferenceMatches.family_type == element.family_type) {
            console.log("element family_type  matched",)
          } else {
            // console.log("element family_type not matched" ,"Archna")  
          }
        } else {
          // console.log(element.user_id ,"Sagar family_type",element.family_type,"error" ,"Archna family_type",this.partnerPreferenceMatches.family_type) 
        }
        // ** family type ** 
        // console.log(element.user_id ,"Sagar",element.living_with_family,"element not matched" ,"Archna",this.partnerPreferenceMatches.living_with_family) 
      }
    });
  }

  getPartnerPreference() {
    this.partnerPreferenceService.getPartnerDetails({ user_id: this.user_id })
      .subscribe((res: any) => {
        if (res.userDetails) {
          this.commonService.setLookingFor(res.userDetails.looking_for);
          this.partnerPreferenceMatches = res.userDetails
          this.calculateSugestedMatches();
          this.calculateEliteAndPremium();
          this.profilePercentage();
        }

      });
  }

  convertDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000000Z`;
  }

  convertTimeZoneToMinutes(offset: string): number {
    const parts = offset.split(':').map(part => part.trim());
    const hours = parseInt(parts[0].replace('GMT+', ''), 10);
    const minutes = parseInt(parts[1], 10);
    const totalMinutes = (hours * 60) + minutes;
    return totalMinutes;
  }

  getGMTOffset(timezone: string): string {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'short'
    });

    const parts = formatter.formatToParts(now);
    const offsetPart = parts.find(part => part.type === 'timeZoneName');
    if (offsetPart) {
      const offset = offsetPart.value;
      return offset;
    }
    return '';
  }

  convertToGMT(timeString) {
    // Convert the given time string to a JavaScript Date object
    const date = new Date(timeString);
    // Get the current time in UTC
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    // Calculate the time in the target timezone (GMT +5:30)
    let timeZone = localStorage.getItem("Time-Zone")
    const gmtOffset = this.getGMTOffset(timeZone);
    const minutes = this.convertTimeZoneToMinutes(gmtOffset);
    const targetTimezoneOffset = minutes * 60000; // 5 hours 30 minutes = 330 minutes
    const targetTime = new Date(utcTime + targetTimezoneOffset);
    // Format the target time as a string
    const formattedTime = targetTime.toLocaleString();
    return formattedTime;
  }

  addMinutesToDateString(dateString: string, minutes: number): string {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + minutes);
    return date.toISOString();
  }

  getCommunicationMessageOnAction() {
    this.CommunicationLoader = true
    this.myProfileService.getCommunicationMessageOnAction({ user_id: this.user_id }).subscribe(
      (result) => {
        if (result.status) {
          let communicationlist = result.myCommunications;
          this.communication = [];
          communicationlist.forEach(element => {
            if ((element.message_type === 'Request accepted' && element.stage == 5) ||
              ((element.message_type === 'Connection request' && element.stage == 3) ||
                (element.message_type === 'Connection request reminder' && element.stage == 13)) ||
              (element.message_type === 'Request cancelled' && element.stage == 6)) {
              this.communication.push(element)
            }
          });


          this.totalCommunication = this.communication.length
          this.CommunicationLoader = false;
          // console.log(this.communication,"communication")
        }
        this.communication.forEach(element => {
          // console.log(element,"communication element")
          let timeZone = localStorage.getItem("Time-Zone")
          const gmtOffset = this.getGMTOffset(timeZone);
          let minuteChange = this.convertTimeZoneToMinutes(gmtOffset);
          if (element?.updated_at && element.updated_at != "" && element.updated_at != null) {
            let convertedDate = this.convertDateFormat(element.updated_at);
            let dd1 = this.addMinutesToDateString(convertedDate, minuteChange);
            const strtDate = new Date(dd1)
            // console.log(dd1, convertedDate,"format check")
            let Tdate = new Date()
            let eDate = Tdate.toISOString()
            // Convert the date strings to Date objects
            const startDate = new Date(dd1); // result date 
            const endDate = new Date(eDate); /// todays date 
            // console.log(dd1,convertedDate)
            // Calculate the time difference in milliseconds
            const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

            // Convert the time difference to days
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
            // Calculate the difference in seconds, minutes, hours, and days
            const seconds = Math.floor(timeDiff / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            let fd1 = this.datePipe.transform(startDate, 'medium');
            let fd2 = this.datePipe.transform(endDate, 'medium');
            console.log(hours, "Hours ||", daysDiff, "daysdiff||", fd1, "Fd1 || fd2", fd2)
            let msgTime = this.datePipe.transform(startDate, 'h:mm a');
            // before sonar uncomment
            // var msgTime = this.datePipe.transform(startDate, 'h:mm a');

            element['msgTime'] = msgTime;
            if (daysDiff < 8) {
              let msgDate = '';
              let msgTime = this.datePipe.transform(startDate, 'h:mm a');
              element['msgTime'] = msgTime
              if (daysDiff == 1) {
                const forstart = this.datePipe.transform(startDate, 'dd');
                const forend = this.datePipe.transform(eDate, 'dd');
                let checkD = parseInt(forend) - parseInt(forstart)
                //  // console.log(checkD) 
                if (checkD == 0) {
                  msgDate = "Today"
                  element['msgDate'] = msgDate
                } else {
                  msgDate = "Yesterday"
                  element['msgDate'] = msgDate
                }
              } else if (daysDiff == 2) {
                msgDate = "Yesterday"
                element['msgDate'] = msgDate

              } 
              if (daysDiff > 2) {
                // unused code
                // Get the day of the week (0-6 where 0 is Sunday)
                const dayOfWeek = strtDate.getDay();
                // unused code
                // Convert the day of the week to a string representation
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                msgDate = days[dayOfWeek];
                element['msgDate'] = msgDate
                console.log()
              }
            } else {
              // Format the timestamp
              const forDate = this.datePipe.transform(convertedDate, 'dd/MM/yyyy');
              // console.log(, "fordate",timeDiff,'Days Difference: is more ', daysDiff ,startDate,"startDate.getTime() ||" ,endDate);
              element['msgDate'] = forDate;
            }
          }
        });
      }, (error) => {

      });
  }

  searchPartner() {
    let looking_for:any;
    if (this.details.gender == "Male") {
      looking_for = "Female";
    } else {
      looking_for = "Male";
    }
    let data = {
      user_id: this.user_id,
      looking_for: looking_for,
      age_from: 18,
      age_to: 90,
      height_from: 100,
      height_to: 230,
      weight_from: 70,
      weight_to: 250,
      living_with_family: "",
      family_type: [],
      marital_status: [],
      have_children: "",
      religion: [],
      first_language: [],
      qualification: [],
      working_as: [],
      income_currency: null,
      income_range_from: null,
      income_range_to: null,
      sort_by: "",
      state_name: [],
      isAllCountrySelected: false,
      allCountrySelected: [],
      isOtherCountrySelected: false,
      otherCountrySelected: [],
      selectedCountry: [],
      isAllMaritalStatus: false,
      isAllReligion: false,
      isAllLanguage: false,
      isAllResidencyStatus: false,
      isAllEducation: false,
      isAllNatureOfWork: false,
      isAllProfession: false,
      isAllFoodPreference: false,
      itemPerPage: 1,
      pageNo: this.pageNo,
      isOtherMaritalStatus: false,
      isOtherReligion: false,
      isOtherLanguage: false,
      isOtherResidencyStatus: false,
      isOtherEducation: false,
      isOtherNatureOfWork: false,
      isOtherProfession: false,
      isOtherFoodPreference: false,
      annual_income: "OpenToAll"
    }
    this.myProfileService.searchPartner(data).subscribe(
      (result) => {
        if (result.status) {
          localStorage.setItem('matchedProfilesCount', result.pages.total)
        }
      }, (error) => {
      })
  }
  getEliteMatches() {
    let data =
    {
      user_id: this.user_id,
      package_id: 2,

    }
    this.myProfileService.getSuggestedMatches(data).subscribe(
      (result) => {
        if (result.status) {
          this.Elitematches = result.pages.data;
          this.EliteTotalRecord = result.pages.total;
        }
      }, (error) => {
      })
  }

  getChatCount() {
    this.myProfileService.getChatCount({ user_id: this.user_id }).subscribe(
      (result) => {
        if (result.status) {
          this.chatCount = result.chatCount
        }
      }, (error) => {
      })
  }

  getNotifications() {
    this.AlertsLoader = true;
    let data = {
      user_id: this.user_id,
      page: this.currentPage,
      itemPerPage: this.tableSize,
    }

    this.myProfileService.getNotifications(data).subscribe(
      (result) => {
        if (result.status) {
          //this.PremiumMatches =result.pages.data;
          // this.selectedIds = [];
          this.AlertsLoader = false;
          this.notifications = result.notifications;
          this.user = result.user;
          this.notificationsConcate = this.notificationsConcate.concat(this.notifications);


       

          // this.notificationsConcate.forEach(element => {
          //   if (element.type === 'new_feature' && element.message) {
          //     // Initialize variables for URL and message content
          //     let url = '';
          //     let message = '';
          
          //     // Regex to extract URL if it exists
          //     const urlRegex = /href="([^"]*)"/;
          //     const urlMatches = element.message.match(urlRegex);
          //     if (urlMatches && urlMatches.length > 1) {
          //       url = urlMatches[1];
          //       console.log(url);
          //       element['pageUrl'] = url;
          //     }
          
          //     // Regex to extract message content
          //     const messageRegexWithLink = /<p[^>]*>(.*?)&nbsp;<a/;
          //     const messageRegexWithoutLink = /<p[^>]*>(.*?)<\/p>/;
          
          //     let messageMatches = element.message.match(messageRegexWithLink);
          //     if (!messageMatches) {
          //       messageMatches = element.message.match(messageRegexWithoutLink);
          //     }
          
          //     if (messageMatches && messageMatches.length > 1) {
          //       message = messageMatches[1];
          //     } else {
          //       // If no matches, use the entire message content
          //       message = element.message;
          //     }
          
          //     // Remove all HTML tags from the message content
          //     message = message.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim();
          //     console.log(message);
          //     element['messageText'] = message;
          //   }
          // });




          this.notificationsConcate.forEach(element => {
            if (element.type === 'new_feature' && element.message) {
                // Initialize variables for URL and message content
                let url = '';
                let message = element.message;
        
                // Regex to extract URL if it exists
                const urlRegex = /href="([^"]*)"/;
                const urlMatches = element.message.match(urlRegex);
                if (urlMatches && urlMatches.length > 1) {
                    url = urlMatches[1];
                    console.log(url);
                    element['pageUrl'] = url;
                }
        
                // Remove the 'click here' link and its surrounding anchor tags
                const clickHereRegex = /<a[^>]*>click here<\/a>/i;
                message = message.replace(clickHereRegex, '');
        
                // Regex to extract message content
                const messageRegex = /<p[^>]*>(.*?)<\/p>/;
                const messageMatches = message.match(messageRegex);
        
                if (messageMatches && messageMatches.length > 1) {
                    message = messageMatches[1];
                }
        
                // Remove all HTML tags from the message content
                message = message.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim();
                console.log(message);
                element['messageText'] = message;
            }
        });



  //   this.notificationsConcate.forEach(element => {
  //     if (element.type === 'event_notification' && element.message) {
  //         // Parse the JSON string within the message field
  //         let parsedMessage = JSON.parse(element.message);
  
  //         // Since the parsed message is an array, we'll need to handle the array elements
  //         parsedMessage.forEach(msg => {
  //             console.log(msg);
  
  //             // Extract date and time parts
  //             let startDateTime = msg.start_date.split(' ');
  //             let endDateTime = msg.end_date.split(' ');
  
  //             // Assign the values to the element
  //             element['messageTextEvent'] = msg.message;
  //             element['messagestart_date'] = msg.start_date;
  //             element['timeFrom'] = startDateTime[startDateTime.length - 2] + ' ' + startDateTime[startDateTime.length - 1];
  //             element['timeto'] = endDateTime[endDateTime.length - 2] + ' ' + endDateTime[endDateTime.length - 1];
  //             element['messageend_date'] = msg.end_date;
  //         });
  //     }
  // });


  this.notificationsConcate.forEach(element => {
    if (element.type === 'event_notification' && element.message) {
        // Parse the JSON string within the message field
        let parsedMessage = JSON.parse(element.message);

        // Since the parsed message is an array, we'll need to handle the array elements
        parsedMessage.forEach(msg => {
            console.log(msg);

            // Extract date and time parts
            let startDateTime = msg.start_date.split(' ');
            let endDateTime = msg.end_date.split(' ');

            element['sameDate'] = (startDateTime[0] === endDateTime[0]);

            // Assign the values to the element
            element['messageTextEvent'] = msg.message;
            element['messagestart_date'] = startDateTime[0]; // Only date part
            element['timeFrom'] = startDateTime[1] + ' ' + startDateTime[2]; // Time part with AM/PM
            element['timeto'] = endDateTime[1] + ' ' + endDateTime[2]; // Time part with AM/PM
            element['messageend_date'] = endDateTime[0]; // Only date part
        });
    }
});

  
    
        
          





          this.alertsCount = this.notifications.length;
          this.nextPage = result.nextPage;
          this.totalAlert = result.totalRecord;

          if (this.totalAlert > this.tableSize && this.totalAlert != this.notificationsConcate.length) {
            this.showMoreAlert = true;
          } else {
            this.showMoreAlert = false;
          }

          setTimeout(() => {
            document.getElementById('alert_wpr').scrollTop = 966 * (this.currentPage - 1);
            // const alr= document.getElementById('alert_wpr2');
            // const alr1= alr.scrollHeight-350;
            // window.scroll(0,alr1);
          }, 200);

        } else {
          this.notifications = result.notifications
          this.alertsCount = 0
          this.AlertsLoader = false;
        }
        this.AlertsLoader = false;
      }, (error) => {
        this.AlertsLoader = false;
      })
  }


  photoAlert() {
    if (this.details.cover_photo) {
      let cover = {
        "id": 2,
        "sender_id": null,
        "receiver_id": this.user_id,
        "type": "cover_photo",
        "status": 0,
        "is_deleted": 0,
        "created_at": this.convertDateFormat(new Date()),
        "updated_at": this.convertDateFormat(new Date()),
        "profile_photo": null,
        "display_name": this.details.display_name
      }
      console.log(cover)
    }
    if (this.details.profile_photo) {
      let profile = {
        "id": 1,
        "sender_id": null,
        "receiver_id": this.user_id,
        "type": "profile_photo",
        "status": 0,
        "is_deleted": 0,
        "created_at": this.convertDateFormat(new Date()),
        "updated_at": this.convertDateFormat(new Date()),
        "profile_photo": null,
        "display_name": this.details.display_name
      }
      console.log(profile)
    }
  }

  public handlePageN() {
    if (this.TotalRecord == this.pageNo) {
      this.pageNo = 1;
      this.searchPartner();
    } else {
      this.pageNo = this.pageNo + 1;
      this.searchPartner();
    }
  }

  public handlePageP() {
    if (this.pageNo != 1) {
      this.pageNo = this.pageNo - 1;
      this.searchPartner();
    }
  }

  ViewProfile(id, name) {
    localStorage.setItem("pageFrom", "Matches");
    if (this.details.package_id > 0) {
      let encrypted_id: any = this.commonService.encryptData(id);
      encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
      let url = `${environment.baseURL}` + 'detail/' + encrypted_id
      window.open(url, '_blank');
    } else {
      this.upgradeDisplayName = name;
      $('#openModal').click();
    }
  }

  ViewRequest(type: any, alert_id: any) {
    // console.log(type)
    let url:any
    if (type == "Connection request") {
       url = `${environment.baseURL}` + 'comunication-inbox/'
    } else if (type == "Request accepted") {
       url = `${environment.baseURL}` + 'accepted/'
    } else if (type == "Request cancelled") {
       url = `${environment.baseURL}` + 'inbox-cancelled/'
    } else if (type == "Connection request reminder") {
       url = `${environment.baseURL}` + 'comunication-inbox/'
    }

    let pload = {
      id: alert_id
    }
    this.myProfileService.updateCommunicationAlertStatus(pload).subscribe((result) => {
      if (result?.status) {
        // unused code
        // this.toastrService.success(result.message);
        console.log(result)
      } else {
        // unused code
        // this.toastrService.error(result.message);
      }
    });
    window.open(url, '_blank');
  }

  actionSelected(event: any, profile_id: any) {
    let std = event.target.id;
    this.selectId = profile_id;
    if (std == "add_to_shortlist") {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: "add_to_shortlist",
      }
      this.myProfileService.addToShortlist(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success('Profile added to my shortlist!');
          } else {
            this.toastrService.error('Profile already shortlisted!');
          }
        })
    } else if (std == "do_not_show_again") {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: "do_not_show_again",
      }
      this.myProfileService.actionOnProfile(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success('Profile updated!');
          } else {
            this.toastrService.error('Profile not updated!');
          }
        })
    } else {
      let pload = {
        user_id: this.user_id,
        profile_id: this.selectId,
        action: "block_and_report_profile",
      }
      this.myProfileService.actionOnProfile(pload).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success('Profile blocked!');
          } else {
            this.toastrService.error('Profile not blocked!');
          }
        })
    }
  }

  calculateSugestedMatches() {
    this.MLoader = true;
    this.matches = [];
    this.Elitematches = [];
    this.PremiumMatches = [];
    let p1 = [];
    let e1 = [];
    let m1 = [];

    if (this.suggestedMatches && this.partnerPreferenceMatches) {

      this.suggestedMatches.forEach(element => {
        this.count = 0;
        this.countMatched = 0;

        if (this.partnerPreferenceMatches.age_from != null && this.partnerPreferenceMatches.age_to != null && element.age != null) {
          let today = moment();
          let birthdate = moment(element.dob);
          let age = element.age;
          if (this.partnerPreferenceMatches.age_from <= age && this.partnerPreferenceMatches.age_to >= age) {
            this.count++
            this.countMatched++;
          }
          console.log(birthdate, today)
        }

        if (this.partnerPreferenceMatches.marital_status != null) {
          if (this.partnerPreferenceMatches.marital_status == "Any Of These") {
            this.countMatched++;
          } else if (element.marital_status != null) {
            const ismaritalStatus = this.partnerPreferenceMatches.marital_status.includes(element.marital_status);
            if (ismaritalStatus) {
              this.countMatched++;
            }
          }
        }

        if (this.partnerPreferenceMatches.country_name != null) {
          if (this.partnerPreferenceMatches.country_name == 0) {
            this.countMatched++;
          } else if (element.living_in_country != null && this.partnerPreferenceMatches.country_name == element.living_in_country || this.partnerPreferenceMatches.country_name == 0) {
            this.count++;
            this.countMatched++;
          }
        }

        if (this.countMatched >= 3) {
          this.matches.push(element)
        }
      });

      console.log("suggested matches", this.matches)
      this.MLoader = false;
      this.TotalRecord = this.matches.length;

      this.EliteTotalRecord = this.Elitematches.length;
      this.PremiumTotalRecord = this.PremiumMatches.length;
      this.EliteLoader = false;
      this.inboxLoader = false;


    } else {
      // console.log("wait")
    }
  }

  deleteAlert(id) {
    // console.log(id)
    this.selectedIdsingle = [];
    this.selectedIds = [];
    this.selectedIdsingle.push(id)
    var pload = {
      alert_ids: this.selectedIdsingle,
      user_id: parseInt(this.user_id)
    }

    console.log(this.selectedIdsingle, 'this.selectedIdsingle in single delete array');

    this.myProfileService.deleteNotification(pload).subscribe(
      (result) => {
        if (result.status) {
          this.selectedIdsingle = [];
          this.initializeCheckedState();
          this.notificationsConcate = [];
          this.currentPage = 1;
          this.getNotifications();
          this.scrollOnShowMore();

          //  // console.log(result)
          // this.getViewedByProfiles();
          this.toastrService.success(result.message);
        } else {
          // unused code
          // this.toastrService.error('Profile not blocked!');
        }
      })
  }

  checkboxId(event) {
    let Sid = parseInt(event.target.id)
    if (this.selectedIds.some(a => a === Sid)) {
      this.selectedIds = this.selectedIds.filter(a => a !== Sid)
    } else {
      this.selectedIds.push(Sid)
    }
    console.log(this.selectedIds, 'this.selectedIds');
    // console.log(event.target.id, this.selectedIds,typeof(Sid),'balram',Sid)
  }

  deleteSelectedAlert() {
    this.selectedIdsingle = [];

    var data = {
      alert_ids: this.selectedIds,
      user_id: parseInt(this.user_id)
    }
    console.log(this.selectedIds, 'this.selectedIds in delete array');

    this.myProfileService.deleteNotification(data).subscribe(
      (result) => {
        if (result.status) {
          this.selectedIds = [];
          this.notificationsConcate = [];
          this.currentPage = 1;
          this.getNotifications();
          this.initializeCheckedState();
          this.scrollOnShowMore();


          //// console.log(result)
          // this.getViewedByProfiles();
          this.toastrService.success(result.message);
        } else {
          // unused code
          //    this.alerts = 0
          // this.toastrService.error('Profile not blocked!');
        }
      })
  }

  refreshAlerts() {
    this.initializeCheckedState();
    this.selectedIds = [];
    this.selectedIdsingle = [];
    this.notificationsConcate = [];
    this.currentPage = 1;
    this.getNotifications();
    this.scrollOnShowMore();

    // const alr= document.getElementById('alert_wpr2');
    // const alr1= alr.scrollHeight-375;
    // window.scroll(0,alr1);

  }

  manageAlerts() {
    this.router.navigate(['setting']);
    localStorage.setItem("tabAleart", 'aleart');
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    margin: 24,
    center: true,
    dots: true,
    navSpeed: 700,
    navText: [' < ', ' > '],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      1366: {
        items: 2
      }
    },
    nav: true

  }


  // free member upgrade popup stert
  checkpachage() {

    const temp = localStorage.getItem('Member');
    this.memberPackageId = localStorage.getItem('package_id');
    if (temp != null && this.memberPackageId == 1) {
      //  // console.log('Freemember');
      //  // console.log(temp);
      $('#openModal1').click();
      localStorage.removeItem('Member');
    } else {
      //  // console.log('NotFreemember'); 
      localStorage.removeItem('Member');
    }
    // free member upgrade popup end


  }

  calculateEliteAndPremium() {
    if (this.suggestedMatches && this.partnerPreferenceMatches) {
      // console.log('total record:',this.suggestedMatches.length)
      for (const element of this.suggestedMatches) {
        let pId = element.package_id;
        if (pId == 1) {
          continue;
        }
        if (this.partnerPreferenceMatches.age_from != null && this.partnerPreferenceMatches.age_to != null && element.age != null) {

          let age = element.age;
          if (!(this.partnerPreferenceMatches.age_from <= age && this.partnerPreferenceMatches.age_to >= age)) {
            continue;
          }
        } else if (element.age == null || element.age == '') {
          continue;
        }

        if (this.partnerPreferenceMatches.height_from != null && this.partnerPreferenceMatches.height_to != null && element.height != null) {
          if (!(this.partnerPreferenceMatches.height_from <= element.height && this.partnerPreferenceMatches.height_to >= element.height)) {
            continue;
          }
        } else if (element.height == null || element.height == '') {
          continue;
        }

        if (this.partnerPreferenceMatches.marital_status != null && this.partnerPreferenceMatches.marital_status != 'Any Of These') {
          if (element.marital_status != null) {
            let looking_range = this.partnerPreferenceMatches.marital_status.split(',');
            if (looking_range.indexOf(element.marital_status) == -1) {
              continue;
            }
          } else if (element.marital_status == null || element.marital_status == '') {
            continue;
          }
        }

        if (this.partnerPreferenceMatches.religion != null && this.partnerPreferenceMatches.religion != 'Any Religion') {
          const isreligion = this.partnerPreferenceMatches.religion.includes(element.religion);
          if (!isreligion) {
            continue;
          }
        }

        if (this.partnerPreferenceMatches.residency_status != null && element.residency_status != null) {
          const isresidency_status = this.partnerPreferenceMatches.residency_status.includes(element.residency_status);
          if (!isresidency_status) {
            continue;
          }
        } else {
          continue;
        }

        if (this.partnerPreferenceMatches.country_name != null && this.partnerPreferenceMatches.country_name != 0) {
          if (this.partnerPreferenceMatches.country_name != element.living_in_country) {
            continue;
          } else if (this.partnerPreferenceMatches.state_name != null && element.state_name != null) {
              // document why this block is empty
          }
        }

        if (pId == 2) {
          this.PremiumMatches.push(element);
          this.PremiumTotalRecord = this.PremiumMatches.length;
          this.EliteLoader = false;

        } else if (pId == 3) {
          this.Elitematches.push(element);
          this.EliteTotalRecord = this.Elitematches.length;
        }
      }
    }
  }

  profilePercentage() {
    const myObject = this.details;
    const myObject1 = this.partnerPreferenceMatches;
    let percentCount = 0;
    let total = 38;
    let filledArr = []; // selft detail 
    let filledArr1 = []; // partner detail
    let selftDetailTotal = ["first_name", "last_name", "mobile_no", "email", "gender", "languages", "religion", "age", "living_in_country", "state_name",
      "current_city", "postal_code", "living_with_family", "residency_status", "country_of_birth", "marital_status", "food_preference",
      "height", "weight", "qualification", "nature_of_work", "description", "profile_photo", "cover_photo", "convenient_time_to_call"];

    let partnerDetailTotal = ["looking_for", "age_from - age_to", "height_from - height_to", "marital_status", "religion", "languages",
      "country_name", "state_name", "residency_status", "education", "nature_of_work", "food_preference", "description",]
    if (myObject) {
      if (myObject.profile_for != null && myObject.profile_for != '' && myObject.profile_for == "Self") {
        // document why this block is empty
      }
      if (myObject.first_name != null && myObject.first_name != '') {
        percentCount++
        filledArr.push("first_name")
      }
      if (myObject.last_name != null && myObject.last_name != '') {
        percentCount++
        filledArr.push("last_name")

      }
      if (myObject.mobile_no != null && myObject.mobile_no != '') {
        percentCount++
        filledArr.push("mobile_no")
      }
      if (myObject.email != null && myObject.email != '') {
        percentCount++
        filledArr.push("email")
      }
      if (myObject.gender != null && myObject.gender != '') {
        percentCount++
        filledArr.push("gender")
      }
      if (myObject.languages != null && myObject.languages != '') {
        percentCount++
        filledArr.push("languages")
      }
      if (myObject.religion != null && myObject.religion != '') {
        percentCount++
        filledArr.push("religion")
      }
      if (myObject.age != null && myObject.age != '') {
        percentCount++
        filledArr.push("age")
      }
      if (myObject.living_in_country != null && myObject.living_in_country != '') {
        percentCount++
        filledArr.push("living_in_country")
      }
      if (myObject.state_name != null && myObject.state_name != '') {
        percentCount++
        filledArr.push("state_name")
      }
      if (myObject.current_city != null && myObject.current_city != '') {
        percentCount++
        filledArr.push("current_city")
      }
      if (myObject.postal_code != null && myObject.postal_code != '') {
        percentCount++
        filledArr.push("postal_code")
      }
      if (myObject.living_with_family != null && myObject.living_with_family != '') {
        percentCount++
        filledArr.push("living_with_family")
      }
      if (myObject.residency_status != null && myObject.residency_status != '') {
        percentCount++
        filledArr.push("residency_status")
      }
      if (myObject.country_of_birth != null && myObject.country_of_birth != '') {
        percentCount++
        filledArr.push("country_of_birth")
      }
      // console.log(total, "before self marital")
      if (myObject.marital_status != null && myObject.marital_status != '') {
        percentCount++
        filledArr.push("marital_status")
        if (myObject.marital_status != "Single" && myObject.marital_status != "Annulled") {
          total++
          selftDetailTotal.push("have_children")
          if (myObject.have_children != null && myObject.have_children != '') {
            percentCount++
            filledArr.push("have_children")
          }
        }
      }
      // console.log(total, "after self marital")

      if (myObject.food_preference != null && myObject.food_preference != '') {
        percentCount++
        filledArr.push("food_preference")
      }
      if (myObject.height != null && myObject.height != '') {
        percentCount++
        filledArr.push("height")
      }
      if (myObject.weight != null && myObject.weight != '') {
        percentCount++
        filledArr.push("weight")
      }
      if (myObject.qualification != null && myObject.qualification != '') {
        percentCount++
        filledArr.push("qualification")
      }

      if (myObject.nature_of_work != null && myObject.nature_of_work != '') {
        percentCount++
        filledArr.push("nature_of_work")
      }
      if (myObject.description != null && myObject.description != '') {
        percentCount++
        filledArr.push("description")
      }
      if (myObject.profile_photo != null && myObject.profile_photo != '') {
        percentCount++
        filledArr.push("profile_photo")
      }
      if (myObject.cover_photo != null && myObject.cover_photo != '') {
        percentCount++
        filledArr.push("cover_photo")
      }
      if (myObject.convenient_time_to_call != null && myObject.convenient_time_to_call != '') {
        percentCount++
        filledArr.push("convenient_time_to_call")
      }

      // partner preferences 
      if (myObject1) {
        if (myObject1.looking_for != null && myObject1.looking_for != '') {
          percentCount++
          filledArr1.push("looking_for")
        }

        if (myObject1.age_from != null && myObject1.age_to != null && myObject1.age_from != '' && myObject1.age_to != '') {
          percentCount++
          filledArr1.push("age_from - age_to")

        }
        if (myObject1.height_from != null && myObject1.height_to != null && myObject1.height_from != '' && myObject1.height_to != '') {
          percentCount++
          filledArr1.push("height_from - height_to")
        }
        // console.log(total, "before partner marital")
        if (myObject1.marital_status != null && myObject1.marital_status != '') {
          percentCount++
          filledArr1.push("marital_status")
          if (myObject1.marital_status != "Single" && myObject1.marital_status != "Annulled" && myObject1.marital_status != "Single,Annulled" && myObject1.marital_status != "Annulled,Single") {
            total++
            partnerDetailTotal.push("have_children")
            if (myObject1.have_children != null && myObject1.have_children != '') {
              percentCount++
              filledArr1.push("have_children")
            }
          }
        }
        // console.log(total, "after partner marital")
        if (myObject1.religion != null && myObject1.religion != '') {
          percentCount++
          filledArr1.push("religion")
        }
        if (myObject1.languages != null && myObject1.languages != '') {
          percentCount++
          filledArr1.push("languages")
        }
        if (myObject1.country_name != null && myObject1.country_name != '' || myObject1.country_name == 0) {
          percentCount++
          filledArr1.push("country_name")
        }
        if (myObject1.state_name != null && myObject1.state_name != '') {
          percentCount++
          filledArr1.push("state_name")
        }
        if (myObject1.residency_status != null && myObject1.residency_status != '') {
          percentCount++
          filledArr1.push("residency_status")
        }
        if (myObject1.education != null && myObject1.education != '') {
          percentCount++
          filledArr1.push("education")
        }
        if (myObject1.nature_of_work != null && myObject1.nature_of_work != '') {
          percentCount++
          filledArr1.push("nature_of_work")
        }
        if (myObject1.food_preference != null && myObject1.food_preference != '') {
          percentCount++
          filledArr1.push("food_preference")
        }
        if (myObject1.description != null && myObject1.description != '') {
          percentCount++
          filledArr1.push("description")
        }
      }
      this.profilePercent = (percentCount / total) * 100
      // console.log("percentCount", percentCount, "total", total, this.profilePercent / 100)
      if (this.profilePercent > 100) {
        this.profilePercent = 100
      }
      if (this.profilePercent == 100) {
        localStorage.removeItem('inCompleteUserDetail');
        localStorage.removeItem('inCompletePatnerDetailDetail');
      }
    }
    this.nonMatchedValuesSelf = this.findNonMatchedValues(filledArr, selftDetailTotal);
    this.nonMatchedValuespartner = this.findNonMatchedValues(filledArr1, partnerDetailTotal);
  }

  findNonMatchedValues(arr1: any[], arr2: any[]): any[] {
    return arr1.filter(item => !arr2.includes(item))
      .concat(arr2.filter(item => !arr1.includes(item)));
  }

  // plese do no work I(Sagar) working on it 
  completeNow() {
    localStorage.setItem('inCompleteUserDetail', this.nonMatchedValuesSelf);
    localStorage.setItem('inCompletePatnerDetailDetail', this.nonMatchedValuespartner);
    this.router.navigate(['myInfo']);
  }

  allowToAccess(alert, status) {
    if (this.details.package_id == 1) {
      $('#openModal1').click();
    } else {
      let type:any;
      if (alert.type == 'request_astro_detail') {
        type = "astro_detail"
      } else if (alert.type == 'request_contact_detail') {
        type = "contact_detail"
      }

      let data = {
        profile_id: alert.sender_id,
        user_id: this.user_id,
        access_for: type,
        request_access_status: status,
        alert_id: alert.id
      }
      // console.log(data, "data", alert)
      this.myProfileService.actionOnRequestAccess(data).subscribe(
        (result) => {
          if (result.status) {
            this.toastrService.success(result.message);
            this.refreshAlerts();
          } else {
            this.toastrService.error(result.message);
            this.refreshAlerts()
          }
        },
        (error) => {
          this.toastrService.error('Some thing went wrong!!');
        }
      );
    }
  }

  // atert showmore code start
  showMoreUserAlertFun(nextPage) {
    this.currentPage = nextPage;
    this.getNotifications();

    this.scrollOnShowMore();

    if (this.alertBox) {
      this.alertBox.nativeElement.scrollTop = this.alertBox.nativeElement.scrollHeight;
      // console.log(this.alertBox,'this.alertBox2');
    }


  }

  onScrollMe(event) {
    // uncoment before code push
    let total = this.myScrollContainer.nativeElement.scrollHeight - this.myScrollContainer.nativeElement.offsetHeight - 1;
    if (event.target.scrollTop > total) {
      // unused code
      // console.log('scroll1');
      //  this.updateMessageStatus()
      // console.log('err in scrollToBottom 1122 onScrollMe');
    }
  }

  // scrollToBottom(haveReadMessage): void {
  //   try {
  //     // console.log('scrollToBottom called ');
  //     this.myScrollContainer.nativeElement.scrollTo({ left: 0, top: this.myScrollContainer.nativeElement.scrollHeight, behavior: 'smooth' });
  //     if (haveReadMessage) {
  //       this.updateMessageStatus();
  //       // console.log("send message have unreadmssage  ")
  //     }
  //   } catch (err) {
  //     //  // console.log('err in scrollToBottom',err);
  //   }
  // }

  // atert showmore code end

  unhide() {
    const modalElement = this.upgradeModalForClose.nativeElement;
    // Check if the modal is open before trying to close it
    if ($(modalElement).hasClass('show')) {
      // Close the modal using JavaScript
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      document.body.classList.remove('modal-open');
      $('.modal-backdrop').remove();
    }
    this.router.navigate(['setting']);
    localStorage.setItem("hideProfile", 'hideProfile');
  }

  renewPlan() {
    // let data = this.details.package_id + "-renewal"
    // let encrypted_id: any = this.commonService.encryptData(data);
    // encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
    // this.router.navigate(['packages/' + encrypted_id]);
    this.router.navigate(['upgrade']);
  }

  upgradeFreeTrial() {
    let data = {
      user_id: this.user_id,
      package_id: 4,
    }
    this.myProfileService.upgradeFreeTrail(data).subscribe(
      (result) => {
        if (result.status) {
          localStorage.removeItem('package_id');
          localStorage.setItem('package_id', '4');
          this.freeTrailMessage = result.message;
          $('#openModal4').click();
        } else {
          this.toastrService.error(result.message);
        }
      },
      (error) => {
        this.toastrService.error('Some thing went wrong!!');
      }
    );

  }

  backToUpgrade() {
    $('#openModal1').click();
  }

  goTofreeTrial() {
    $('#openModal3').click();
  }

  memberSet() {
    localStorage.setItem('Member', 'Free');
  }

  suggested_matches(user) {
    const filterValue = user;
    const encodedFilterValue = encodeURIComponent(filterValue);
    const url = `${environment.baseURL}` + '/suggested-matches?sort_by=' + encodedFilterValue;
    window.open(url, '_blank');
    console.log('balram', filterValue, encodedFilterValue);
  }


  // Function to update checked state
  updateCheckedState(profile: any, isChecked: boolean) {
    this.checkedStateMap[profile.id] = isChecked;
  }

  // Initialize checked state map based on initial notificationsConcate array
  initializeCheckedState() {
    this.notificationsConcate.forEach(profile => {
      this.checkedStateMap[profile.id] = profile.checkedbox || false;
    });
  }


  scrollOnShowMore() {

    if (this.mobileMedia_Mobil_s.matches && this.mobileMedia_Mobil_s.media == '(max-width: 320px)') {
      window.scroll(0, 3020);
      console.log("Mobil_s");
    } else if (this.mobileMedia_Mobil_M.matches && this.mobileMedia_Mobil_M.media == '(max-width: 375px)') {
      window.scroll(0, 3075);
      console.log("Mobil_M");
    } else if (this.mobileMedia_Mobil_L.matches && this.mobileMedia_Mobil_L.media == '(max-width: 425px)') {
      window.scroll(0, 3150);
      console.log("Mobil_L");
    } else if (this.mobileMedia_Tablet.matches && this.mobileMedia_Tablet.media == '(max-width: 768px)') {
      window.scroll(0, 1050);
      console.log("Tablet");
    } else if (this.mobileMedia_Small_Laptop.matches && this.mobileMedia_Small_Laptop.media == '(max-width: 1024px)') {
      window.scroll(0, 760)
      console.log("Small_Laptop");

    } else {

      const alr = document.getElementById('alert_wpr2');
      const alr1 = alr.scrollHeight - 355;
      window.scroll(0, alr1);
    }


    // console.log(this.mobileMedia_Mobil_s,'mobileMedia_Mobil_s');
    // console.log(this.mobileMedia_Mobil_M,'mobileMedia_Mobil_M');
    // console.log(this.mobileMedia_Mobil_L,'mobileMedia_Mobil_L');
    // console.log(this.mobileMedia_Tablet,'mobileMedia_Tablet');
    // console.log(this.mobileMedia_Small_Laptop,'mobileMedia_Small_Laptop');

    // console.log(this.mobileMedia_Mobil_s.media,'mobileMedia_Mobil_s');
    // if(this.mobileMedia_Mobil_s.matches && this.mobileMedia_Mobil_s.media=='(max-width: 320px)'){
    //   alert("hello ajay");
    // }

    // const alr= document.getElementById('alert_wpr2');
    //   const alr1= alr.scrollHeight-355;
    //   window.scroll(0,alr1);
  }

  ViewAlertPage(alertPage){

    if(alertPage == 'update_safety_tips'){
      let url = `${environment.baseURL}` + 'safety-tips'
      window.open(url, '_blank');
    } else if(alertPage == 'update_disclaimer'){
      let url = `${environment.baseURL}` + 'Disclaimer'
      window.open(url, '_blank');
    }else if(alertPage == 'update_payment_policy'){
      let url = `${environment.baseURL}` + 'payment'
      window.open(url, '_blank');
    }
    // else if(alertPage == 'new_features'){
    //   // let url = `${environment.baseURL}` + 'safety-tips'
    //   // window.open(url, '_blank');
    //   console.log('new_features');
      
    // }
   
  }

  ViewAlertPageFeature1(pageUrl:any){
    console.log(pageUrl);
    let url = pageUrl;
    console.log(url);
    window.open(url, '_blank');
  }


 

}

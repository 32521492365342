import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
//import { Observer } from 'rxjs/Observer';
import { ChatComponent } from '../chat/chat.component';

import * as io from 'socket.io-client';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    private url = 'https://eternalstrings.com:2201/';
    //   'https://demoprojects.itsabacus.net:2201/';
    //  'https://demoprojects.itsabacus.net:2201/';
    //  'http://127.0.0.1:8080/';
    //  'http://localhost:8080/chat/';
    // 'http://'+document.domain+':2021/';//// https://repel-dry.com/Admin/Repel-Dry/main-sales-office/customer-list/1
    private socket;

    constructor(private http: HttpClient) {
        this.socket = io(this.url);
    }

    public add_chat_messages(message) {
        this.socket.emit('socketApi', message);
    }

    public getRequestSocket = () => {
        return Observable.create((observer) => {
            this.socket.on('socketApi', (message) => {
                observer.next(message);
            });
        });
    }

    sendMessage(msg: string) {
        this.socket.emit('message', msg);
    }
    getMessage() {
        return this.socket.fromEvent('message').pipe(map((data: any) => data.msg));
    }

}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotifService } from '../services/notif.service';
import { CookieService } from 'ngx-cookie-service';
// unused import
//import { interval } from 'rxjs';
import { DomSanitizer, SafeHtml,Title, Meta } from '@angular/platform-browser';
import { MyProfileService } from '../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from '../services/register.service';
import {/* RouterEvent,*/ Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../admin/adminServices/admin.service';
import { CommonService } from '../services/common.service';
import { TokenService } from '../shared/token.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})

export class BlogDetailComponent implements OnInit {
  tagsList: string[] = [
    "Commitment",
    "Compatibility",
    "Connect",
    "Couple",
    "Family",
    "Festival",
    "Find love",
    "First date",
    "Happily Married",
    "Help",
    "Indian",
    "Inspiring story",
    "Interact",
    "Life partner",
    "Love",
    "Made for each other",
    "Marriage",
    "Match",
    "Match-making",
    "Matchmaking",
    "Matrimonial",
    "Perfect match",
    "Perfect partner",
    "Potential match",
    "Relationship",
    "Research",
    "Right match",
    "Single",
    "Social",
    "Soulmate success story",
    "Survey",
    "Tips",
    "Traditional",
    "Trust",
    "Wedding",
    "Women",
    "Wonderful",
    "Relationship advice",
    "Singles event",
    "Online dating",
    "Find your match",
    "Manage matchmaking"
  ];

  sanitizedContent: SafeHtml;
  tagname: string = '';
  tag: string = '';

  user_id: number;
  categoryList: any = [];
  popularBlogs: any = [];
  blogDetailsArr: any = [];
  search: string = '';
  blogTitle: any;
  blogId: any;
  haveCover: boolean = false;
  photoUrl: 'assets/images/blog/blog_default_detailpage.png';
  // verified : boolean= false;
  activeTab: any;
  category_id: any = 0;
  totalBlogs: number = 0;
  // category_id: any = 0;
  imageUrl: string = 'assets/images/blog/blog_default_detailpage.png';
  dataNotFound: boolean = false;
  isLoggedIn: any;
  searchCount: any = 0;

  constructor(
    private notifService: NotifService,
    private cookieService: CookieService,
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    private myProfileService: MyProfileService,
    private toastrService: ToastrService,
    private registerService: RegisterService,
    private paramsroutes: ActivatedRoute,
    private adminService: AdminService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public token: TokenService
  ) { 
    this.isLoggedIn = this.token.isLoggedIn();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.blogTitle = this.paramsroutes.snapshot.params['id'];
    this.user_id = parseInt(this.cookieService.get('user_id'));
    let pageId = this.route.snapshot.params['id'];
    this.blogId = pageId;
    this.getDetailsofblog();
    this.getBlogCategories();
    this.getMostReadBlogs();
    $('#openModal12').click();
  }

  blogViewed(id) {
    let data = {
      blogId: id,
    };
    this.myProfileService.increaseViewCountBlog(data).subscribe(
      res => {
      }, (err) => {
      }
    );
  }

  getUser() {
    let data = {
      user_id: this.user_id,
    };
    this.registerService.getUserDetails(data).subscribe((user_details: any) => {
      let details = user_details.userDetails;
      let register_id = details.register_done;
      localStorage.setItem('register_id', register_id);
    });
  }

  getBlogCategories() {
    this.myProfileService.blogCategories().subscribe(
      res => {
        this.categoryList = res["category_list"];
        this.categoryList.forEach(element => {
          this.totalBlogs = this.totalBlogs + element.category_count;
        });
      }, (err) => {
        this.totalBlogs = 0;
      }
    );
  }

  Search() {
  }

 
  onKeyDown(event: KeyboardEvent) {
    let keypressed = event?.key;
    if (keypressed === 'Enter') {
      this.Search();
    }
  }

  Tags(event) {
    const filterValue = event.target.id;
    const encodedFilterValue = encodeURIComponent(filterValue);
    const url = `${environment.baseURL}` + 'blogs?sort_byTag=' + encodedFilterValue;
    window.open(url, '_blank');
  }

  getDetailsofblog() {
    let bolgDetails =
    {
      blog_title: this.blogId
    }
    this.blogDetailsArr = [];
    this.adminService.getBlogDetailsByTitle(bolgDetails).subscribe(
      res => {
        if (res?.['status']) {
          this.dataNotFound=false;
          this.blogDetailsArr = res['blog_details'];
          let blogId = this.blogDetailsArr.id;
          this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(this.blogDetailsArr?.blog_content);
          if (this.blogDetailsArr.published_at != "" && this.blogDetailsArr.published_at != null) {
            let convertedDate = this.convertDateFormat(this.blogDetailsArr.published_at);
            const outputDate = this.addMinutesToDateString(convertedDate);
            this.blogDetailsArr['msgSent'] = outputDate;
          }
          this.increaseViewCountBlog(this.blogId);
          this.category_id = this.blogDetailsArr.category_id;
          if (this.blogDetailsArr.blog_media.length > 0) {
            this.haveCover = true;
            this.photoUrl = this.blogDetailsArr.blog_media[0].media_path;
          } else {
            this.haveCover = false;
          }
          if (this.blogDetailsArr.blog_media.length > 0) {
            this.imageUrl = this.blogDetailsArr.blog_media[0].seo_media_path;
          } 
        }else{
          this.dataNotFound=true;
          setTimeout(() => {
            this.router.navigate(['/blog']);
          }, 2000)
        }
          this.titleService.setTitle(`${this.blogDetailsArr?.seo_blog_title} | Best Matrimonial Site in India`);
          this.meta.updateTag({ property: 'og:title', content: this.blogDetailsArr?.seo_blog_title });
          this.meta.updateTag({ property: 'og:description', content: this.blogDetailsArr?.seo_blog_content });
          this.meta.updateTag({ property: 'og:keywords', content: this.blogDetailsArr?.tags });
          this.meta.updateTag({ property: 'og:image', content: this.imageUrl });
          this.meta.updateTag({ name: 'description', content: this.blogDetailsArr?.seo_blog_content });
          this.meta.updateTag({ name: 'keywords', content: this.blogDetailsArr?.tags });
          this.meta.updateTag({ name: 'image', content: this.imageUrl  });
      },
      (err) => {
        if (err?.['message']) {
          this.toastrService.error('Error!', 'Internal error!')
          this.router.navigate(['/admin/blog-list']);
        }
      });
  }

  convertDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const day = `0${date.getUTCDate()}`.slice(-2);
    const hours = `0${date.getUTCHours()}`.slice(-2);
    const minutes = `0${date.getUTCMinutes()}`.slice(-2);
    const seconds = `0${date.getUTCSeconds()}`.slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000000Z`;
  }

  convertUTCToLocal(utcTimestamp) {
    const date = new Date(utcTimestamp);
    const localDateString = date.toLocaleString();
    return localDateString;
  }

  addMinutesToDateString(dateString: string): string {
    const date = new Date(dateString);
    let newMin = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - newMin);
    return date.toISOString();
  }

  increaseViewCountBlog(id) {
    let data = {
      blog_id: id,
    };
    this.myProfileService.increaseViewCountBlog(data).subscribe(
      res => {
      }, (err) => {
      }
    );
  }

  filterByCategory(categoryId, categoryName) {
    const filterValue = categoryId;
    const encodedFilterValue = encodeURIComponent(filterValue);
    const url = `${environment.baseURL}` + 'blog?sort_by=' + encodedFilterValue;
    window.open(url, '_blank');
  }

  getMostReadBlogs() {
    let data =
    {
      platform_id: 1
    }
    this.myProfileService.getMostReadBlogs(data).subscribe(
      res => {
        this.popularBlogs = res["mostReadBlogs"];
      }, (err) => {

      }
    );
  }
  handleKeyboardEvent(event: KeyboardEvent) {
    let keypressed = event?.key;
    if (keypressed === 'Enter') {
      this.tag = '';
      this.category_id = 0;
      this.tagname = '';
      // this.currentPage = 1;
      // this.getBlogsList();
    } else if (keypressed === 'Backspace' && this.search === '') {
      // this.currentPage = 1
      // this.getBlogsList();
    }
  }

  viewBlog(id) {
    let encrypted_id: any = this.commonService.encryptData(id);
    encrypted_id = encrypted_id.toString().replaceAll('=', 'Ar1t').replaceAll('/', 'FGH').replaceAll('+', 'THD');
    let url = `${environment.baseURL}` + 'blogDetails/' + encrypted_id
    window.open(url);
  }

  popularBlogsRedirect(blog) {
    this.blogId = blog.id;
    this.getDetailsofblog();
    window.scroll(0, 0);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { BlogComponent } from './blog/blog.component';
import { ChatComponent } from './chat/chat.component';
import { CompareComponent } from './compare/compare.component';
import { ContactComponent } from './contact/contact.component';
import { CookiesComponent } from './cookies/cookies.component';
import { DetailComponent } from './detail/detail.component';
import { EditComponent } from './edit/edit.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { InboxComponent } from './inbox/inbox.component';
import { CommunicationInboxComponent } from './communications/communication-inbox/communication-inbox.component';
import { InboxSentComponent } from './communications/inbox-sent/inbox-sent.component';
import { InboxFollowUpComponent } from './communications/inbox-follow-up/inbox-follow-up.component';
import { InboxCancelledComponent } from './communications/inbox-cancelled/inbox-cancelled.component';
import { AcceptedComponent } from './communications/accepted/accepted.component';
import { InboxBlockedComponent } from './communications/inbox-blocked/inbox-blocked.component';
import { LoginComponent } from './login/login.component';
import { LoginhomeComponent } from './loginhome/loginhome.component';
import { MatchesComponent } from './matches/matches.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { PartnerPreferencesComponent } from './partner-preferences/partner-preferences.component';
import { DisclaimerComponent } from './Policies/disclaimer/disclaimer.component';
import { PaymentComponent } from './Policies/payment/payment.component';
import { PrivacyComponent } from './Policies/privacy/privacy.component';
import { TermsComponent } from './Policies/terms/terms.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ProfilePage1Component } from './profile-page1/profile-page1.component';
import { RegisterDoneComponent } from './register-done/register-done.component';
import { RegisterNxtComponent } from './register-nxt/register-nxt.component';
import { RegisterComponent } from './register/register.component';
import { SaftyTipsComponent } from './safty-tips/safty-tips.component';
import { SearchComponent } from './search/search.component';
import { AuthGuardService } from './services/auth-guard.service';
import { GuestGuardService } from './services/guest-guard.service';
import { ServicesComponent } from './services/services.component';
import { SettingComponent } from './setting/setting.component';
import { StepDoneComponent } from './step-done/step-done.component';
import { StepComponent } from './step/step.component';
import { SteptwoComponent } from './steptwo/steptwo.component';
import { UpgreadComponent } from './upgread/upgread.component';
import { RenewalComponent } from './renewal/renewal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginHomeComponent } from './login-home/login-home.component';
import { NotificationComponent } from './notification/notification.component';
import { ProfilePreviewComponent } from './profile-preview/profile-preview.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { MyinfoComponent } from './myinfo/myinfo.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { MatchesViewComponent } from './matches-view/matches-view.component';
import { MatchesFavouriteComponent } from './matches-favourite/matches-favourite.component';
import { MatchesShortlistComponent } from './matches-shortlist/matches-shortlist.component';
import { PackagesComponent } from './packages/packages.component';
import { SearchForLookingComponent } from './search-for-looking/search-for-looking.component';
import { BiodataComponent } from './biodata/biodata.component';
import { LeaveGuard } from './beforeunload/leave.guard';
import { SuggestedMatchesComponent } from './suggested-matches/suggested-matches.component';
import { BlogCategoryComponent } from './blog-category/blog-category.component';
// const routes: Routes = [];
const routes: Routes = [
  // {
  //   path: '',
  //   component: LoginComponent,
  //   canActivate:[GuestGuardService]
  // },
  {
    path: '',
    component: HomeComponent,
    // canActivate:[GuestGuardService]
  },

  // { path: '', redirectTo: 'admin', pathMatch: 'full' },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  // { path: '**', redirectTo: 'admin' },

  {
    path: 'home',
    component: HomeComponent,
    // canActivate:[GuestGuardService],
    data: { title: 'Home', }
  },
  {
    path: 'Home',
    component: LoginHomeComponent,
    canActivate: [AuthGuardService],
    data: { title: 'Home', }
  },
   {
    path: 'blog',
    component: BlogComponent,
    // canActivate: [AuthGuardService],
    data: { title: 'Blog' }
  }, {
    path: 'blog/:id',
    component: BlogDetailComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'blog/category/:id ' ,
    component: BlogCategoryComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'bioData/:id',
    component: BiodataComponent
  },

  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
    // canActivate:[GuestGuardService]

  },

  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'registerNxt',
    component: RegisterNxtComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'registerDone',
    component: RegisterDoneComponent,
  },
  {
    path: 'step',
    component: StepComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'stepTwo',
    component: SteptwoComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'stepDone',
    component: StepDoneComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'loginHome',
    component: LoginhomeComponent,
  },
  {
    path: 'profilePage',
    component: ProfilePageComponent,
  },
  {
    path: 'myProfile',
    component: MyprofileComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'myInfo',
    component: MyinfoComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'myPhotoGallery',
    component: PhotoGalleryComponent,
    canActivate: [AuthGuardService]
    // canActivate:[AuthGuardService]
  },
  {
    path: 'Disclaimer',
    component: DisclaimerComponent,
  },
  {
    path: 'Terms',
    component: TermsComponent,
  },
  {
    path: 'Privacy',
    component: PrivacyComponent,
  },
  // {
  //   path: 'search',
  //   component: SearchComponent,
  // },
  {
    path: 'payment',
    component: PaymentComponent,
  },
  {
    path: 'edit',
    component: EditComponent,
  },
  {
    path: 'matches',
    component: MatchesComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [LeaveGuard]

  },
  {
    path: 'matches-view',
    component: MatchesViewComponent,
    canActivate: [AuthGuardService]

  },
  {
    path: 'matches-shortlist',
    component: MatchesShortlistComponent,
    canActivate: [AuthGuardService]

  },
  {
    path: 'matches-favourite',
    component: MatchesFavouriteComponent,
    canActivate: [AuthGuardService]

  },
  {
    path: 'detail',
    component: DetailComponent
  },
  {
    path: 'detail/:id',
    component: DetailComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'Setting',
    component: SettingComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'inbox',
    component: InboxComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'comunication-inbox',
    component: CommunicationInboxComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'inbox-sent',
    component: InboxSentComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'inbox-followUp',
    component: InboxFollowUpComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'inbox-cancelled',
    component: InboxCancelledComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'inbox-blocked',
    component: InboxBlockedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'accepted',
    component: AcceptedComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: 'upgrade',
    component: UpgreadComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'renewal',
    component: RenewalComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'packages/:id',
    component: PackagesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'Dashboard',
    component: MyDashboardComponent,
  },
  {
    path: 'Profile-Preview',
    component: ProfilePreviewComponent,
    canActivate: [AuthGuardService]

  },
  {
    path: 'ProfilePage1',
    component: ProfilePage1Component,
    canActivate: [AuthGuardService]
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'notification',
    component: NotificationComponent,
  },
  {
    path: 'PartnerPreferences',
    component: PartnerPreferencesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'safety-tips',
    component: SaftyTipsComponent,
  },
  {
    path: 'compare',
    component: CompareComponent,
  },
  {
    path: 'cookies',
    component: CookiesComponent,
  },
  {
    path: 'reset-password/:id',
    component: ResetPasswordComponent,
  },
  {
    path: 'change-password/:id',
    component: ChangePasswordComponent,
  },
  {
    path: 'search-for-looking',
    component: SearchForLookingComponent
  },
  {
    path: 'suggested-matches',
    component: SuggestedMatchesComponent,
    canActivate: [AuthGuardService]
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  // providers: [CookieService],
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml,Title, Meta } from '@angular/platform-browser';
import { FormBuilder/*, FormGroup, Validators, FormControl */ } from '@angular/forms';
import { AdminService } from '../admin/adminServices/admin.service';
import { Router } from '@angular/router';
import { MyProfileService } from '../services/my-profile.service';
import { HttpClient/*, HttpHeaders */ } from '@angular/common/http';
// unused command
// import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TokenService } from '../shared/token.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  categoryList: any = [];
  faqList: any = [];

  pageAdd: number = 0;
  TotalCount: any = 0;
  searchCount: any = 0;

  search: string = '';

  category_id: any = 0;
  activeTab: any;
  category_name: string;

  TotalRecord: any;
  currentPage: number = 1;
  pageSize: number = 8;
  showPagination: boolean;
  hidePageSize = true;
  nextPage: any;
  lastPage: number;
  itemPerPage: number = 8;
  user_id: any;
  isLoggedIn: any;
  totalCategory: any;
  defaultOpenIndex: number = 0;
  sanitizedContent: SafeHtml;

  constructor(private titleService: Title,
    private meta: Meta,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private router: Router,
    private myProfileService: MyProfileService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    public token: TokenService,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer
  ) {
    this.titleService.setTitle('Eternal String FAQs | Understand Matrimony Services');
    this.meta.addTag({ name: 'description', content: "Find answers instantly on our FAQ page! Quick solutions to common queries ensure you get the info you need without the wait. Visit now for clarity!" });
    this.meta.addTag({ name: 'robots', content: 'all' });
    this.meta.addTag({ name: 'keywords', content: 'Matrimony Services, FAQs, Eternal strings'});

    this.isLoggedIn = this.token.isLoggedIn();
    this.user_id = parseInt(this.cookieService.get('user_id'));
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getFAQList();
    this.getFAQCategoryList();
  }

  getFAQCategoryList() {
    this.adminService.getFAQCategoryList().subscribe(
      (result) => {
        if (result.status) {
          console.log(result);
          this.categoryList = result.categorylist;
          this.totalCategory = result.total
        }
      }, (error) => {                              //Error callback

      })
  }

  getFAQList() {
    this.spinner.show();
    let data = {
      admin_id: 6,
      // admin_id: this.adminUser.id,
      itemPerPage: this.itemPerPage,
      page: this.currentPage,
      search: this.search,
      status: 2,
      category_id: this.category_id
    }

    this.adminService.getFAQList(data).subscribe(
      (result) => {
        if (result.status) {
          console.log(result);
          this.faqList = result.faqList;

          this.faqList.forEach(element => {
            if (element.answer != null && element.answer != '') {
              this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(element?.answer);
              element['answer1'] = this.sanitizedContent;
            } 
          });
          this.TotalCount = result.total;
          this.pageAdd = (this.currentPage - 1) * this.itemPerPage;
          this.faqList = result['faqList'];
          this.TotalRecord = result['totalRecord'];
          this.lastPage = Math.ceil(this.TotalRecord / this.itemPerPage);
          if (this.category_id == 0 && this.search == '') {
            this.TotalCount = this.TotalRecord;
          }

          this.spinner.hide();
          if (this.search != '') {
            this.searchCount = this.TotalRecord;
          } else {
            this.searchCount = 0;
          }
        }
      }, (error) => {                              //Error callback
        // this.spinner.hide();

      })
  }

  // before sonar
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   var keypressed = event.keyCode;
  //   if (keypressed == 13) {
  //     this.currentPage = 1;
  //     this.getFAQList()
  //   } else if (keypressed == 8 && this.search == '') {
  //     this.currentPage = 1;
  //     this.getFAQList();
  //   }
  // }


  //after sonar
  handleKeyboardEvent(event: KeyboardEvent) {
    let keypressed = event?.key;
    this.category_id = 0
    if (keypressed === 'Enter') {
      this.currentPage = 1;
      
      this.getFAQList();
    } else if (keypressed === 'Backspace' && this.search === '') {
      console.log(keypressed)
      this.currentPage = 1
      this.getFAQList();
    }
  }

  clearSearch() {
    this.currentPage = 1;
    this.search = ""
    this.getFAQList();
  }

  Search() {
    this.currentPage = 1;
    this.category_id = 0;
    this.getFAQList();
  }

  filterByCategory(categoryId, categoryName) {
    this.search = ""
    this.category_id = categoryId;
    this.activeTab = categoryId;
    this.getFAQList();
    // this.clearSearch()
    window.scroll(0, 0);
  }

  public handlePage(e: any) {
    console.log('currentPage event', e);
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    console.log('currentPage', this.currentPage, e);
    this.getFAQList();
    window.scroll(0, 342);
  }
}

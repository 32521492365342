<div class="w-100 bg-maroon" *ngIf="!isLoggedIn">
  <app-header></app-header>
</div>
<div class="page-header1">
  <div class="container">
    <div class="row">
      <div class="col-12 ">
        <div class="page-caption1">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content py-3 vh_rm">
  <div class="container mt-3">
    <div class="row">
      <div class="col-md-9 col-sm-12 col-12 ">
        <div class="row">
          <div class="col-12">
            <div class="post-block">
              <div class="row mx-0 p-2 bg-gray border-bottom">
                <div class="col-4 px-0 text-truncate">
                  <a href="javascript:void(0)" class="text-danger fw-bold ms-3"
                    (click)="filterByCategory(blogDetailsArr.category_id,blogDetailsArr.category_name)">
                    {{blogDetailsArr.category_name }} </a>
                </div>
                <div class="px-0 " [ngClass]="(blogDetailsArr?.author)?'col-4 text-center':'col-8 text-end'">
                  <span *ngIf="blogDetailsArr?.published_at!='0000-00-00 00:00:00'  && !dataNotFound  ">
                    <small class="text-muted">
                      Published on {{blogDetailsArr?.msgSent | date:'dd/MM/yyyy' }}
                    </small>
                  </span>
                  <span *ngIf="dataNotFound">
                    <small class="text-muted">
                      Data Not Found
                    </small>
                  </span>
                </div>
                <div class="col-4 px-0 text-end" *ngIf="blogDetailsArr?.author">
                  <span>
                    <small class="text-muted">
                      Author: {{blogDetailsArr?.author }}
                    </small>
                  </span>
                </div>
              </div>
              <div class="post-img bg-white">
                <ng-container
                  *ngIf=" blogDetailsArr?.blog_media && blogDetailsArr.blog_media.length > 0 && blogDetailsArr.blog_media[0]?.media_path !== ''">
                  <app-progressive-image class="progressive-image">
                    <img appProgressiveImage [src]=" blogDetailsArr.blog_media[0]?.media_path" alt=""
                      class="img-fluid object-fit image-cover" type="">
                    <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
                      <div id="loaderProgressive" class="centerProgressive1"></div>
                    </div>
                  </app-progressive-image>
                </ng-container>
                <ng-container
                  *ngIf=" !blogDetailsArr?.blog_media || blogDetailsArr.blog_media.length === 0 || blogDetailsArr.blog_media[0]?.media_path === '' ">
                  <img src=" assets/images/blog/blog_default_detailpage.png" alt=""
                    class="img-fluid object-fit image-cover" type="">
                </ng-container>
              </div>
              <div class="post-content min-screen-height-blogDetail">
                <h4 class="text-start text-muted py-0">{{blogDetailsArr.blog_title}}</h4>
                <div class="lh-base pb-3 text-muted text-start right-margin" [innerHTML]="sanitizedContent">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12 position-relative">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item widget">
            <button class="accordion-button  no-cursor-pointer" type="button">
              Search ({{searchCount}})
            </button>
            <div style="display: block !important;" id="collapseOne" class="accordion-collapse collapse"
              aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="input-group mb-3 ">
                  <input style="height:34px;" type="text"
                    class="w-100 rounded-3 border-solid-1 ps-3 pe-5 position-relative ng-touched"
                    placeholder="Search here" aria-label="Search Here" [(ngModel)]="search"
                    (keyup)="handleKeyboardEvent($event)" aria-describedby="button-addon2">
                  <div class="input-group-append position-absolute top-50 start-100 translate-middle">
                    <span *ngIf="search == ''"> <i class="fas fa-search searchButton" (click)="Search()"></i></span>
                    <button *ngIf="search != ''" (click)="clearSearch()"
                      class="searchButton btn btn-small p-0 fw-semibold" type="button" id="button-addon2">X</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item widget widget-category">
            <button class="accordion-button collapsed" id="openModal12" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Categories
            </button>
            <button style="display:none;" data-bs-toggle="collapse" data-bs-target="#collapseTwo"></button>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class>
                  <li class="slide-10" [ngClass]="category_id == 0 ? 'active' : ''"><a href="javascript:void(0);"
                      (click)="filterByCategory(0,'all')"><i class="fa fa-arrow-right" aria-hidden="true"></i>
                      All categories <strong class='ml-1'>({{totalBlogs}})</strong></a>
                  </li>
                  <ng-container *ngFor="let category of categoryList">
                    <li class="slide-10" [ngClass]="category_id == category.id ? 'active' : ''"><a
                        href="javascript:void(0);" (click)="filterByCategory(category.id,category.category_name)"><i
                          class="fa fa-arrow-right" aria-hidden="true"></i>
                        {{category.category_name}} <strong *ngIf="category.category_count> 0"
                          class='ml-1'>({{category.category_count}})</strong></a></li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item widget widget-archives">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Popular Blogs
            </button>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <ul class="mb-0">
                  <li class="slide-10" *ngFor="let mostReadBlogs of popularBlogs"><a href="javascript:void(0);"
                      (click)="popularBlogsRedirect(mostReadBlogs)"><i class="fa fa-arrow-right" aria-hidden="true"></i>
                      {{mostReadBlogs.blog_title}}</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item widget-tags">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#headingFour" aria-expanded="false" aria-controls="headingFour">
              Tags
            </button>
            <div id="headingFour" class="accordion-collapse collapse" aria-labelledby="headingThree"
              data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <a *ngFor="let tag of tagsList; index as i" class="rounded cursor-pointer" [id]="tag"
                  (click)="Tags($event)" [ngClass]="(tagname==tag)?'active1': ''">{{tag}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MyProfileService {
  private getHobbiesDetailsUrl = `${environment.apiURL}/auth/setHobbiesDetails`;
  private getFamilyDetailsUrl = `${environment.apiURL}/auth/setFamilyDetails`;
  private getReligionViewUrl = `${environment.apiURL}/auth/setReligionView`;
  private getAstroDetailsUrl = `${environment.apiURL}/auth/setAstroDetails`;

  private getBasicAndLifestyleUrl = `${environment.apiURL}/auth/setBasicAndLifestyle`;
  private getAboutYouUrl = `${environment.apiURL}/auth/setAboutYou`;
  private getContactDetailsUrl = `${environment.apiURL}/auth/setContactDetails`;
  private getLocationDetailsUrl = `${environment.apiURL}/auth/setLocationDetails`;
  private getEducationDetailsUrl = `${environment.apiURL}/auth/setEducationDetails`;

  private getPartnerBasicAndLifestyleUrl = `${environment.apiURL}/auth/setBasicAndLifestyleOfPartner`;
  private getPartnerReligionViewUrl = `${environment.apiURL}/auth/religiousViewOfPartner`;
  private getPartnerFamilyDetailsUrl = `${environment.apiURL}/auth/familyDetailsOfPartner`;
  private getPartnerEducationDetailsUrl = `${environment.apiURL}/auth/educationAndCareerOfPartner`;
  private getPartnerLocationDetailsUrl = `${environment.apiURL}/auth/locationOfPartner`;
  private uploadImageUrl = `${environment.apiURL}/auth/image-upload`;
  // private uploadCoverImageUrl = `https://tukev2rhsh.execute-api.me-south-1.amazonaws.com/v1/upload/image`
  private uploadCoverImageUrl = `${environment.apiURL}/auth/cover-upload`;
  //private visibleToMembersUrl =`${environment.apiURL}/auth/visibleToMembers`;

  constructor(private http: HttpClient) { }

  setAboutYou(user: any): Observable<any> {
    return this.http.post(this.getAboutYouUrl, user);
  }
  setBasicAndLifestyle(user: any): Observable<any> {
    return this.http.post(this.getBasicAndLifestyleUrl, user);
  }
  setReligionView(user: any): Observable<any> {
    return this.http.post(this.getReligionViewUrl, user);
  }
  setAstroDetails(user: any): Observable<any> {
    return this.http.post(this.getAstroDetailsUrl, user);
  }
  setFamilyDetails(user: any): Observable<any> {
    return this.http.post(this.getFamilyDetailsUrl, user);
  }

  setEducationDetailsUrl(user: any): Observable<any> {
    return this.http.post(this.getEducationDetailsUrl, user);
  }
  setHobbiesDetails(user: any): Observable<any> {
    return this.http.post(this.getHobbiesDetailsUrl, user);
  }
  setLocationDetails(user: any): Observable<any> {
    return this.http.post(this.getLocationDetailsUrl, user);
  }
  setContactDetails(user: any): Observable<any> {
    return this.http.post(this.getContactDetailsUrl, user);
  }

  setHandoverDetails(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/setHandoverDetails`, user);
  }
  // Partner Preferrence
  setPartnerBasicAndLifestyle(user: any): Observable<any> {
    return this.http.post(this.getPartnerBasicAndLifestyleUrl, user);
  }
  setPartnerReligionView(user: any): Observable<any> {
    return this.http.post(this.getPartnerReligionViewUrl, user);
  }
  setPartnerFamilyDetails(user: any): Observable<any> {
    return this.http.post(this.getPartnerFamilyDetailsUrl, user);
  }
  setPartnerEducationDetailsUrl(user: any): Observable<any> {
    return this.http.post(this.getPartnerEducationDetailsUrl, user);
  }
  setPartnerLocationDetails(user: any): Observable<any> {
    return this.http.post(this.getPartnerLocationDetailsUrl, user);
  }
  uploadImage(user: any): Observable<any> {
    return this.http.post(this.uploadImageUrl, user);
  }



  uploadCoverImage(user: any): Observable<any> {
    return this.http.post(this.uploadCoverImageUrl, user);
  }

  // myProfileService(user: any): Observable<any> {
  //   return this.http.post("https://demoprojects.itsabacus.net/2757_JubWeMet/back-end/api/auth/userProfilePreview "
  //   ,user);
  // }
  myProfilePreview(user: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/myProfilePreview`,
      user
    );
  }
  userProfilePreview(user: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/userProfilePreview`,
      user
    );
  }

  getUserProfileFeilds(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getUserProfileFeilds`, data);
  }
  requestAccess(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/requestAccess`, data);
  }
  viewProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/viewProfile`, data);
  }
  actionOnRequestAccess(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/actionOnRequestAccess`, data);
  }

  uploadGallery(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/uploadGallery`, user);
  }

  userPhotoGallery(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/userPhotoGallery`, user);
  }

  getProfileGallery(user: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getProfileGallery`, user);
  }

  searchPartner(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/searchPartner`, data);
  }
  searchPartners(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/searchPartners`, data);
  }

  deletePhotoGallery(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/deletePhotoGallery`,
      data
    );
  }

  actionOnProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/actionOnProfile`, data);
  }

  reportProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/reportProfile`, data);
  }

  getFavouriteProfiles(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/getFavouriteProfiles`,
      data
    );
  }
  getViewedByShortlistedFavouritedCount(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/getViewedByShortlistedFavouritedCount`,
      data
    );
  }

  getShortedProfiles(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/getShortedProfiles`,
      data
    );
  }
  addToViewedByOther(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/addToViewedByOther`,
      data
    );
  }

  addToShortlist(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/addToShortlist`, data);
  }
  addToFavoriteList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/addToFavoriteList`, data);
  }
  getChatCount(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getChatCount`, data);
  }

  getNotifications(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getNotifications`, data);
  }

  deleteNotification(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/deleteNotification`, data);
  }
  updateUserSettings(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updateUserSettings`, data);
  }

  hideProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/hideProfile`, data);
  }
  unHideProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/unHideProfile`, data);
  }
  deleteProfile(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/deleteProfile`, data);
  }
  updatePassword(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updatePassword`, data);
  }
  userSettings(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/userSettings`, data);
  }
  getUserSettings(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getUserSettings`, data);
  }

  // getChatCount  getNotifications deleteNotification userSetting 
  // https://demoprojects.itsabacus.net/2757_JubWeMet/back-end/api/auth/deletePhotoGallery

  getViewedByProfiles(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/getViewedByProfiles`,
      data
    );
  }

  AddToMyFavourite(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/AddToMyFavourite`, data);
  }

  compareProfiles(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/compareProfiles`, data);
  }

  visibleToMembers(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/visibleToMembers`, data);
  }

  myPhotoGalleryAccess(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/myPhotoGalleryAccess`,
      data
    );
  }

  getProfileVisibility(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/getProfileVisibility`,
      data
    );
  }

  updateProfileVisibility(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updateProfileVisibility`, data);
  }

  getMoreAboutUser(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getMoreAboutUser`, data);
  }

  connectNow(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/connectNow`, data);
  }

  doActionOnRequests(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiURL}/auth/doActionOnRequests`,
      data
    );
  }

  getAllRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getAllRequest`, data);
  }

  getSentRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getSentRequest`, data);
  }
  getFollowUpRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getFollowUpRequest`, data);
  }

  getCancelledRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getCancelledRequest`, data);
  }
  getBlockedRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getBlockedRequest`, data);
  }
  getCountOfRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getCountOfRequest`, data);
  }

  getConnectionProfileHistory(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getConnectionProfileHistory`, data);
  }

  getSuggestedMatches(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getSuggestedMatches`, data);
  }
  getCommunicationMessageOnAction(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getCommunicationMessageOnAction`, data);
  }

  updateCommunicationAlertStatus(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updateCommunicationAlertStatus`, data);
  }


  getBlogsList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getBlogsList`,data);
  }

  //  https://stagingapi.itsgametime.com/api/blogCategories

  blogCategories() {
    return this.http.post(`${environment.apiURL}/auth/blogCategories`, {});
  }

  getBlogTags() {
    return this.http.post(`${environment.apiURL}/auth/getBlogTags`, {});
  }


  getBlogDetailsByTitle(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getBlogDetailsByTitle`, data);
  }

  increaseViewCountBlog(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/increaseViewCountBlog`, data);
  }

  // upgradePackage(data :any): Observable<any>{
  //   return this.http.post(`https://stagingapi.itsgametime.com/api/upgradePackage`,data);
  // }

  getPackages(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getPackages`, data);
  }

  getCorePackages(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getCorePackages`, data);
  }
  upgradePackage(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/upgradePackage`, data);
  }

  getUserPlans(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getUserPlans`, data);
  }
  activePlanNow(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/activePlanNow`, data);
  }

  getPlanExtendedData(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getPlanExtendedData`, data);
  }



  startPayment(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/startPayment`, data);
  }

  getAcceptedRequest(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getAcceptedRequest`, data);
  }

  getProfilesBlockedByMe(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getProfilesBlockedByMe`, data);
  }

  getChatUserList(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getChatUserList`, data);
  }
  getChatUsersForwardTo(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getChatUsersForwardTo`, data);
  }
  openMyChat(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/openMyChat`, data);
  }

  sendMessageForChat(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/sendMessageForChat`, data);
  }
  forwardMessageNow(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/forwardMessageNow`, data);
  }
  deleteMessageNow(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/deleteMessageNow`, data);
  }

  clearMessagesNow(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/clearMessagesNow`, data);
  }

  getChatUserDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getChatUserDetails`, data);
  }
  sendFilesForChat(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/sendFilesForChat`, data);
  }

  searchChat(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/searchChat`, data);
  }

  // sendFilesForChat
  updateMessageStatus(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updateMessageStatus`, data);
  }

  // getUserSettings(data: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/auth/getUserSettings`, data);
  // }

  // userSettings (data: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/auth/userSettings `, data);
  // }
  updateGalleryPhoto(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updateGalleryPhoto`, data);
  }
  setPhotoCaption(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/setPhotoCaption`, data);
  }

  updateProfileDetails(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/updateProfileDetails`, data);
  }

  getStories(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getStories`, data);
  }

  getAlertTypes(): Observable<any> {
    return this.http.get(`${environment.apiURL}/auth/getAlertTypes`);
  }

  sendFilesWithCaptionForChat(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/sendFilesWithCaptionForChat`, data);
  }

  getDeletedProfilesByUserId(data: any): Observable<any> {
    return this.http.get(`${environment.apiURL}/auth/getDeletedProfilesByUserId`, data);
  }

  getUserPackages(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getUserPackages`, data);
  }

  requestFreeTrial(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/requestFreeTrial`, data);
  }

  upgradeFreeTrail(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/upgradeFreeTrail`, data);
  }

  getSuggestedMatchesByPartnerPreference(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getSuggestedMatchesByPartnerPreference`, data);
  } getSuggestedMatchesByPartnerPreference1(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getSuggestedMatchesByPartnerPreference1`, data);
  }

  // getMostReadBlogs(data: any): Observable<any> {
  //   return this.http.post(`${environment.apiURL}/auth/getMostReadBlogs`, data);
  // }

  getMostReadBlogs(data: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/auth/getMostReadBlogs`, data);
  }

}
//   getBlogsList(data :any): Observable<any>{
//     return this.http.post(`https://stagingapi.itsgametime.com/api/getBlogsList`,data);
//   }

// }

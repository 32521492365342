import { Component, OnInit, ViewChild } from '@angular/core';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../services/common.service';
import { MatPaginator } from '@angular/material/paginator';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-search-for-looking',
  templateUrl: './search-for-looking.component.html',
  styleUrls: ['./search-for-looking.component.css']
})
export class SearchForLookingComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  value: number = 18;
  highValue: number = 100;
  options: Options = {
    floor: 18,
    ceil: 90,
  };

  minValue: number = 0;
  maxValue: number = 230;
  options2: Options = {
    floor: 100,
    ceil: 230,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' cm';
        case LabelType.High:
          return +value + ' cm';
        default:
          return '';
      }
    },
  };

  hidePageSize = true;

  minValue3: number = 0;
  maxValue3: number = 150;
  options3: Options = {
    floor: 70,
    ceil: 150,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return +value + ' lb';
        case LabelType.High:
          return +value + ' lb';
        default:
          return '';
      }
    },
  };


  minValue4: number = 0;
  maxValue4: number = 100000000000;
  options4: Options = {
    floor: 0,
    ceil: 100000000000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return "$" + value;
        default:
          return '';
      }
    }
  };



  profileList: any = [];
  contentLoader: boolean = false;
  formData: { looking_for: any; age_from: any; age_to: any; religion: any; country: any; };
  countries: any = [];
  phoneCode: any = 38;
  TotalRecord: any;
  sortBylooking_for: any = '';
  sortByageFrom: any = 18;
  sortByageTo: any = 70;
  sortByreligion: any = '';
  sortBycountry: any = 38;
  currentPage: number = 0;
  pageSize: number = 8;
  showPagination: boolean = false;
  lastPage: any;
  age_from: any = [];
  // age_to: any = [];
  // age_from: any[] = [];
  age_to: any[] = [];
  renderTo: boolean = true;;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private CommonService: CommonService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getCountryList();

    for (let i = 18; i <= 70; i++) {
      this.age_from.push({ id: i })
    }

    this.route.queryParams.subscribe((queryParams) => {

      this.sortBylooking_for = queryParams.looking_for;
      this.sortByageFrom = parseInt(queryParams.age_from);
      this.sortByageTo = parseInt(queryParams.age_to);
      this.sortByreligion = queryParams.religion;
      this.sortBycountry = parseInt(queryParams.country);
      this.formData = {
        looking_for: queryParams.looking_for,
        age_from: queryParams.age_from,
        age_to: queryParams.age_to,
        religion: queryParams.religion,
        country: queryParams.country,
      };
      console.log(this.formData);
    });
    this.onChangeAgeFrom({ target: { value: this.sortByageFrom } });
    this.getSearchRecord();
  }

  getSearchRecord() {
    this.contentLoader = true;
    let formData = {
      looking_for: this.sortBylooking_for,
      age_from: this.sortByageFrom,
      age_to: this.sortByageTo,
      religion: this.sortByreligion,
      country: this.sortBycountry,
      pageNo: this.currentPage,
      itemPerPage: this.pageSize,
      current_page: this.currentPage
    }
    this.authService.searchRecords(formData).subscribe(
      (res: any) => {
        if (res.status) {
          this.profileList = res.pages.data
          this.TotalRecord = res.pages.total
          this.currentPage = res.pages.current_page;
          this.lastPage = res.pages.last_page;
          console.log(this.profileList)
          this.contentLoader = false;
          if (this.profileList.length > 0) {
            if (this.TotalRecord >= this.pageSize) {
              this.showPagination = true
            } else {
              this.showPagination = false
            }
          } else {
            // unused code
            //this.toastrService.success('Data not found!');
          }
        } else {
          this.toastrService.error('Data not found!');
          this.contentLoader = false;
        }
        this.contentLoader = false;
      },
      (error: any) => {
        console.error("Error occurred:", error);
        this.contentLoader = false;
      }
    )
  }

  getCountryList() {
    this.CommonService.getCountryList({}).subscribe(
      (result) => {
        this.countries = result.data;
      },
      (error) => {

      },
      () => {
      }
    );
  }

  signupModal() {
    $('#openModal1').click();
  }

  sortedByGender(event: any) {
    const selectedValue = event.target.value;
    this.sortBylooking_for = selectedValue;
    this.currentPage = 1;
    this.getSearchRecord();
  }

  sortedByAgeFrom(event: any) {
    this.renderTo = false;
    this.sortByageFrom = event.target.value;
    this.currentPage = 1;
    this.checkAge() ;
    this.age_to = [];
    for (let i = this.sortByageFrom; i <= 70; i++) {
      this.age_to.push({ id: i });
    }
    setTimeout(() => {
      this.renderTo = true;
    }, 200);
    this.getSearchRecord();
  }

  sortedByAgeTo(event: any) {
    const selectedValue = event.target.value;
    this.sortByageTo = selectedValue;
    this.currentPage = 1;
    this.getSearchRecord();
  }

  sortedByReligion(event: any) {
    const selectedValue = event.target.value;
    this.sortByreligion = selectedValue;
    this.currentPage = 1;
    this.getSearchRecord();
  }

  sortedBycountry(event: any) {
    console.log(event)
    this.currentPage = 1;
    this.getSearchRecord();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.getSearchRecord();
  }

  checkAge() {
    if (this.sortByageTo < this.sortByageFrom) {
      let tmp = this.sortByageFrom;
      this.sortByageFrom = this.sortByageTo;
      this.sortByageTo = tmp;
    }
  }

  onChangeAgeFrom(age: any) {
    console.log(123, age.target?.value, this.sortByageTo)
    this.age_to = [];
    for (let i = this.sortByageFrom; i <= 70; i++) {
      this.age_to.push({ id: i });
    }
    this.checkAge()
  }
}

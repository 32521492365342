<div class="w-100 bg-maroon">
  <app-header *ngIf="!isLoggedIn"></app-header>
</div>
<div>
  <app-progressive-image class="progressive-image">
    <img appProgressiveImage src="page-header" alt="image" title="About Image" class="page-header">
    <link rel="preload" as="image" href="assets/images/about.webp"/>
    <div class="page-header mt-93">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="page-caption">
            </div>
          </div>
        </div>
      </div>
      <div appProgressiveImagePlaceholder class="placeholderOneOrThree">
        <div id="loaderProgressive" class="centerProgressive"></div>
      </div>
    </div>
    <div appProgressiveImageFallback class="fallback">
      <img appProgressiveImageFallback
        src alt>
    </div>
  </app-progressive-image>
  <div class="py-5 bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="about-descriptions text-center">
            <h2 class="text-center">About Us</h2>
            <div class="seperator-line mx-auto centered mb-4"></div>
          </div>
        </div>

      </div>

      <section class="py-4 container px-0">

        <div class="row">
          <div class="col-12 p-3 p-sm-4">
            <h4 class="fw-bold text-danger">WHO </h4>
            <p>
              Eternal Strings was founded to help members meet with suitable matches. We were established to redefine the way
              you find true love. By providing the best possible experience for our members, we cultivate new
              relationships every minute of every day. We bridge the gap between physical distance, cultures, and
              religions by connecting like-minded individuals from around the world, whether that’s you or someone you
              know.
            </p>
            <p>
              Finding a partner doesn’t have to be so difficult. We’re here to do the heavy lifting. All you need to do
              is
              create a profile and we’ll do the rest. We gather detailed information about religion, family, education,
              lifestyle, and more, in our questionnaire to ensure each partner’s values are aligned.
            </p>

            <h4 class="fw-bold text-danger mt-4 mt-sm-5">WHY</h4>
            <p>
              Matchmaking is an essential service at a time when people are already overwhelmed by their busy lives. Our
              members want to meet like-minded people looking for serious relationships. Your perfect match is searching
              for you too.
            </p>
            <h4 class="fw-bold text-danger mt-4 mt-sm-5">WHAT</h4>
            <p>
              Our goal is to earn the trust of our members by providing premium services to South Asians and others all
              around the world. We link eligible singles who are looking for a lifetime commitment. Our portal offers a
              quick and easy way to find, communicate, and connect with your potential life partner. Our data base
              includes individuals from different countries, communities, and religions.
            </p>
            <p>
              All contact information of each member is kept confidential until they decide to share it with their
              potential match. Every member is carefully vetted for safety and approval. Feel free to review our Safety
              guidelines
            </p>
            <h4 class="fw-bold text-danger mt-4 mt-sm-5">HOW</h4>
            <p>
              Eternal Strings uses advanced matchmaking technology and safety standards while connecting singles with their
              perfect match. Gone are the days when aunties were your only source for matchmaking. We connect our
              members based on the information provided by each applicant. When registering, all individuals have the
              option to include detailed information about themselves.
            </p>
            <p>
              Our partnership service provides you a smooth browsing experience of compatible individuals. All you need
              to do is register for free and create a profile
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="py-5 bg-white">
    <div class="container">
      <div class="row">
        <!--  about-details  -->
        <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12 mb60 ">
          <div class="about-descriptions text-center">

            <h2 class="text-center">A Vision Beyond Your Dreams</h2>
            <div class="seperator-line mx-auto centered mb-4"></div>
            <p class="plan-sub-heading lead">Your perfect life partner is within your reach. Determine your preferences
              and we make it a reality. Dreams can come true.
            </p>
          </div>
        </div>
        <div class="col-12 px-0 text-center position-relative overflow-hidden">
          <img src="assets/images/about-img-5.webp" alt="image" title="Image" class="w-100 img-fluid">
        </div>

      </div>

    </div>
  </div>

  <section class="bg-grey pt-5 pb-2">
    <div class="container py-5">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div
            class="card h-100 shadow-sm rounded-5 border border-1 border-danger-rm cursor-pointer-rm p-4 text-center card-hover-4-rm">

            <div class="contact-us-icon rounded-circle mx-auto mb-4 center">
              <i class="fa fa-cubes fa-2x text-center" aria-hidden="true"></i>
            </div>
            <h3>Our Mission</h3>
            <p>Use technology to enrich peoples lives with love, purpose, and find their life partner.</p>

          </div>
        </div>
        <div class="col">
          <div
            class="card h-100 shadow-sm rounded-5 border border-1 border-danger-rm cursor-pointer-rm p-4 text-center card-hover-4-rm">

            <div class="contact-us-icon rounded-circle mx-auto mb-4 center">
              <i class="fas fa-edit fa-2x text-center"></i>
            </div>
            <h3>Our Vision</h3>
            <p>To give everyone the opportunity to connect and create long lasting relationships.</p>

          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="call-out" class data-bs-toggle="modal"
    data-bs-target="#planningModal">
    <div class="parallax"
      style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('assets/images/banner-5-v1.jpg');">
      <div class="overlay-bg"></div>
      <div class="container text-center">
        <div class="call-out-dtl">
          <h2 class="call-out-heading text-white mb-4"> Start Planning Today </h2>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- start today plannin madal -->
<div class="modal fade" id="planningModal" tabindex="-1" aria-labelledby="planningModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content mx-auto mx-width-400 rounded-10 position-relative overflow-hidden">
      <div class="modal-header text-white bg-maroon border-0 pt-3 mb-3 pb-1">
        <img src="assets/images/second_main_logo_webp.webp" class="mx-auto text-start" alt="Description Eternalstrings LOGO" title="Eternalstrings LOGO" width="130px">
        <button type="button" class="btn-close position-absolute top-0 right-0 mt-2 me-2" id="closeplanning"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-0">
        <div class="col-12 px-0">
          <div class="mb-3 px-3">
            <h1 class="text-danger mt-2 text-center">Coming soon...</h1>
          </div>
          <div class="row mx-0">
            <div class="col-12 mt-3 py-2 bg-maroon w-100">
              <p class="text-center mb-0 fw-bold slide-10"> <a href="javascript:void(0);" routerLink="/home"
                  class="text-light" id="closeWelcomeModal" data-bs-dismiss="modal" aria-label="Close"><i
                    class="fas fa-long-arrow-alt-left"></i> Go to eternalstrings.com</a> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button style="display:none;" id="openModal2" data-bs-target="#deleteModal1" data-bs-toggle="modal"></button>
<!--compare modal modal -->
<div class="modal fade" id="deleteModal1" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content position-relative">

      <div class="modal-body text-center p-4">
        <button type="button" class="btn btn-danger position-absolute top-0 right-0 m-2 center w-30-h-30"
          data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-times fs-5"></i>
        </button>

        <img src="assets/images/second_main_logo_webp.webp" alt="Description Eternalstrings LOGO" title="Eternalstrings LOGO" width="200" class="mb-6">
        <div href="javascript:void(0)"
          routerLink="/registerDone">

          <h4 class="mt-2"> Sign Up for free</h4>
          <!-- <br> -->
          <h5 class=" ">Start finding your life partner</h5>
        </div>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>
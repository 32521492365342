import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotifService } from '../services/notif.service';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
import { Router } from '@angular/router';
import { Title,Meta} from '@angular/platform-browser';
import { TokenService } from '../shared/token.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  readonly notifyOptions = {
    timeOut: 0,
    showProgressBar: true,
    pauseOnHover: true,
    clickToClose: true,
    // clickIconToClose: true,
    preventDuplicates: true,
  };
  txt: string = '';
  options: any;
  private subscription;
  user_id: any;
  // aboutLoader:boolean=false;

  isLoggedIn: any;


  constructor(
    private notifService: NotifService,
    private cookieService: CookieService,
    private router: Router,
    private meta: Meta,
    private titleService: Title,
    public token: TokenService,
    // private cookieService: CookieService,


  ) {
    this.isLoggedIn = this.token.isLoggedIn();
    this.user_id = parseInt(this.cookieService.get('user_id'));
    this.showNotification();
    this.options = this.notifService.getOptions();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    // this.titleService.setTitle('About Us | eternalstrings.com');
    this.titleService.setTitle('Your Gateway to Lifelong Bonds | About Eternal String');
    this.meta.addTag({ name: 'description', content: "Dive into the Eternal String website where timeless connections are woven! Discover the web's hidden gem for endless inspiration and joy!" });
    this.meta.addTag({ name: 'robots', content: 'all' });
    this.meta.addTag({ name: 'keywords', content: 'About us, Eternal strings, find life partner'});

    const routSubscription = this.router.events.subscribe((val) => {
      this.subscription.unsubscribe();
      routSubscription.unsubscribe();
    });
  }

  somefunction() {
    this.subscription.unsubscribe();
  }

  showNotification() {
    this.subscription = interval(60000).subscribe((x) => {
      if (this.cookieService.get('oldUser')) {
        //document why this block is empty
      } else {
        this.notifService.action(
          '<a href=' +
          environment.registerDone +
          ' class="text-light fs-5 pt-2" style="text-decoration:none;">Sign Up for free</a><button class="btn btn-sm btn-close position-absolute top-0 right-0 m-2"></button>',
          `Start finding your life partner`,
          'btn',
          () => (this.txt = `${this.showNotification()}`)
        );
        this.somefunction();
      }
    });
  }

  notifAction1() {
    this.notifService.action(
      'Demo notif with button',
      `Click button below: <button class="btn btn-primary">Do it!</button>`,
      'btn',
      () => (this.txt = `${this.subscription.subscribe()}`)
    );
  }

  notifDestroyed(notif: any) {
    this.notifService.notifDestroyed(notif['id']);
  }
}
